export interface StoredCredential {
  tenantName: string;
  tenantKey: string;
  cupPassword: string;
}

export const localStorageService = () => {
  const tenantStorageKey = 'tenantKey'
  const cupPasswordKey = "cupPassword"
  const cupLoginDataKey = "cupLoginData"

  const _getLoginData = (): StoredCredential[] => {
    const storedString = localStorage.getItem(cupLoginDataKey);
    return storedString ? JSON.parse(storedString) : []
  }

  const _setLoginData = (credentials: StoredCredential) => {
    const stored = _getLoginData()
    localStorage.setItem(cupLoginDataKey, JSON.stringify([...stored.filter(s => s.tenantKey !== credentials.tenantKey), credentials]));
  }

  return {
    getLoginData: (): StoredCredential[] => {
      return _getLoginData()
    },
    setLoginData: (credentials: StoredCredential) => {
      _setLoginData(credentials)
    },

    removeLoginData: (tenantKey: string): StoredCredential[] => {
      const stored = _getLoginData()
      localStorage.setItem(cupLoginDataKey, JSON.stringify(stored.filter(v => v.tenantKey !== tenantKey)))
      return _getLoginData();
    },

    getTenantKey: (): string => {
      return localStorage.getItem(tenantStorageKey) || ''
    },

    setTenantKey: (tenantKey: string) => {
      localStorage.setItem(tenantStorageKey, tenantKey)
    },

    setCupPassword: (password: string) => {
      localStorage.setItem(cupPasswordKey, password)
    },

    getCupPassword: (): string => {
      return localStorage.getItem(cupPasswordKey)
    },

    resetTenantKey: () => {
      localStorage.removeItem(tenantStorageKey)
    },

    resetCupPassword: () => {
      localStorage.removeItem(cupPasswordKey)
    }
  }
}
