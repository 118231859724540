import TitledDivider from "../../../components/layout/TitledDivider";
import React, {useEffect, useMemo, useState} from "react";
import {
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography
} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {EmptyListMessage} from "../../../components/empty/EmptyContainer";
import {TypographyEllipsis} from "../../../components/output/TypographyEllipsis";
import {DeleteGameConfirmDialog} from "./DeleteGameConfirmDialog";
import {Game} from "../../../api/games/model";
import {Tenant} from "../../../api/tenants/model";
import {useGamesState} from "../../../api/games/useGames";
import {SettingsGameResultForm} from "./SettingsGameResultForm";
import {EditGameDialog} from "./EditGameDialog";
import {ChallengeDialog} from "../../home/challenges/ChallengeDialog";


interface SettingsGamesComponentProps {
  tenant: Tenant
}

export const SettingsGamesComponent: React.FC<SettingsGamesComponentProps> = ({tenant}) => {
  const theme = useTheme()
  const {openGames, finishedGames} = useGamesState();
  const games = useMemo(() => [...(openGames || []), ...(finishedGames || [])], [openGames, finishedGames])
  const [selectedGameId, setSelectedGameId] = useState<number | undefined>()
  const selectedGame = games?.find((game) => game.id === selectedGameId)

  useEffect(() => {
    if (!games || games.length === 0) {
      setSelectedGameId(undefined)
      return
    }
    if (selectedGameId) {
      games.find((game) => game.id === selectedGameId) ? setSelectedGameId(selectedGameId) : setSelectedGameId(games[0].id)
    } else {
      setSelectedGameId(games[0].id)
    }
  }, [selectedGameId, games])


  const handleSelectGame = (game: Game) => {
    setSelectedGameId(game.id)
  }

  const createPlayerNames = (game: Game) => {
    const challenger = game.challenger.name
    const defender = game.defender.name

    return (
        <Typography>
          <Typography component="span"
                      style={{fontWeight: game.challenger.id === game.winner?.id ? 700 : 400}}>
            {challenger}
          </Typography>
          <Typography component="span">{' - '}</Typography>
          <Typography
              component="span"
              style={{fontWeight: game.defender.id === game.winner?.id ? 700 : 400}}>
            {defender}
          </Typography>
        </Typography>
    )
  }

  const createGameListEntry = (game: any) => {
    return (
        <Grid container>
          <Grid item xs={12}><Typography variant="subtitle2"
                                         color="textSecondary">{`#${game.id} - ${game.challengeTimestamp.toLocaleString()}`}</Typography></Grid>
          <Grid item container justifyContent='flex-end' xs={9}>{}</Grid>
          <Grid item xs={8}>{createPlayerNames(game)}</Grid>
          {game.result && <Grid item xs={4} container
                                justifyContent='flex-end'><TypographyEllipsis>{game.result}</TypographyEllipsis></Grid>}
          {game.result === undefined &&
              <Grid item xs={4} container
                    justifyContent='flex-end'><TypographyEllipsis>offen</TypographyEllipsis></Grid>}
          {game.result === null &&
              <Grid item xs={4} container
                    justifyContent='flex-end'><TypographyEllipsis>-</TypographyEllipsis></Grid>}
        </Grid>
    )
  }

  return (
      <section id="games">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitledDivider title="Spiele & Forderungen"/>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Paper style={{padding: theme.spacing(2), height: '100%'}}>
              <Grid container alignItems="flex-start" spacing={2}>
                <Grid item xs={4}>
                  <Typography variant="overline">Spiele</Typography>
                </Grid>
                <Grid item xs={8} container justifyContent="flex-end">
                  <ChallengeDialog tenantStatus={tenant.status} buttonTitle="Neue Forderung"
                                   dialogTitle="Forderung erstellen" buttonVariant={"outlined"}
                                   buttonColor={"primary"} disabled={!tenant.accessGranted}
                                   admin={true}/>
                </Grid>
                <Grid item xs={12}>
                  <List component="nav" style={{height: 325, overflow: 'auto'}}>
                    {games.map((g) => {
                      return (
                          <ListItem
                              button
                              key={g.id}
                              selected={g.id === selectedGame?.id}
                              onClick={() => handleSelectGame(g)}
                          >
                            <ListItemText primary={createGameListEntry(g)}/>
                          </ListItem>
                      )
                    })}
                    {(!games || games.length === 0) &&
                        <EmptyListMessage emptyMessage="Noch keine Spiele vorhanden."/>}
                  </List>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            {selectedGame &&
                <Card style={{
                  height: '100%',
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}>
                  <CardContent>
                    <Grid container spacing={2}>
                      <Grid item xs={12}>
                        <Typography variant="overline">Details</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <SettingsGameResultForm
                            game={selectedGame}/>
                      </Grid>
                    </Grid>
                  </CardContent>
                  <CardActions>
                    <Grid container>
                      <Grid item xs={12} container justifyContent='space-between'
                            alignItems='flex-end'>
                        <DeleteGameConfirmDialog game={selectedGame}/>
                        {selectedGame &&
                            <EditGameDialog game={selectedGame}/>
                        }
                      </Grid>
                    </Grid>
                  </CardActions>
                </Card>}
          </Grid>
        </Grid>
      </section>
  )
}
