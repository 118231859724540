import './App.css';
import React from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {SettingsPage} from "./app/pages/settings/SettingsPage";
import {MuiThemeProvider} from "@material-ui/core";
import light from "./app/theme/light";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {PublicPage} from "./app/pages/public/PublicPage";
import {CreateTenantPage} from "./app/pages/public/CreateTenantPage";
import {cupAuthService} from "./app/auth/cupAuthService";
import {LocalizationProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import deLocale from 'date-fns/locale/de'
import {loadStripe} from "@stripe/stripe-js";
import {HomePage} from "./app/pages/home/HomePage";
import {QueryClient, QueryClientProvider} from 'react-query';
import {ReadOnlyCupPage} from "./app/pages/readonly/ReadOnlyCupPage";

const useStyles = makeStyles((theme: Theme) => {
  return {
    appBackground: {
      backgroundColor: theme.palette.background.default,
      height: '100%',
      minHeight: '100vh'
    }
  }
})

export const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY)
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const App = () => {
  const classes = useStyles()
  const {tenantKey, cupPassword} = cupAuthService().getCredentials()

  return (
      <QueryClientProvider client={queryClient}>
        <LocalizationProvider dateAdapter={DateFnsUtils} locale={deLocale}>
          <MuiThemeProvider theme={light}>
            <Router>
              <div className={classes.appBackground}>
                <Switch>

                  {tenantKey && cupPassword ?
                      (<>
                        <Route exact path="/">
                          <PublicPage/>
                        </Route>
                        <Route path="/settings">
                          <SettingsPage/>
                        </Route>
                        <Route path="/new">
                          <CreateTenantPage/>
                        </Route>
                        <Route path="/login">
                          <PublicPage/>
                        </Route>
                        <Route path="/cup">
                          <HomePage/>
                        </Route>
                        <Route path="/view">
                          <ReadOnlyCupPage/>
                        </Route>
                      </>) : (
                          <>
                            <Route exact path="/">
                              <PublicPage/>
                            </Route>
                            <Route path="/new">
                              <CreateTenantPage/>
                            </Route>
                            <Route path="/login">
                              <PublicPage/>
                            </Route>
                            <Route path="/view">
                              <ReadOnlyCupPage/>
                            </Route>
                          </>
                      )
                  }
                </Switch>
              </div>
            </Router>
          </MuiThemeProvider>
        </LocalizationProvider>
      </QueryClientProvider>
  );
}


export default App;
