import {Box, Card, CardContent, Grid, Typography} from "@material-ui/core";
import TitledDivider from "../../../components/layout/TitledDivider";
import React from "react";
import {OutputField} from "../../../components/output/OutputField";
import {SubscriptionDTO} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/SubscriptionDTO";
import {formatDateTime} from "../../../util/date";
import {UpgradeSubscriptionDialog} from "./dialogs/UpgradeSubscriptionDialog";
import {UpdatePaymentMethodDialog} from "./dialogs/UpdatePaymentMethodDialog";
import {StripeRedirectDialog} from "./dialogs/StripeRedirectDialog";
import {CancelSubscriptionDialog} from "./dialogs/CancelSubscriptionDialog";
import {ReactivateSubscriptionDialog} from "./dialogs/ReactivateSubscriptionDialog";


interface BillingInfoProps {
    subscription?: SubscriptionDTO,
    onUpdateSubscription: (subscription: SubscriptionDTO)=>void
}

export const BillingInfo: React.FC<BillingInfoProps> = ({subscription, onUpdateSubscription}) => {

    const determineStateText = (subscription?: SubscriptionDTO): string => {
        if (!subscription) return ""
        switch (subscription.accessReasonType) {
            case "ACTIVE_CANCELING":
                return "Aktiv (wird beendet)"
            case "ACTIVE_CONTINUING":
            case "ACTIVE_TRIALING":
                return "Aktiv"
            case "PAST_DUE":
                return "Aktiv (Zahlung überfällig)"
            case "CANCELED_EXPIRED":
            case "TRIALING_EXPIRED":
                return "Beendet"
            case "TRIALING":
            case "CANCELED_TRIALING":
                return "Testphase"
            case "PROBLEM_TRIALING":
            case "PROBLEM":
                return "Problem"
        }
    }

    const determinePaymentLabel = (subscription?: SubscriptionDTO): string => {
        switch (subscription?.paymentMethodType) {
            case "CARD":
                return "Kreditkarte"
            default:
                return "Zahlungsart"
        }
    }

    const determinePaymentErrorText = (subscription?: SubscriptionDTO) => {
        switch (subscription?.paymentMethodErrorType) {
            case "ERROR":
                return <Box width="100%" display="flex" flexDirection="column">
                    <Typography color="error">Beim Zahlungsvorgang ist ein Fehler
                        aufgetreten.<br/><br/>Bitte überprüfen Sie die Zahlungsmethode oder
                        probieren
                        Sie es gegebenenfalls mit einer Anderen.<br/><br/></Typography>
                    <UpdatePaymentMethodDialog variant="contained"
                                               buttonText="Zahlungsart ändern"/></Box>
            case "ERROR_EXPIRED":
                return <Box width="100%" display="flex" flexDirection="column">
                    <Typography color="error">Beim Zahlungsvorgang ist ein Fehler
                        aufgetreten.<br/><br/>Das Abo wurde beendet.<br/><br/></Typography>
                </Box>
            case "NO_PAYMENT_METHOD":
                return <Box width="100%" display="flex" flexDirection="column">
                    <Typography component="div" color="error">Bitte fügen Sie eine Zahlungsmethode
                        hinzu.</Typography>
                    <UpdatePaymentMethodDialog variant="contained"
                                               buttonText="Zahlungsart hinzufügen"/></Box>
        }
    }

    const displayPaymentMethod = (subscription?: SubscriptionDTO) => {
        return subscription?.paymentMethodString
    }

    const determineActionButton = (subscription?: SubscriptionDTO) => {
        switch (subscription?.possibleActionType) {
            case "SUBSCRIBE":
                return <UpgradeSubscriptionDialog onAbort={() => onUpdateSubscription}/>
            case "REACTIVATE":
                return <ReactivateSubscriptionDialog onSuccess={onUpdateSubscription}/>
            case "CANCEL":
                return <CancelSubscriptionDialog subscription={subscription}
                                                 onSuccess={onUpdateSubscription}/>
            case "NO_ACTION":
            default:
                return <></>
        }
    }

    const determineValidUntilHelperText = (subscription?: SubscriptionDTO) => {
        switch (subscription?.accessReasonType) {
            case "ACTIVE_TRIALING":
            case "ACTIVE_CONTINUING":
                return "Wird automatisch verlängert"
            case "PAST_DUE":
                return "unter Vorbehalt, sofern Zahlung erfolgt."
            case "ACTIVE_CANCELING":
                return "Endet nach dieser Periode"
            case "PROBLEM_TRIALING":
            case "CANCELED_TRIALING":
            case "TRIALING":
                return "Endet nach der Testphase"
            case "CANCELED_EXPIRED":
            case "TRIALING_EXPIRED":
            case "PROBLEM":
                return " "
        }
    }

    return (
        <section id="billingInfo">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TitledDivider title="Abo"/>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Card style={{height: '100%'}}>
                        <CardContent>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography variant="overline">Abo</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <OutputField
                                        value={determineStateText(subscription)}
                                        label='Status'/>
                                </Grid>
                                <Grid item xs={12}>
                                    <OutputField
                                        value={formatDateTime(subscription?.accessGrantedUntil) || 'kein Zugriff'}
                                        label="Zugriff bis"
                                        helperText={determineValidUntilHelperText(subscription)}
                                    />
                                </Grid>

                                <Grid item xs={12}>
                                    {determineActionButton(subscription)}
                                </Grid>
                                <Grid item xs={12}>
                                    {determinePaymentErrorText(subscription)}
                                </Grid>
                                {displayPaymentMethod(subscription) &&
                                    <>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant="overline">Zahlungsdaten</Typography>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <OutputField
                                                value={subscription?.paymentMethodString}
                                                label={determinePaymentLabel(subscription)}/>
                                        </Grid>
                                        <Grid item xs={12} container justifyContent="flex-end"
                                              spacing={0}>
                                            <UpdatePaymentMethodDialog/>
                                        </Grid></>}
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
            <StripeRedirectDialog/>
        </section>
    )
}
