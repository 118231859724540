import {Tenant} from "./model";
import {
  TenantsApiResponseProcessor
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/TenantsApi";
import {ResponseContext, TenantDTO} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {handleAuthorization} from "../error/error";


export class TenantsApiResponseConverter extends TenantsApiResponseProcessor {

  public async get(response: ResponseContext): Promise<Tenant> {
    if (handleAuthorization(response)) return
    return super.get(response).then(convertTenantDTO)
  }

  public async create(response: ResponseContext): Promise<Tenant> {
    if (handleAuthorization(response)) return
    return super.create(response).then(convertTenantDTO);
  }

  public async login(response: ResponseContext): Promise<Tenant> {
    if (handleAuthorization(response)) return
    return super.login(response).then(convertTenantDTO)
  }

  public async update(response: ResponseContext): Promise<void> {
    if (handleAuthorization(response)) return
    return super.update(response)
  }

}

const convertTenantDTO = (tenantDTO: TenantDTO): Tenant => {
  return {...tenantDTO, createdAt: new Date(tenantDTO.createdAt)}
}
