import React from "react";
import {useGamesState} from "../../../api/games/useGames";
import {ChallengesListComponent} from "./ChallengesListComponent";


export const ChallengesList = () => {
  const {openGames, isLoading, isSuccess, isError, error} = useGamesState();

  return (
      <ChallengesListComponent openGames={openGames} isLoading={isLoading} isSuccess={isSuccess}
                               isError={isError} error={error}/>
  )
}
