import {TenantDTOStatusEnum} from "@pyramid-cup/pyramid-cup-api-client-ts";

export interface Tenant {
    id: number,
    tenantKey: string
    name: string,
    cupPassword: string,
    createdAt: Date,
    startTimestamp: Date,
    status: TenantDTOStatusEnum,
    accessGranted: boolean
}

export const emptyTenant = (): Tenant => {
    return {
        id: 0,
        tenantKey: '',
        name: '',
        cupPassword: '',
        createdAt: undefined,
        startTimestamp: new Date(0),
        status: 'NOT_STARTED',
        accessGranted: true
    }
}


export interface TenantConfiguration {
    name: string,
    cupPassword: string
    startTimestamp: Date
    adminEmail: string
    adminPassword: string
    adminPasswordRepeat: string
    useExistingAccount: boolean
    consentReceiveEmails: boolean
}

export const createEmptyTenantConfig = () => {
    const startTimestamp = new Date()
    startTimestamp.setHours(startTimestamp.getHours()+1,0,0,0)
    return {
        name: '',
        cupPassword: '',
        startTimestamp,
        adminEmail: '',
        adminPassword: '',
        adminPasswordRepeat:'',
        useExistingAccount: false,
        consentReceiveEmails: false
    }
}
