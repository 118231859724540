import {server1, server2} from "@pyramid-cup/pyramid-cup-api-client-ts";

export const getServerUrl = (): string => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        return  server2["url"]
    }else {
        return server1["url"]
    }
}

export const getStripeRedirectUrl = (): string => {
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
        return  "https://app.pyramidencup.at/settings"
    }else {
        return "http://localhost:3000/settings"
    }
}


