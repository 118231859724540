import React, {ReactElement} from "react";
import {FormDialog} from "../../../../components/form/FormDialog";
import {
  FinishGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/FinishGameRequest";
import {useFinishGame} from "../../../../api/games/useGames";
import {useForm} from "react-hook-form";
import {Grid, Typography} from "@material-ui/core";
import {PlayerLite} from "../../../../api/player/model";
import {toOption} from "../../../../components/inputs/singleSelect/option";
import {SelectInput} from "../../../../components/form/inputs/SelectInput";
import {TextInput} from "../../../../components/form/inputs/TextInput";

interface FinishGameDialogNewProps {
  gameId: number
  challenger: PlayerLite
  defender: PlayerLite
}

export const FinishGameDialog = ({
                                   gameId,
                                   challenger,
                                   defender
                                 }: FinishGameDialogNewProps): ReactElement => {
  const mutation = useFinishGame();
  const useFormReturn = useForm<FinishGameRequest>({
    defaultValues: {
      gameId,
      winnerId: challenger.id,
      result: ""
    }
  });

  const toSelectionOption = (player: PlayerLite) => {
    return toOption(player.id, `${player.name}`, player.id)
  }

  const options = [challenger, defender].map((p) => {
    return toSelectionOption(p)
  })

  return (
      <FormDialog<FinishGameRequest, number>
          mutation={mutation}
          useFormReturn={useFormReturn} buttonText="Ergebnis
                eintragen" dialogTitel="Ergebnis eintragen"
          buttonProps={{
            variant: "contained",
            color: "primary",
            size: "small"
          }}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Typography variant={"overline"}>Sieger</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectInput
                name="winnerId"
                useFormReturn={useFormReturn}
                options={options}
                label={"Sieger"}
                fullWidth
            />
          </Grid>
          <Grid item xs={12} style={{marginTop: 2}}>
            <Typography variant={"overline"}>Resultat (optional)</Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center">
            <TextInput name="result" useFormReturn={useFormReturn} label="Resultat"
                       fullWidth helperText={`${challenger.name} gegen ${defender.name}`}
            />
          </Grid>
        </Grid>
      </FormDialog>
  );
};
