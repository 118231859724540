export const millisToString = (millis: number): string => {
    const hours = millisToFlooredHours(millis)
    let minutes = millisToFlooredMinutes(millis)
    if (hours === 0 && minutes === 0 && millis > 0) {
        return '<1min'
    }
    return hoursAndMinutesToString(hours, minutes)
}

export const hoursAndMinutesToString = (hours: number, minutes: number): string => {
    let string = hours !== 0 ? `${hours}h` : ''
    string += minutes !== 0 ? ` ${minutes}min` : ''
    if (hours === 0 && minutes === 0) {
        string = '0min'
    }
    return string.trim()
}

export const hoursAndMinutesToMillis = (hours: number, minutes: number): number => {
    return hours * 60 * 60 * 1000 + minutes * 60 * 1000

}

export const millisToFlooredHours = (durationInMillis: number): number => {
    return Math.floor(durationInMillis / (60 * 60 * 1000))
}

export const millisToCeiledMinutes = (durationInMillis: number): number => {
    return Math.ceil((durationInMillis - millisToFlooredHours(durationInMillis) * 60 * 60 * 1000) / (60 * 1000))
}

export const millisToFlooredMinutes = (durationInMillis: number): number => {
    return Math.floor((durationInMillis - millisToFlooredHours(durationInMillis) * 60 * 60 * 1000) / (60 * 1000))
}