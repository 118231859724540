import {millisToCeiledMinutes, millisToFlooredHours} from "../../util/duration";

export interface Configuration {
  id: number,
  tenantKey: string,
  topXPlayersCanChallengeBetterPlayers: number;
  minChallengeRankType: string;
  defenderProtectionDurationMillis: number,
  challengerFreezeDurationMillis: number,
  instantRevengeBanDurationMillis: number,
  publicUrlParam: string,
  createdAt: Date,
}

export enum MinChallengeRankType {
  FULL_UPPER_ROW = "FULL_UPPER_ROW", RIGHTSIDE_UPPER_ROW = "RIGHTSIDE_UPPER_ROW"
}

export const MinChallengeRankTypes = [MinChallengeRankType.FULL_UPPER_ROW, MinChallengeRankType.RIGHTSIDE_UPPER_ROW]

export interface HoursMinutes {
  hours: number
  minutes: number
}

export const toHoursMinutes = (millis: number): HoursMinutes => {
  return {
    hours: millisToFlooredHours(millis),
    minutes: millisToCeiledMinutes(millis)
  }
}


