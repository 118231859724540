import React from 'react';
import {PageWrapper} from "../../components/page/PageWrapper";
import {Grid} from "@material-ui/core";
import {CreateCupWizard} from "./CreateCupWizard";


export const CreateTenantPage = () => {

    return (
        <PageWrapper title="Willkommen">
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <CreateCupWizard />
                </Grid>
            </Grid>
        </PageWrapper>
    )
}
