import React, {ReactElement} from "react";
import {Controller, FieldValues, Path, UseFormReturn} from "react-hook-form";
import {MaterialSelect} from "../../inputs/singleSelect/MaterialSelectField";
import {Option} from "../../inputs/singleSelect/option";
import {TextInput} from "./TextInput";

interface SelectInputProps<T extends FieldValues> {
  name: Path<T>;
  useFormReturn: UseFormReturn<T>;
  options: Option[];
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  readonly?: boolean;
}

export const SelectInput = <T extends FieldValues>({
                                                     name,
                                                     useFormReturn,
                                                     options,
                                                     label,
                                                     fullWidth,
                                                     disabled,
                                                     readonly,
                                                   }: SelectInputProps<T>): ReactElement => {
  if (readonly) {
    return <TextInput name={name} useFormReturn={useFormReturn}
                      valueFormatter={(value) => options.find((o) => o.value === value)?.label || value || ""}
                      readonly={readonly}/>
  }
  return (
      <Controller
          name={name}
          control={useFormReturn.control}
          render={({field}) => <MaterialSelect {...field}
                                               label={label}
                                               fullWidth={fullWidth}
                                               options={options}
                                               disabled={disabled}
                                               dense={true}
          />}
      />
  );
};
