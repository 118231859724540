import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import {Transition} from "../../../../components/layout/DialogTransition";
import {billingApi} from "../../../../api/BillingApi";
import {getStripeRedirectUrl} from "../../../../api/serverConfig";
import {Stripe, StripeElements} from "@stripe/stripe-js";
import {StripePaymentForm} from "./StripePaymentForm";
import {toErrorMessage} from "../../../../api/error/error";
import {ClientSecretDTO} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {ProgressIndicator} from "./ProgressIndicator";


interface UpdatePaymentMethodDialogProps {
    variant?: 'text' | 'outlined' | 'contained';
    buttonText?: string
}

export const UpdatePaymentMethodDialog: React.FC<UpdatePaymentMethodDialogProps> = ({variant, buttonText}) => {
    const [open, setOpen] = useState(false);
    const [serverMessage, setServerMessage] = useState<string>('')
    const [clientSecret, setClientSecret] = useState<ClientSecretDTO>(undefined)
    const paymentFormId = 'updatePaymentMethodForm'
    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)

    const handleClickOpen = async () => {
        resetDialog()
        setOpen(true);
        await loadClientSecret()
    };

    const resetDialog = () => {
        setServerMessage("")
        setIsLoading(false)
        setIsSubmitting(false)
        setClientSecret(undefined)
    }

    const loadClientSecret = async () => {
        try {
            setIsLoading(true)
            const clientSecret = await billingApi.billing().getClientSecret()
            setClientSecret(clientSecret)
        } catch (e) {
            setServerMessage(toErrorMessage(e))
        }
    }

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmitClicked = () => {
        setIsSubmitting(true)
        const form = document.getElementById(paymentFormId)
        if (form) {
            form.dispatchEvent(new Event('submit', {
                cancelable: true,
                bubbles: true
            }))
        }
    }

    const onPaymentFormSubmitted = async (stripe: Stripe, elements: StripeElements) => {
        let result = null
        const options = {
            elements,
            confirmParams: {
                return_url: getStripeRedirectUrl(),
            },
        }
        if (clientSecret.type === "SETUP_INTENT") {
            result = await stripe.confirmSetup(options);
        }
        if (clientSecret.type === "PAYMENT_INTENT") {
            result = await stripe.confirmPayment(options)
        }
        if (result && result.error) {
            setServerMessage(result.error.message)
        }
        setIsSubmitting(false)
    }

    return (
        <>
            <Button color="secondary" variant={variant || 'text'} onClick={handleClickOpen}>{buttonText || "Zahlungsart ändern"}</Button>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}
                    aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">{buttonText || "Zahlungsart ändern"}</DialogTitle>
                <DialogContent style={{overflowY: "initial", minHeight: "366px"}}>
                    {clientSecret &&
                        <StripePaymentForm formId={paymentFormId} clientSecret={clientSecret.secret}
                                           onPaymentFormReady={() => setIsLoading(false)}
                                           onSubmit={onPaymentFormSubmitted}/>}
                    <Box paddingTop={"15px"}>
                        <Typography color="error">{serverMessage}</Typography>
                    </Box>
                    {isLoading && <ProgressIndicator/>}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined"
                            color="primary">Abbrechen</Button>
                    <Button type="button" variant="contained" color="primary" style={{minWidth: "100px"}}
                                              onClick={handleSubmitClicked}
                                              disabled={isLoading || isSubmitting}>{isSubmitting ? <CircularProgress size={25}/> : 'Ändern'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
