import React, {ReactElement, useEffect, useState} from "react";
import {Box, Divider, Grid, Paper, Typography} from "@material-ui/core";
import {createStyles, makeStyles, Theme, useTheme} from "@material-ui/core/styles";
import {EmptyContainer} from "../../../components/empty/EmptyContainer";
import {TypographyEllipsis} from "../../../components/output/TypographyEllipsis";
import {createPyramidData} from "./pyramidUtil";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {Player} from "../../../api/player/model";
import {toErrorMessage} from "../../../api/error/error";
import {ApiError, ApiException} from "@pyramid-cup/pyramid-cup-api-client-ts";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
          pyramidElement: {
            width: '190px',
            height: '40px',
            padding: theme.spacing(1),
            paddingBottom: 0,
            paddingTop: 12,
            backgroundColor: theme.palette.primary.main,
            marginLeft: theme.spacing(1),
            marginRight: theme.spacing(1),
            marginTop: theme.spacing(1),
            color: theme.palette.getContrastText(theme.palette.primary.main),
            cursor: 'pointer'
          },
          playerName: {
            width: '100%'
          }
        }
    )
)

export enum PyramidElementStatus {
  SELECTED = 'SELECTED',
  UNCHALLENGABLE = 'UNCHALLENGABLE',
  CHALLANGEABLE = 'CHALLENGEABLE',
  OPPONENT = 'OPPONENT',
  DEFAULT = 'DEFAULT',
  INACTIVE = 'INACTIVE'
}

export interface PyramidElementData {
  id: number
  name: string
  rank: number
  subtitle: string
  status: PyramidElementStatus
}

interface PyramidElementProps {
  pyramidElement: PyramidElementData
  onClick: (pyramidElement: PyramidElementData) => void
}

export const PyramidElement: React.FC<PyramidElementProps> = ({pyramidElement, onClick}) => {
  const classes = useStyles()
  const theme = useTheme()

  const handleClick = (pyramidElement: PyramidElementData) => {
    onClick(pyramidElement)
  }

  const determineBackgroundColor = (pyramidElement: PyramidElementData) => {
    switch (pyramidElement.status) {
      case PyramidElementStatus.SELECTED:
        return theme.palette.secondary.main
      case PyramidElementStatus.CHALLANGEABLE:
        return `${theme.palette.success.dark}`
      case PyramidElementStatus.OPPONENT:
        return theme.palette.warning.dark
      case PyramidElementStatus.UNCHALLENGABLE:
      case PyramidElementStatus.INACTIVE:
        return `repeating-linear-gradient(  45deg,  ${theme.palette.primary.main},  ${theme.palette.primary.main} 13px,  ${theme.palette.primary.light} 13px,  ${theme.palette.primary.light} 26px)`
      case PyramidElementStatus.DEFAULT:
        return ''
      default:
        return ''
    }
  }
  return (
      <Paper className={classes.pyramidElement} style={{
        background: determineBackgroundColor(pyramidElement),
        cursor: pyramidElement.rank ? 'cursor' : 'auto'
      }}
             elevation={2}
             onClick={() => handleClick(pyramidElement)}>
        <Grid container alignItems="center" justifyContent="center">
          <Grid item xs={12} container alignItems="center" justifyContent="center">
            <div style={{maxWidth: "160px"}}>
              <TypographyEllipsis component="div">
                {pyramidElement.name}
              </TypographyEllipsis>
            </div>
            <Typography variant="caption"
                        component="span">&nbsp;{pyramidElement.rank !== 0 && `(${pyramidElement.rank})`}</Typography>
          </Grid>
          <Grid item xs={12} container alignItems="center" justifyContent="center"
                style={{marginTop: '-3px'}}>
            <Typography variant="caption"
                        style={{fontSize: 10}}>{pyramidElement.subtitle}</Typography>
          </Grid>
        </Grid>
      </Paper>
  )
}

interface PyramidRowProps {
  pyramidElements: PyramidElementData[]
  rowNumber: number
  onPyramidElementClicked: (pyramidElement: PyramidElementData) => void
}

export const PyramidRow: React.FC<PyramidRowProps> = ({
                                                        pyramidElements,
                                                        rowNumber,
                                                        onPyramidElementClicked
                                                      }) => {

  const handlePyramidElementClicked = (pyramidElement: PyramidElementData) => {
    onPyramidElementClicked(pyramidElement)
  }

  return (
      <>
        {/* MOBILE*/}
        <Box component="div" flexDirection="column" justifyContent="center" m={2} marginTop={0}
             marginBottom={3}
             display={{xs: 'flex', sm: 'flex', md: 'none'}}>
          <Grid container>
            <Grid item xs={8}>
              {pyramidElements.map((p, index) => <PyramidElement key={index} pyramidElement={p}
                                                                 onClick={() => handlePyramidElementClicked(p)}/>)}
            </Grid>
            <Grid item xs={4} container justifyContent='flex-end' alignItems='flex-end'>
              <Typography variant='h5' color='textSecondary'>{'R' + rowNumber}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Box marginTop={1}>
                <Divider/>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {/* DESKTOP*/}
        <Box component="div" flexDirection="row" justifyContent="center" m={2}
             display={{xs: 'none', sm: 'none', md: 'flex'}}>
          {pyramidElements.map((p, index) => <PyramidElement key={index} pyramidElement={p}
                                                             onClick={() => handlePyramidElementClicked(p)}/>)}
        </Box>
      </>
  )
}


type PyramidComponentProps = {
  players: Player[]
  isLoading: boolean,
  isError: boolean,
  error: ApiException<ApiError>
}

export const PyramidComponent = ({
                                   players,
                                   isLoading,
                                   isError,
                                   error
                                 }: PyramidComponentProps): ReactElement => {

  const [selectedPlayer, setSelectedPlayer] = useState<any>(undefined)
  const [pyramidElements, setPyramidElements] = useState<PyramidElementData[][]>([])

  useEffect(() => {
    if (!players) return;
    const pyramidData = createPyramidData(selectedPlayer, players)
    setPyramidElements(pyramidData)
  }, [selectedPlayer, players])

  const handlePyramidElementClicked = (pyramidElement: PyramidElementData) => {
    if (selectedPlayer && selectedPlayer.id === pyramidElement.id) {
      setSelectedPlayer(undefined)
    } else {
      players.flatMap((p: Player) => p).filter((p: Player) => p.id === pyramidElement.id).forEach((p: any) => setSelectedPlayer(p))
    }
  }

  const determineEmptyMessage = () => {
    if (isLoading) return "Rangliste wird geladen";
    if (isError) return `Fehler beim Laden.\n${toErrorMessage(error)}`;
    return "Keine Spieler in der Rangliste vorhanden."
  }

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginLeft={2}>
            <Typography variant='overline'>Rangliste</Typography>
          </Box>
        </Grid>
        {(pyramidElements && pyramidElements.length > 0) && <Grid item xs={12}>
          <Box marginLeft={2} marginRight={2} display="flex" alignItems='center'
               justifyContent='center'>
            <InfoOutlinedIcon fontSize='small' style={{marginRight: 4}}/>
            <Typography variant='caption'>Um mögliche Gegner anzuzeigen, bitte auf einen Spieler
              drücken </Typography>
          </Box>
        </Grid>}
        {(!pyramidElements || pyramidElements.length === 0) && <Grid item xs={12}>
          {/*TODO usePlayerState everywhere*/}
          <EmptyContainer
              emptyMessage={determineEmptyMessage()}
              loading={isLoading}/>
        </Grid>}
        <Grid item xs={12}>
          {pyramidElements.map((row, index) => {
            return (
                <PyramidRow key={index} pyramidElements={row} rowNumber={index + 1}
                            onPyramidElementClicked={handlePyramidElementClicked}/>
            )
          })}
        </Grid>
      </Grid>
  )
}
