

export const navigateToHome = ()=>{
  window.location.href = "/"
}

export const navigateToLogin = ()=>{
  window.location.href = "/login"
}

export const navigateToCup = ()=>{
  window.location.href = "/cup"
}

export const navigateToSettings = ()=>{
  window.location.href = "/settings"
}

export const navigateToNewCup = (tenantKey: string, tenantName: string)=>{
  window.location.href = `/login?key=${tenantKey}&name=${tenantName}&type=new`
}
