import {
    Avatar,
    Button,
    Card,
    CardContent,
    Grid,
    IconButton,
    Link,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    Typography
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {PageWrapper} from "../../components/page/PageWrapper";
import {Form} from "react-final-form";
import {SingleLineField} from "../../components/inputs/singleLineInput/SingleLineField";
import {toErrorMessage} from "../../api/error/error";
import {useLocation} from "react-router-dom";
import {Tenant} from "../../api/tenants/model";
import {Md5} from 'ts-md5/dist/md5';
import {autoLoginDetection, cupAuthService} from "../../auth/cupAuthService";
import DeleteIcon from '@material-ui/icons/Delete';
import {Stars} from "@material-ui/icons";
import {localStorageService, StoredCredential} from "../../localstorage/LocalStorageService";
import {navigateToCup} from "../../util/navigation";


export const PublicPage = () => {
  const [serverMessage, setServerMessage] = useState('')
  const [tenant, setTenant] = useState<Tenant>(null)
  const {search} = useLocation()
  const params = new URLSearchParams(search)
  const key = params.get("key")
  const name = params.get("name")
  const type = params.get("type")

  const onLoginSuccess = (tenant: Tenant) => {
    setTenant(tenant)
    navigateToCup();
  }

  const onLoginFailed = (e: any) => {
    if (e?.body?.messageKey === 'CUP_LOGIN_FAILED') {
      cupAuthService().logout()
    }
    setServerMessage(toErrorMessage(e))
  }

  useEffect(() => {
    autoLoginDetection()
    .then(tenant => {
      if (tenant) {
        onLoginSuccess(tenant);
      }
    }).catch(e => {
      onLoginFailed(e)
    })
  }, [])

  const onSubmit = async (values) => {
    setServerMessage(' ')
    if (!values.username) {
      setServerMessage('Bitte geben Sie einen Wert ein.')
      return
    }
    if (!values.cupPassword) {
      setServerMessage('Bitte geben Sie ein Passwort ein.')
      return
    }
    const password = Md5.hashStr(values.cupPassword)
    await login(values.username, password);
  }

  const login = async (tenantKey: string, password: string) => {
    await cupAuthService().login(tenantKey, password).then(tenant => {
      onLoginSuccess(tenant)
    }).catch(e => {
      onLoginFailed(e)
    })
  }

  return (
      <PageWrapper title={'Willkommen'}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} sm={7} md={5} lg={4} container justifyContent="center">
            <Form onSubmit={onSubmit}
                  render={({handleSubmit}) => {
                    return (
                        <form id={'publicTenantKeySearch'} onSubmit={handleSubmit}
                              noValidate>
                          <Grid container style={{flexDirection: "column"}}>
                            {!tenant && name && key && (
                                <Grid item xs={12}>
                                  <CupCreationInfoCard cupKey={key} cupName={name}
                                                       type={type}/>
                                </Grid>
                            )}
                            <Grid item xs={12} style={{
                              textAlign: "center",
                              paddingTop: "16px",
                              paddingBottom: "36px"
                            }}>
                              <Typography color="primary" variant="h2" style={{
                                fontFamily: "Montserrat",
                                fontWeight: "bold"
                              }}>Pyramiden Cup</Typography>
                            </Grid>
                            <Grid item xs={12}>
                              {!tenant &&
                                  <SavedLoginCredentials loginFn={login}/>}
                            </Grid>
                            <Grid item xs={12}>
                              {!tenant &&
                                  <CupSearchCard serverMessage={serverMessage}/>}
                            </Grid>
                          </Grid>
                        </form>
                    )
                  }}
            />
          </Grid>
          <Grid container item xs={12} justifyContent="center">
            {!tenant && <><Typography variant="subtitle2" color="textSecondary">Neu
              hier? </Typography>
              <Link href="/new" variant="subtitle2" color="secondary"
                    style={{cursor: "pointer", paddingLeft: 5}}>Jetzt Cup
                erstellen</Link></>}
          </Grid>
        </Grid>
      </PageWrapper>
  );
}

interface CupSearchCardProps {
  serverMessage: string
}

const CupSearchCard: React.FC<CupSearchCardProps> = ({serverMessage}) => {
  return (
      <Card style={{paddingLeft: 20, paddingRight: 20}}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{paddingBottom: 10}}><Typography
                variant="overline">Cup suchen</Typography></Grid>
            <Grid item xs={12}>
              <SingleLineField name="username" label={'Name oder Schlüssel'}/>
            </Grid>
            <Grid item xs={12}>
              <SingleLineField name="cupPassword" type="password" label={'Cup Passwort'}/>
            </Grid>
            <Grid item xs={12}>
              <Button type="submit" variant="contained" fullWidth
                      color="primary">Suchen</Button>
            </Grid>
            <Grid item xs={12} style={{minHeight: 31, paddingTop: 10, textAlign: "center"}}>
              <Typography variant="caption">{serverMessage || ''}</Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}

interface SavedLoginCredentialsProps {
  loginFn: (tenantKey: string, password: string) => void;
}

const SavedLoginCredentials: React.FC<SavedLoginCredentialsProps> = ({loginFn}) => {
  const [storedCredentials, setStoredCredentials] = useState<StoredCredential[]>(localStorageService().getLoginData())

  const onDelete = (tenantKey) => {
    setStoredCredentials(localStorageService().removeLoginData(tenantKey));
  }

  if (storedCredentials.length === 0) return <></>
  return (
      <Card style={{paddingLeft: 20, paddingRight: 20, marginBottom: 20}}>
        <CardContent>
          <Grid container>
            <Grid item xs={12} style={{paddingBottom: 10}}><Typography
                variant="overline">Deine Cups</Typography></Grid>
            <Grid item xs={12}>
              <List>
                {storedCredentials.map((credential) => {
                  return (
                      <ListItem key={credential.tenantKey}
                                button
                                onClick={() => loginFn(credential.tenantKey, credential.cupPassword)}
                      >
                        <ListItemAvatar>
                          <Avatar>
                            <Stars/>
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                            primary={credential.tenantName}
                            secondary={credential.tenantKey}
                        />
                        <ListItemSecondaryAction
                            onClick={() => onDelete(credential.tenantKey)}>
                          <IconButton edge="end" aria-label="delete">
                            <DeleteIcon/>
                          </IconButton>
                        </ListItemSecondaryAction>
                      </ListItem>
                  )
                })}
              </List>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
  )
}


interface CupCreationInfoCardProps {
  cupKey: string
  cupName: string
  type: string
}

const CupCreationInfoCard: React.FC<CupCreationInfoCardProps> = ({cupKey, cupName, type}) => {
  return (
      <Card style={{
        paddingLeft: 20,
        paddingRight: 20,
        marginBottom: 20
      }}>
        <CardContent>
          <Typography variant="body2">Der
            Cup <TypographyPrimary>{cupName}</TypographyPrimary> mit
            dem
            Schlüssel <TypographyPrimary>{cupKey}</TypographyPrimary> wurde erfolgreich
            erstellt.</Typography>
          {type === "new" &&
              <><br/><br/><Typography variant="body2">Sie erhalten in Kürze eine E-Mail
                mit<TypographyPrimary> allen
                  Informationen</TypographyPrimary> zum Cup und der Möglichkeit
                Ihre <TypographyPrimary>E-Mail
                  Adresse</TypographyPrimary> zu <TypographyPrimary>bestätigen</TypographyPrimary>.</Typography></>}
          {type === "email" &&
              <><br/><br/><Typography variant="body2">Ihre E-Mail Adresse
                wurde <TypographyPrimary>erfolgreich</TypographyPrimary> bestätigt.</Typography></>}
        </CardContent>
      </Card>
  )
}

const TypographyPrimary: React.FC = ({children}) => {
  return (
      <Typography
          style={{fontWeight: "bold"}}
          component="span"
          variant="body2"
          color="primary">{children}</Typography>)
}
