import {createConfiguration, server1, server2} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {PromiseBillingStripeApi} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/types/PromiseAPI";
import {BillingApiRequestEnhancer} from "./billing/BillingApiRequestEnhancer";
import {BillingApiResponseConverter} from "./billing/BillingApiResponseConverter";

export class BillingApi {
    private readonly clientConfiguration;

    private readonly billingApi;

    constructor() {
        this.clientConfiguration = createConfiguration({baseServer: server1})
        if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
            this.clientConfiguration = createConfiguration({baseServer: server2})
        }
        this.billingApi = new PromiseBillingStripeApi(this.clientConfiguration, new BillingApiRequestEnhancer(this.clientConfiguration), new BillingApiResponseConverter())
    }

    billing(): PromiseBillingStripeApi {
        return this.billingApi
    }
}

export const billingApi = new BillingApi()
