import React from "react";
import {Box, InputAdornment, TextField} from "@material-ui/core";

interface OutputFieldProps {
  value: any
  label: string
  fullWidth?: boolean
  helperText?: string
  endAdornment?: any

}

export const OutputField: React.FC<OutputFieldProps> = ({
                                                          value,
                                                          label,
                                                          fullWidth = true,
                                                          helperText,
                                                          endAdornment
                                                        }) => {

  return (
      <Box style={{pointerEvents: 'none'}}>
        <TextField variant='outlined' label={label} size="small" value={value} fullWidth={fullWidth}
                   helperText={helperText}
                   InputProps={{
                     endAdornment: <InputAdornment position="end">{endAdornment}</InputAdornment>,
                   }}
        />
      </Box>
  )
}
