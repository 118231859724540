import {Box, Card, CardActions, CardContent, Grid, IconButton, Typography} from "@material-ui/core";
import TitledDivider from "../../../components/layout/TitledDivider";
import React, {ReactElement} from "react";
import {OutputField} from "../../../components/output/OutputField";
import {formatDateTime} from "../../../util/date";
import {useTenantState} from "../../../api/tenants/useTenant";
import {EditCupPasswordDialog} from "./dialogs/EditCupPasswordDialog";
import {EditStartTimestampDialog} from "./dialogs/EditStartTimestampDialog";
import {useConfigurationState} from "../../../api/configuration/useConfiguration";
import {FileCopy} from "@material-ui/icons";

export const TenantInfo = (): ReactElement => {
  const {tenant} = useTenantState();
  const {configuration} = useConfigurationState()
  const publicTenantInfoUrl = configuration?.publicUrlParam ? document.location.origin + "/view?param=" + configuration?.publicUrlParam : "";

  return (
      <section id="tenantInfo">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitledDivider title="Allgemeine Info"/>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <Card style={{height: '100%'}}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="overline">Cup Name</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OutputField value={tenant.name} label='Name'/>
                  </Grid>
                  <Grid item xs={6} md={5} lg={5}>
                    <OutputField value={tenant.tenantKey} label='Schlüssel'
                                 fullWidth={false}/>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
          <Grid item md={6} lg={8}/>
          <Grid item xs={12} md={6} lg={4}>
            <Card style={{height: '100%'}}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="overline">Cup Passwort</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OutputField value={tenant.cupPassword} label='Cup Passwort'/>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box display="flex" justifyContent={"flex-end"} width={'100%'}>
                  <EditCupPasswordDialog/>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={6} lg={8}/>
          <Grid item xs={12} md={6} lg={4}>
            <Card style={{height: '100%'}}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="overline">Start des Cups</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OutputField value={formatDateTime(tenant.startTimestamp)}
                                 label='Startzeitpunkt'/>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Box display="flex" justifyContent={"flex-end"} width={'100%'}>
                  <EditStartTimestampDialog/>
                </Box>
              </CardActions>
            </Card>
          </Grid>
          <Grid item md={6} lg={8}/>
          <Grid item xs={12} md={12} lg={4}>
            <Card style={{height: '100%'}}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="overline">Öffentliche URL</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <OutputField value={publicTenantInfoUrl}
                                 label={"Öffentliche URL"}
                                 endAdornment={<IconButton size="small"
                                                           style={{pointerEvents: "all"}}
                                                           onClick={() => navigator.clipboard.writeText(publicTenantInfoUrl)}>
                                   <FileCopy/>
                                 </IconButton>}/>

                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant="caption">Dieser Link kann verwendet werden um den Cup mit
                      Personen zu teilen, welche nicht daran teilnehmen. <br/> <br/> Auf der
                      angezeigten Seite werden die Pyramide, sowie offene und abgeschlossene Spiele
                      angezeigt. Es
                      können jedoch <b>keine Änderungen vorgenommen werden</b>.</Typography>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </section>
  )
}
