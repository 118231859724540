import {PublicApiRequestFactory} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/PublicApi";

import {Configuration} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/configuration";
import {RequestContext} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/http/http";

export class PublicApiRequestEnhancer extends PublicApiRequestFactory {
  public async getPublicTenantInfo(param?: string, _options?: Configuration): Promise<RequestContext> {
    return super.getPublicTenantInfo(param, _options);
  }

}
