import {
    PlayersApiResponseProcessor
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/PlayersApi";
import {Player, PlayerLite} from "./model";
import {PlayerDTO, PlayerLiteDTO, ResponseContext} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {handleAuthorization} from "../error/error";


export class PlayersApiResponseConverter extends PlayersApiResponseProcessor {

  public async create(response: ResponseContext): Promise<PlayerLite> {
    if (handleAuthorization(response)) return
    return super.create(response).then(convertPlayerLiteDTO)
  }

  public async getAll(response: ResponseContext): Promise<Array<Player>> {
    if (handleAuthorization(response)) return
    return super.getAll(response).then(convertPlayerDTOs) //.catch(() => [])// TODO handle when called
  }

  public async getAllLite(response: ResponseContext): Promise<Array<PlayerLite>> {
    if (handleAuthorization(response)) return
    return super.getAllLite(response).then(convertPlayerLiteDTOs).catch(() => [])// TODO handle when called
  }

  public async update(response: ResponseContext): Promise<PlayerLite> {
    if (handleAuthorization(response)) return
    return super.update(response).then(convertPlayerLiteDTO)
  }

  public async _delete(response: ResponseContext): Promise<void> {
    if (handleAuthorization(response)) return
    return super._delete(response)
  }
}

export const convertPlayerDTOs = (playerDTOs: PlayerDTO[]): Player[] => {
  return playerDTOs.map(convertPlayerDTO)
}

export const convertPlayerDTO = (playerDTO: PlayerDTO): Player => {
  return {
    ...playerDTO,
    challengeablePlayers: convertPlayerLiteDTOs(playerDTO.challengeablePlayers),
    blockedPlayerDueRevengeBan: convertPlayerLiteDTO(playerDTO.blockedPlayerDueRevengeBan),
    opponent: convertPlayerLiteDTO(playerDTO.opponent)
  }
}

export const convertPlayerLiteDTO = (playerLiteDTO: PlayerLiteDTO): PlayerLite => {
  if (!playerLiteDTO) return null
  return {...playerLiteDTO}
}

const convertPlayerLiteDTOs = (playerLiteDTOs: PlayerLiteDTO[]): PlayerLite[] => {
  return playerLiteDTOs.map(convertPlayerLiteDTO)
}

