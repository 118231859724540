import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Table,
    TableBody,
    TableCell,
    TableRow,
    TextField,
    Typography
} from "@material-ui/core";
import {Transition} from "../../../../components/layout/DialogTransition";
import {billingApi} from "../../../../api/BillingApi";
import {ClientSecretDTO, StripePriceDTO} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {getStripeRedirectUrl} from "../../../../api/serverConfig";
import {StripePaymentForm} from "./StripePaymentForm";
import {Stripe, StripeElements} from "@stripe/stripe-js";
import {ProgressIndicator} from "./ProgressIndicator";
import TitledDivider from "../../../../components/layout/TitledDivider";
import {PromoDTO} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/PromoDTO";
import {toErrorMessage} from "../../../../api/error/error";

interface UpgradeSubscriptionDialogProps {
    onAbort: () => void
}

export const UpgradeSubscriptionDialog: React.FC<UpgradeSubscriptionDialogProps> = ({onAbort}) => {
    const [open, setOpen] = useState(false);
    const [serverMessage, setServerMessage] = useState<string>('')
    const [activeStep, setActiveStep] = useState(0)
    const [prices, setPrices] = useState<StripePriceDTO[]>([])
    const [selectedPrice, setSelectedPrice] = useState<StripePriceDTO>(undefined)
    const [couponCode, setCouponCode] = useState('')
    const [clientSecret, setClientSecret] = useState<ClientSecretDTO>(undefined)

    const [isLoading, setIsLoading] = useState(false)
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isError, setIsError] = useState(false)
    const paymentFormId = 'paySubscriptionForm'

    const steps = ['Abo auswählen', 'Zahlungsdaten eingeben']

    const loadPrices = ()=>{
        setIsLoading(true)
        billingApi.billing().getPrices().then(prices => {
            setPrices(prices)
            setIsLoading(false)
        }).catch((e)=>{
            setIsError(true)
            setIsLoading(false)
            setServerMessage(toErrorMessage(e, "Bitte versuchen Sie es später noch einmal."))
        })
    }

    const handleClickOpen = () => {
        setClientSecret(undefined)
        setIsLoading(false)
        setIsError(false)
        setIsSubmitting(false)
        setPrices([])
        setSelectedPrice(undefined)
        setCouponCode('')
        setActiveStep(0)
        setServerMessage("")
        loadPrices()
        setOpen(true);
    };

    const handleClose = () => {
        onAbort()
        setOpen(false)
    }

    const handlePriceSelection = useCallback((price: StripePriceDTO) => {
        setSelectedPrice(price)
    }, [])

    const handleSubscriptionSelection = async () => {
        try {
            if (!selectedPrice) return
            setIsLoading(true)
            const clientSecret = await billingApi.billing().upgradeSubscription(couponCode)
            if(!clientSecret){
                window.location.href = getStripeRedirectUrl()+"?no_payment_necessary=true"
                return
            }else {
                setClientSecret(clientSecret)
            }
            setActiveStep(activeStep + 1)
        } catch (e) {
            setIsLoading(false)
            setServerMessage(toErrorMessage(e, "Bitte versuchen Sie es später noch einmal."))
        }
    }

    const handleSubmitClicked = () => {
        setIsSubmitting(true)
        const form = document.getElementById(paymentFormId)
        if (form) {
            form.dispatchEvent(new Event('submit', {
                cancelable: true,
                bubbles: true
            }))
        }
    }

    const onPaymentFormSubmitted = async (stripe: Stripe, elements: StripeElements) => {
        const result = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: getStripeRedirectUrl()
            },
        });

        if (result.error) {
            if (result.error.type === 'card_error' || result.error.code === 'validation_error') {
                setServerMessage(result.error.message)
            } else {
                setServerMessage("Beim Zahlvorgang ist ein Fehler aufgetreten. Bitte versuchen Sie es später erneut.")
            }
        }
        setIsSubmitting(false)
    }

    return (
        <>
            <Button color="secondary" variant="contained" fullWidth onClick={handleClickOpen}>Jetzt
                Verlängern</Button>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}
                    aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">{steps[activeStep]}</DialogTitle>
                <DialogContent style={{overflowY: "initial", minHeight: "410px"}}>
                    {!isError && activeStep === 0 && !isLoading &&
                        <ProductSelection prices={prices} onSelectPrice={handlePriceSelection}
                                          onSelectPromo={setCouponCode}/>}
                    {!isError && activeStep === 1 && clientSecret &&
                        <StripePaymentForm formId={paymentFormId}
                                           clientSecret={clientSecret.secret}
                                           onPaymentFormReady={() => setIsLoading(false)}
                                           onSubmit={onPaymentFormSubmitted}/>}
                    {isLoading && <ProgressIndicator/>}
                    <Box paddingTop={"15px"}>
                        <Typography color="error">{serverMessage}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined"
                            color="primary">{isError ? 'Schließen' : 'Abbrechen'}</Button>
                    {!isError && activeStep === 0 && <Button variant="contained" color="primary" disabled={isLoading}
                                                 onClick={handleSubscriptionSelection}>Weiter</Button>}
                    {!isError && activeStep === 1 &&
                        <Button type="button" variant="contained" color="primary" style={{minWidth: "125px"}}
                                disabled={isLoading || isSubmitting}
                                onClick={handleSubmitClicked}>{isSubmitting ? <CircularProgress size={25}/> : 'Verlängern'}</Button>}
                </DialogActions>
            </Dialog>
        </>
    )
}

interface ProductSelectionProps {
    prices: StripePriceDTO[]
    onSelectPrice: (price: StripePriceDTO) => void
    onSelectPromo: (couponCode: string) => void
}


const ProductSelection: React.FC<ProductSelectionProps> = ({
                                                               prices,
                                                               onSelectPrice,
                                                               onSelectPromo
                                                           }) => {

    const [promoCode, setPromoCode] = useState('')
    const [promo, setPromo] = useState<PromoDTO>(undefined)
    const [selectedPrice, setSelectedPrice] = useState<StripePriceDTO>(undefined)
    const [isLoading, setIsLoading] = useState(false)
    const [serverMessage, setServerMessage] = useState('')

    useEffect(() => {
        setServerMessage('')
    }, [])

    useEffect(() => {
        setSelectedPrice(prices[0])
        onSelectPrice(prices[0])
    }, [prices, onSelectPrice])

    const onRedeemPromoClicked = async () => {
        try {
            setPromo(undefined)
            onSelectPromo('')
            setIsLoading(true)
            setServerMessage('')
            const promo = await billingApi.billing().getPromo(promoCode, selectedPrice.priceId)
            setPromo(promo)
            setIsLoading(false)
            onSelectPromo(promo.couponCode)
        } catch (e) {
            setServerMessage(toErrorMessage(e))
            setIsLoading(false)
        }
    }

    const getInterval = (price?: StripePriceDTO) => {
        if (!price) return ""
        switch (price.interval) {
            case 'month': {
                if (price.intervalCount > 1) {
                    return `pro ${price.intervalCount} Monate`
                }
                return 'pro Monat'
            }
            // TODO support other intervals
        }
    }

    const formatPrice = (price: number, currency: string) => {
        return (price / 100).toLocaleString("de-DE", {
            style: 'currency',
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
            currency: currency
        })
    }

    return (
        <Grid container spacing={2}>
            <Grid item xs={12} sm={5}><Typography>Ausgewähltes Abo:</Typography></Grid>
            <Grid item xs={12} sm={7}>
                {prices.map((price) => {
                    return (
                        <Card>
                            <CardContent>
                                <Typography color="textSecondary" gutterBottom>
                                    {price.productName}
                                </Typography>
                                <Typography variant="h4">
                                    {formatPrice(price.unitAmount, price.currency)}
                                </Typography>
                                <Typography color="textSecondary">
                                    {getInterval(price)}
                                </Typography>
                            </CardContent>
                        </Card>
                    )
                })}
            </Grid>
            <Grid item xs={12} sm={5}><Typography>Rabattcode einlösen:</Typography></Grid>
            <Grid item xs={12} sm={7} container justifyContent="flex-end">
                <TextField label="Rabattcode" variant="outlined" size="small" fullWidth
                           value={promoCode} onChange={e => setPromoCode(e.target.value)}/>
                <Button color="secondary" disabled={!promoCode}
                        onClick={onRedeemPromoClicked}>Einlösen</Button>
            </Grid>
            <Grid item xs={12} sm={5} />
            <Grid item xs={12} sm={7}><Typography
                color="textSecondary">{serverMessage ? serverMessage :
                <span>&nbsp;</span>}</Typography></Grid>
            <TitledDivider title=""/>
            <Grid item xs={12}/>
            <Grid item xs={12} sm={5}><Typography>Zusammenfassung:</Typography></Grid>
            {selectedPrice && <Grid item xs={12} sm={7} container justifyContent="flex-end"
                                    style={{minHeight: "90px"}}>
                {!isLoading &&
                    <Table padding="none">
                        <TableBody>
                            <TableRow>
                                <TableCell style={{borderBottom: "none"}}><TableCellContent
                                    content={`Abo ${selectedPrice?.productName}`}/></TableCell>
                                <TableCell style={{borderBottom: "none"}}
                                           align="right"><TableCellContent
                                    content={formatPrice(promo ? promo.amountPrice : selectedPrice.unitAmount, selectedPrice.currency)}/></TableCell>
                            </TableRow>
                            {promo && <TableRow>
                                <TableCell><TableCellContent content="Rabattcode"
                                                             subContent={promo.promoCode}/></TableCell>
                                <TableCell align="right"><TableCellContent
                                    content={`- ${formatPrice(promo.amountOff, selectedPrice.currency)}`}/></TableCell>
                            </TableRow>}
                            {!promo &&
                                <TableRow><TableCell>&nbsp;</TableCell><TableCell>&nbsp;</TableCell></TableRow>}
                            <TableRow>
                                <TableCell style={{borderBottom: "none"}}><TableCellContent
                                    content="Gesamt"
                                    subContent={getInterval(selectedPrice)}
                                    bold/></TableCell>
                                <TableCell style={{borderBottom: "none"}}
                                           align="right"><TableCellContent
                                    content={formatPrice(promo ? promo.amountTotal : selectedPrice.unitAmount, selectedPrice.currency)}
                                    bold/></TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>}
                {isLoading &&
                    <Box height="100%" width="100%" display="flex" justifyContent="center">
                        <CircularProgress/>
                    </Box>}
            </Grid>}
        </Grid>
    )
}

interface TableCellContentProps {
    content: string
    subContent?: string
    bold?: boolean
}

const TableCellContent: React.FC<TableCellContentProps> = ({content, subContent, bold}) => {
    return (
        <Typography style={{fontWeight: bold ? "bold" : "normal"}}>{content}&nbsp;<Typography
            component="span" variant="body2"
            color="textSecondary">{subContent}</Typography></Typography>
    )
}
