import {Configuration} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/configuration";
import {RequestContext} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/http/http";
import {cupAuthService} from "../../auth/cupAuthService";
import {
    ConfigurationApiRequestFactory
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/ConfigurationApi";
import {
    ConfigurationDTO
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/ConfigurationDTO";
import {adminAuthService} from "../../auth/adminAuthService";


export class ConfigurationApiRequestEnhancer extends ConfigurationApiRequestFactory {
    public async getConfiguration(xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()

        const {token} = await adminAuthService().getAdminCredentials()
        return super.getConfiguration(xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    public async update(configurationDTO: ConfigurationDTO, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.update(configurationDTO, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options
        )

    }

}
