import React from 'react'
import {
  FormControl,
  FormHelperText,
  InputLabel,
  makeStyles,
  MenuItem,
  Select
} from '@material-ui/core'
import {Option} from "./option";


const useStyles = makeStyles((theme) => ({
  selectContainer: {
    display: 'inline-flex',
    alignItems: 'center'
  },
  icon: {
    marginRight: theme.spacing(2),
    marginTop: -5,
    height: '1em'
  }
}))


interface MaterialSelectProps {
  value: any
  onChange: (option: any) => void
  options: Option[]
  label?: string
  placeholder?: string
  required?: boolean
  noneOption?: boolean
  disabled?: boolean
  helperText?: string
  dense?: boolean

  [key: string]: any
}

export const MaterialSelect: React.FC<MaterialSelectProps> = ({
                                                                value,
                                                                onChange,
                                                                options,
                                                                label = '',
                                                                placeholder,
                                                                required = false,
                                                                noneOption = false,
                                                                disabled = false,
                                                                helperText,
                                                                variant = "outlined",
                                                                dense,
                                                                ...others
                                                              }) => {
  const classes = useStyles()
  return (
      <FormControl {...others} placeholder={placeholder} required={required} variant={variant}
                   margin={dense ? "dense" : "none"}>
        <InputLabel>{label}</InputLabel>
        <Select
            value={value}
            label={label}
            onChange={(e) => onChange(e.target.value)}
            inputProps={{className: classes.selectContainer}}
            disabled={disabled}
        >
          {noneOption && <MenuItem value={undefined}>{'auswählen'}</MenuItem>}
          {options.map((option) => (
              <MenuItem value={option.value} key={option.id}>
                {option.icon && <span className={classes.icon}>{option.icon}</span>}
                {option.label}
              </MenuItem>
          ))}
        </Select>
        <FormHelperText>{helperText || ' '}</FormHelperText>
      </FormControl>
  )
}
