import React from "react";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {SingleLineField} from "../../components/inputs/singleLineInput/SingleLineField";
import {TenantConfiguration} from "../../api/tenants/model";
import {SwitchField} from "../../components/inputs/switchField/SwitchField";
import {checkIfEmailExists, firebaseSignIn} from "../../config/firebase";
import {AuthErrorCodes} from "firebase/auth";
import {CheckboxField} from "../../components/inputs/checkbox/CheckboxField";


interface AdminStepperContentProps {
    adminEmailValidationMessage: string
    adminPasswordValidationMessage: string
    consentReceiveEmailValidationMessage: string
    tenantConfig: TenantConfiguration
}

export const CupWizardAdminStepperContent: React.FC<AdminStepperContentProps> = ({
                                                                                     adminEmailValidationMessage,
                                                                                     adminPasswordValidationMessage,
                                                                                     consentReceiveEmailValidationMessage,
                                                                                     tenantConfig
                                                                                 }) => {
    return (
        <Box marginTop={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Diese Email Adresse und das Passwort
                        werden
                        benötigt um Änderungen in
                        den Einstellungen vorzunehmen.</Typography>
                    <br/>
                </Grid>
                <Grid item xs={12}>
                    <SwitchField name="useExistingAccount" label="Bestehenden Account verwenden" size="small"/>
                </Grid>
                <Grid item xs={12}>
                    <SingleLineField name="adminEmail" label="Admin E-Mail" autoFocus type="text" required
                                     helperText={adminEmailValidationMessage}/>
                </Grid>
                <Grid item xs={12}>

                    <SingleLineField name="adminPassword" label="Admin Passwort" type="password" required
                                     helperText={(tenantConfig.useExistingAccount && adminPasswordValidationMessage) ? adminPasswordValidationMessage : 'Mindestens 6 Zeichen.'}
                                     style={{marginBottom: 8}}/>
                    {!tenantConfig.useExistingAccount &&
                    <SingleLineField name="adminPasswordRepeat" label="Wiederholen" type="password" required
                                     helperText={adminPasswordValidationMessage}/>}
                </Grid>
                <Grid item xs={12}>
                    <CheckboxField name="consentReceiveEmails" label={<Typography>Pyramiden Cup darf mir E-Mails im Zusammenhang mit meinem Cup senden. *</Typography>} />
                    <Typography color="textSecondary" style={{fontSize: "0.75rem"}}>{consentReceiveEmailValidationMessage}&nbsp;</Typography>
                </Grid>
            </Grid>
        </Box>
    )
}

export const validateAdminEmail = (tenantConfig: TenantConfiguration): string | null => {
    if (!tenantConfig.adminEmail) {
        return 'Bitte geben Sie eine E-Mail Adresse ein.'
    }
}

export const validateExistingAccount = (tenantConfig: TenantConfiguration): Promise<string | null> => {
    if (!tenantConfig.adminPassword) {
        return Promise.resolve('Bitte geben Sie ein Passwort ein.')
    }
    if (tenantConfig.adminPassword.length < 6) {
        return Promise.resolve('Das Passwort muss mindestens 6 Zeichen enthalten.')
    }
    return firebaseSignIn(tenantConfig.adminEmail, tenantConfig.adminPassword).then(user => {
        return null
    }).catch((e) => {
        switch (e.code) {
            case AuthErrorCodes.INVALID_EMAIL:
            case AuthErrorCodes.INVALID_PASSWORD:
            case AuthErrorCodes.USER_DELETED:
                return 'Die eingegebene E-Mail oder das Passwort ist falsch oder die E-Mail wurde nicht gefunden.'
            default:
                return 'Bei der Verwendung dieser E-Mail Adresse ist ein Fehler aufgetreten.'
        }
    })
}

export const validateNewAccount = (tenantConfig: TenantConfiguration): Promise<string | null> => {
    if (!tenantConfig.adminPassword) {
        return Promise.resolve('Bitte geben Sie ein Passwort ein.')
    } else if (tenantConfig.adminPassword.length < 6) {
        return Promise.resolve('Das Passwort muss mindestens 6 Zeichen enthalten.')
    } else if (tenantConfig.adminPassword !== tenantConfig.adminPasswordRepeat) {
        return Promise.resolve('Die Passwörter stimmen nicht überein.')
    }
    return checkIfEmailExists(tenantConfig.adminEmail).then(exists => {
        if (exists) {
            return Promise.resolve('Diese E-Mail Adresse wird bereits verwendet. Aktivieren Sie "Bestehenden Account verwenden".')
        } else {
            return null
        }
    }).catch(error => {
        return null
    })
}

export const validateConsentReceiveEmails = (tenantConfig: TenantConfiguration): string => {
    if(tenantConfig.consentReceiveEmails){
        return null
    }
    return "Bitte akzeptieren Sie dieses Feld."
}
