import React, {useEffect, useState} from 'react';
import AppBar from '@material-ui/core/AppBar';
import CssBaseline from '@material-ui/core/CssBaseline';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import MenuIcon from '@material-ui/icons/Menu';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import {createStyles, makeStyles, Theme, useTheme} from '@material-ui/core/styles';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Button from "@material-ui/core/Button";
import {Box, Grid} from '@material-ui/core';
import {Login} from "./login/Login";
import {TenantInfo} from "./tenantInfo/TenantInfo";
import {SettingsConfigurationComponent} from "./configuration/SettingsConfigurationComponent";
import {adminAuthService} from "../../auth/adminAuthService";
import {BillingInfo} from "./billing/BillingInfo";
import {useAuthState} from "react-firebase-hooks/auth";
import {getAuth} from "firebase/auth";
import {stripePromise} from "../../../App";
import {Elements} from '@stripe/react-stripe-js';
import {HeaderBanner} from "../../components/header/Header";
import {billingApi} from "../../api/BillingApi";
import {SubscriptionDTO} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/SubscriptionDTO";
import {navigateToCup} from "../../util/navigation";
import {useTenantState} from "../../api/tenants/useTenant";
import {SettingsPlayerComponent} from "./player/SettingsPlayer";
import {SettingsGamesComponent} from "./games/SettingsGamesComponent";


const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
      root: {
        display: 'flex',
      },
      drawer: {
        [theme.breakpoints.up('sm')]: {
          width: drawerWidth,
          flexShrink: 0,
        },
      },
      appBar: {
        [theme.breakpoints.up('sm')]: {
          width: `calc(100% - ${drawerWidth}px)`,
          marginLeft: drawerWidth,
        },
      },
      menuButton: {
        marginRight: theme.spacing(2),
        [theme.breakpoints.up('sm')]: {
          display: 'none',
        },
      },
      // necessary for content to be below app bar
      toolbar: theme.mixins.toolbar,
      drawerPaper: {
        width: drawerWidth,
      },
      content: {
        flexGrow: 1,
        padding: theme.spacing(3),
      },
      toolbarRightButtons: {
        marginLeft: 'auto',
      },
      listButton: {
        width: '100%',
        padding: theme.spacing(1),
        borderRadius: 0
      }
    }),
);


export const SettingsPage = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [user] = useAuthState(getAuth());
  const [mobileOpen, setMobileOpen] = useState(false)
  const [loggedIn, setLoggedIn] = useState<boolean>(false)
  const {tenant} = useTenantState();
  const [subscription, setSubscription] = useState<SubscriptionDTO>(undefined)
  const noSubscriptionWarningMessage = "Ihr Abo ist abgelaufen. Um weiter Zugriff zu erhalten können Sie es unter 'Abo' verlängern."

  useEffect(() => {
    if (user && tenant) {
      adminAuthService().checkLogin().then(() => {
        setLoggedIn(true)
        loadSubscription()
      }).catch(() => adminAuthService().logout().then())
    }
  }, [user, tenant])

  const loadSubscription = () => {
    billingApi.billing().getSubscription().then(subscription => {
      setSubscription(subscription)
    }).catch(e => {
      console.log("Failed loading subscriptions.", e)
    })
  }
  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };


  const onLoginSuccess = () => {
    setLoggedIn(true)
  }

  const logout = () => {
    try {
      adminAuthService().logout().then(e => console.log("Firebase logout failed", e))
      navigateToCup()
    } catch (e) {
      console.log("Logout failed", e)
    }
  }

  const drawer = (<div>
        <div className={classes.toolbar}/>
        <Divider/>
        {loggedIn && <>
          <List>
            <Button key="tenantInfo" variant="text" disableElevation href="#tenantInfo"
                    className={classes.listButton}>Allgemeines</Button>
            <Button key="player" variant="text" disableElevation href="#player"
                    className={classes.listButton}>Spieler</Button>
            <Button key="games" variant="text" disableElevation href="#games"
                    className={classes.listButton}>Spiele</Button>
            <Button key="settings" variant="text" disableElevation href="#settings"
                    className={classes.listButton}>Einstellungen</Button>
          </List>
          <Divider/>
          <List>
            <Button onClick={logout} variant="text" disableElevation startIcon={<ExitToAppIcon/>}
                    className={classes.listButton}>Logout</Button>
          </List>
        </>
        }
      </div>
  );

  const container = undefined

  return (

      <div className={classes.root}>
        <CssBaseline/>
        <AppBar position="fixed" className={classes.appBar}>
          <Toolbar>
            <Box display='flex' flexGrow={1}>
              {loggedIn && <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  edge="start"
                  onClick={handleDrawerToggle}
                  className={classes.menuButton}
              >
                <MenuIcon/>
              </IconButton>}
              <Box alignSelf="center"><Typography variant="h6" noWrap>
                Einstellungen
              </Typography></Box>

            </Box>
            <Button variant="outlined" color="inherit"
                    onClick={logout}>{loggedIn ? "Logout" : "Zurück"}</Button>
          </Toolbar>
          {loggedIn && !tenant.accessGranted &&
              <Box><HeaderBanner text={noSubscriptionWarningMessage}/></Box>}
        </AppBar>
        <nav className={classes.drawer} aria-label="mailbox folders">
          {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
                container={container}
                variant="temporary"
                anchor={theme.direction === 'rtl' ? 'right' : 'left'}
                open={mobileOpen}
                onClose={handleDrawerToggle}
                classes={{
                  paper: classes.drawerPaper,
                }}
                ModalProps={{
                  keepMounted: true, // Better open performance on mobile.
                }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
                classes={{
                  paper: classes.drawerPaper,
                }}
                variant="permanent"
                open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>

        <main className={classes.content}>
          <div className={classes.toolbar}/>

          {!loggedIn && <Login onLoginSuccess={onLoginSuccess}/>}

          {loggedIn && <>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <TenantInfo/>
              </Grid>
              <Grid item xs={12}>
                <Elements stripe={stripePromise}>
                  <BillingInfo subscription={subscription}
                               onUpdateSubscription={setSubscription}/>
                </Elements>
              </Grid>
              <Grid item xs={12}>
                <SettingsPlayerComponent tenant={tenant}/>
              </Grid>
              <Grid item xs={12}>
                <SettingsGamesComponent tenant={tenant}/>
              </Grid>
              <Grid item xs={12}>
                <section id="settings">
                  <SettingsConfigurationComponent/>
                </section>
              </Grid>
            </Grid>
          </>}
        </main>
      </div>

  );
}
