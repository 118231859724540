import React, {ReactElement, useEffect} from "react";
import {localStorageService} from "../../../../localstorage/LocalStorageService";
import {Md5} from "ts-md5";
import {Grid} from "@material-ui/core";
import {FormDialog} from "../../../../components/form/FormDialog";
import {useTenantState, useUpdateTenant} from "../../../../api/tenants/useTenant";
import {TextInput} from "../../../../components/form/inputs/TextInput";
import {useForm} from "react-hook-form";
import {
  TenantUpdateRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/TenantUpdateRequest";


export type EditCupPasswordFormType = Partial<TenantUpdateRequest>;

export const EditCupPasswordDialog = (): ReactElement => {
  const {tenant} = useTenantState();
  const useFormReturn = useForm<EditCupPasswordFormType>({
    defaultValues: {
      cupPassword: tenant.cupPassword
    }
  });
  const {reset} = useFormReturn;
  const mutation = useUpdateTenant();
  const {isSuccess, variables} = mutation;

  useEffect(() => {
    if (isSuccess) {
      const password = Md5.hashStr(variables.cupPassword);
      localStorageService().setLoginData({
        cupPassword: password,
        tenantName: tenant.name,
        tenantKey: tenant.tenantKey
      })
      localStorageService().setCupPassword(password)
      reset({cupPassword: variables.cupPassword})
    }
  }, [isSuccess, variables, reset, tenant])

  return (
      <FormDialog buttonText="Editieren" dialogTitel="Cup Passwort ändern" mutation={mutation}
                  useFormReturn={useFormReturn}
                  buttonProps={{disabled: !tenant.accessGranted, color: "secondary"}}>
        <Grid container>
          <Grid item xs={12}>
            <TextInput name="cupPassword" useFormReturn={useFormReturn} label="Cup Passwort"
                       required/>
          </Grid>
        </Grid>
      </FormDialog>
  )
}
