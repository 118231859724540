import {
    BillingStripeApiRequestFactory
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/BillingStripeApi";
import {Configuration} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/configuration";
import {RequestContext} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/http/http";
import {cupAuthService} from "../../auth/cupAuthService";
import {adminAuthService} from "../../auth/adminAuthService";

export class BillingApiRequestEnhancer extends BillingStripeApiRequestFactory {

    async getPrices(xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.getPrices(xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    async upgradeSubscription(couponCode?: string, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.upgradeSubscription(couponCode, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    async getSubscription(xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.getSubscription(xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    async getClientSecret(xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.getClientSecret(xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    async cancelSubscription(xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.cancelSubscription(xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    async reactivateSubscription(xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.reactivateSubscription(xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    async getPromo(promoCode: string, priceId: string, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.getPromo(promoCode, priceId, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

}
