import React from 'react'



import {MaterialDateTimePickerField} from "./MaterialDateTimePickerField";

interface TimeFieldProps extends NodeJS.Dict<any> {
  name: string
  label?: string
  required?: boolean
}

export const DateTimeField: React.FC<TimeFieldProps> = ({ name, label = '', required = false, ...other }) => {
  return (
    <MaterialDateTimePickerField
      name={name}
      label={label}
      variant="outlined"
      size="small"
      fullWidth
      {...other}
    />
  )
}
