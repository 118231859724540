import {TenantsApiRequestFactory} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/TenantsApi";
import {CreateTenantRequest} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreateTenantRequest";
import {Configuration} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/configuration";
import {RequestContext} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/http/http";
import {TenantLoginRequest} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/TenantLoginRequest";
import {cupAuthService} from "../../auth/cupAuthService";
import {AdminLoginRequest} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/AdminLoginRequest";
import {TenantUpdateRequest} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/TenantUpdateRequest";
import {adminAuthService} from "../../auth/adminAuthService";


export class TenantsApiRequestEnhancer extends TenantsApiRequestFactory {


    public create(createTenantRequest: CreateTenantRequest, _options?: Configuration): Promise<RequestContext> {
        return super.create(createTenantRequest, _options)
    }

    public exists(name: string, _options?: Configuration): Promise<RequestContext> {
        return super.exists(name, _options)
    }

    public get(xTenantKey: string, xCupPw?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        return super.get(xTenantKey || tenantKey, xCupPw || cupPassword, _options)
    }

    public login(tenantLoginRequest: TenantLoginRequest, _options?: Configuration): Promise<RequestContext> {
        return super.login(tenantLoginRequest, _options)
    }

    public loginAdmin(adminLoginRequest: AdminLoginRequest, xTenantKey?: string, xCupPw?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        return super.loginAdmin(adminLoginRequest, xTenantKey || tenantKey, xCupPw || cupPassword, _options)
    }

    public async update(tenantUpdateRequest: TenantUpdateRequest, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.update(tenantUpdateRequest, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }


}
