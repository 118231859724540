import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import {Transition} from "../../../../components/layout/DialogTransition";
import {billingApi} from "../../../../api/BillingApi";
import {SubscriptionDTO} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/SubscriptionDTO";
import {formatDate, formatTime} from "../../../../util/date";

interface CancelSubscriptionDialogProps {
    subscription: SubscriptionDTO
    onSuccess: (subscription: SubscriptionDTO) => void
}

export const CancelSubscriptionDialog: React.FC<CancelSubscriptionDialogProps> = ({subscription, onSuccess}) => {
    const [open, setOpen] = useState(false);
    const [serverMessage, setServerMessage] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState(false)


    const handleClickOpen = () => {
        setServerMessage("")
        setOpen(true);
        setIsSubmitting(false)
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmitClicked = async () => {
        setIsSubmitting(true)
        try {
            const subscription = await billingApi.billing().cancelSubscription()
            onSuccess(subscription)
            setIsSubmitting(false)
            setOpen(false)
        } catch (e) {
            setServerMessage(`Es ist ein Fehler aufgetreten. Bitte probieren Sie es später noch einmal oder wenden Sie sich an support@pyramidencup.at`)
            setIsSubmitting(false)
        }
    }

    return (
        <>
            <Button color="primary" variant="outlined"
                    fullWidth onClick={handleClickOpen}>Beenden</Button>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}
                    aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Abo beenden</DialogTitle>
                <DialogContent style={{overflowY: "initial"}}>
                    <Typography>Schade, dass Sie sich zu diesem Schritt entschlossen haben.</Typography>
                    <br />
                    <Typography>Durch <Typography component="span" color="primary" style={{fontWeight: 'bold'}}>"Beenden"</Typography> wird das Abo beendet. Ab dann wird Ihr Konto nicht mehr belastet.</Typography>
                    <br />
                    <Typography>Bis zum Ende der aktuellen Periode am {formatDate(subscription.accessGrantedUntil)} um {formatTime(subscription.accessGrantedUntil)} können Sie noch ganz normal auf den Cup zugreifen.</Typography>
                    <br />
                    <Typography>Außerdem haben Sie bis dahin noch die Möglichkeit das Abo wieder zu <Typography component="span" color="primary" style={{fontWeight: 'bold'}}>"reaktivieren"</Typography>. Dann wird die Kündigung rückgängig gemacht und das Abo läuft normal weiter.</Typography>
                    <Box paddingTop={"15px"}>
                        <Typography display="block" color="error">{serverMessage}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined"
                            color="primary">Abbrechen</Button>
                    <Button type="button" variant="contained" color="primary"
                            style={{minWidth: "125px"}}
                            disabled={isSubmitting}
                            onClick={handleSubmitClicked}>{isSubmitting ?
                        <CircularProgress size={25}/> : 'Beenden'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
