import {ApiError, ApiException, ResponseContext} from "@pyramid-cup/pyramid-cup-api-client-ts"
import {cupAuthService} from "../../auth/cupAuthService";


export const toErrorMessage = (error: ApiException<ApiError> | any, appendToDefaultMessage: string = ''): string => {
  return error?.body?.message || `Es ist ein Fehler aufgetreten. ${appendToDefaultMessage}`
}

export const handleAuthorization = (response: ResponseContext): boolean => {
  if (response.httpStatusCode === 401) {
    cupAuthService().logoutAndNavigateToStart()
    return true
  }
  return false
}
