import {Configuration} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/configuration";
import {RequestContext} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/http/http";
import {cupAuthService} from "../../auth/cupAuthService";
import {GamesApiRequestFactory} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/GamesApi";
import {
    CreateGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreateGameRequest";
import {
    FinishGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/FinishGameRequest";
import {adminAuthService} from "../../auth/adminAuthService";


export class GamesApiRequestEnhancer extends GamesApiRequestFactory {

  public async _delete(gameId: number, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
    const {tenantKey, cupPassword} = cupAuthService().getCredentials()
    const {token} = await adminAuthService().getAdminCredentials()
    return super._delete(gameId, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
  }

  public create(createGameRequest: CreateGameRequest, xTenantKey?: string, xCupPw?: string, _options?: Configuration): Promise<RequestContext> {
    const {tenantKey, cupPassword} = cupAuthService().getCredentials()
    return super.create(createGameRequest, xTenantKey || tenantKey, xCupPw || cupPassword, _options)
  }

  public finish(finishGameRequest: FinishGameRequest, xTenantKey?: string, xCupPw?: string, _options?: Configuration): Promise<RequestContext> {
    const {tenantKey, cupPassword} = cupAuthService().getCredentials()
    return super.finish(finishGameRequest, xTenantKey || tenantKey, xCupPw || cupPassword, _options)
  }

  public load(xTenantKey?: string, xCupPw?: string, open?: boolean, _options?: Configuration): Promise<RequestContext> {
    const {tenantKey, cupPassword} = cupAuthService().getCredentials()
    return super.load(xTenantKey || tenantKey, xCupPw || cupPassword, open, _options)
  }
}
