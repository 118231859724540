import {Configuration} from "./model";
import {
  ConfigurationApiResponseProcessor
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/ConfigurationApi";
import {ConfigurationDTO, ResponseContext} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {handleAuthorization} from "../error/error";


export class ConfigurationApiResponseConverter extends ConfigurationApiResponseProcessor {
  public async getConfiguration(response: ResponseContext): Promise<Configuration> {
    if (handleAuthorization(response)) return
    const config = await super.getConfiguration(response)
    return convertConfiguration(config);
  }

  public async update(response: ResponseContext): Promise<number> {
    if (handleAuthorization(response)) return
    return super.update(response)
  }

}

const convertConfiguration = (configurationDTO: ConfigurationDTO): Configuration => {
  return {
    ...configurationDTO
  }
}
