import {
    ClientSecretDTO,
    ResponseContext,
    StripePriceDTO
} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {handleAuthorization} from "../error/error";
import {
    BillingStripeApiResponseProcessor
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/BillingStripeApi";
import {SubscriptionDTO} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/SubscriptionDTO";
import {PromoDTO} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/PromoDTO";


export class BillingApiResponseConverter extends BillingStripeApiResponseProcessor {

    public async getPrices(response: ResponseContext): Promise<Array<StripePriceDTO>> {
        if (handleAuthorization(response)) return
        return super.getPrices(response)
    }

    public async upgradeSubscription(response: ResponseContext): Promise<ClientSecretDTO | void> {
        if (handleAuthorization(response)) return
        if(response.httpStatusCode === 204) return undefined
        return super.upgradeSubscription(response)
    }

    public async getSubscription(response: ResponseContext): Promise<SubscriptionDTO> {
        if (handleAuthorization(response)) return
        return super.getSubscription(response)
    }

    public async getClientSecret(response: ResponseContext): Promise<ClientSecretDTO> {
        if (handleAuthorization(response)) return
        return super.getClientSecret(response)
    }

    public async cancelSubscription(response: ResponseContext): Promise<SubscriptionDTO> {
        if (handleAuthorization(response)) return
        return super.cancelSubscription(response)
    }

    public async reactivateSubscription(response: ResponseContext): Promise<SubscriptionDTO> {
        if (handleAuthorization(response)) return
        return super.reactivateSubscription(response)
    }

    public async getPromo(response: ResponseContext): Promise<PromoDTO> {
        if (handleAuthorization(response)) return
        return super.getPromo(response)
    }
}
