import {Tenant} from "./model";
import {LoadingStateType, SetLoadingStateType} from "../LoadingStateType";
import create from "zustand";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";
import {ApiError, ApiException} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {pyramidCupApi} from "../PyramidCupApi";
import {useEffect} from "react";
import {
  TenantUpdateRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/TenantUpdateRequest";


export const TENANT_KEY = "tenant"

const useTenant = (): UseQueryResult<Tenant | undefined, ApiException<ApiError>> => {
  return useQuery<Tenant, ApiException<ApiError>, Tenant>(TENANT_KEY, () => fetchTenant(), {})
}

const fetchTenant = async (): Promise<Tenant> => {
  return await pyramidCupApi.tenants().get();
}

const updateTenant = async (request: TenantUpdateRequest): Promise<void> => {
  return await pyramidCupApi.tenants().update(request)
}

export const useUpdateTenant = (): UseMutationResult<void, ApiException<ApiError>, TenantUpdateRequest> => {
  const queryClient = useQueryClient();
  return useMutation(TENANT_KEY, updateTenant, {
    onSuccess: () => {
      return queryClient.invalidateQueries(TENANT_KEY);
    },
  })
}

type TenantStoreStateType = LoadingStateType & SetLoadingStateType & {
  tenant: Tenant;
  setTenant: (newTenant: Tenant) => void;
  cleanup: () => void;
}

const tenantState = create<TenantStoreStateType>((set) => ({
  tenant: undefined,
  setTenant: (newTenant: Tenant) => set(() => ({tenant: newTenant})),
  cleanup: () => set(() => ({tenant: undefined})),
  isLoading: false,
  isSuccess: false,
  isError: false,
  error: undefined,
  setLoadingState: (isLoading, isError, isSuccess, error) => set(() => ({
    isLoading,
    isError,
    isSuccess,
    error
  }))
}))

type TenantStateType = LoadingStateType & {
  tenant: Tenant
}

export const useTenantState = (): TenantStateType => {
  const {tenant, setTenant, cleanup, setLoadingState} = tenantState();
  const {data: loadedTenant, isLoading, isError, isSuccess, error} = useTenant();

  useEffect(() => {
    setLoadingState(isLoading, isError, isSuccess, error)
    setTenant(loadedTenant)
    return () => cleanup();
  }, [loadedTenant, isSuccess, isError, isLoading, error, cleanup, setTenant, setLoadingState])
  return {tenant, isSuccess, isError, isLoading, error}
}
