import React from "react";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {TenantConfiguration} from "../../api/tenants/model";
import {DateTimeField} from "../../components/inputs/dateTimePicker/DateTimeField";
import {isValid} from "date-fns";


interface StartStepperContentProps {
    validationMessage: string
}

export const CupWizardStartStepperContent: React.FC<StartStepperContentProps> = ({validationMessage}) => {
    return (
        <Box marginTop={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Ab welchem Zeitpunkt darf gefordert werden?</Typography>
                </Grid>
                <Grid item xs={12}>
                    <DateTimeField name="startTimestamp" label="Start des Cups *" helperText={validationMessage} required />
                </Grid>
            </Grid>
        </Box>
    )
}

export const validateStart = (tenantConfig: TenantConfiguration): string => {
    if (!tenantConfig.startTimestamp) {
        return 'Bitte geben Sie einen Startzeitpunkt ein.'
    }
    if(!isValid(tenantConfig.startTimestamp)){
        return `Bitte geben Sie einen korrekten Startzeitpunkt ein. Beispiel:${String.fromCharCode(160)}'24.12.${new Date().getFullYear()}${String.fromCharCode(160)}14:30'`
    }
}
