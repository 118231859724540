import {
  PublicApiResponseProcessor
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/PublicApi";
import {PublicTenantResponse, ResponseContext} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {convertPlayerDTOs} from "../player/PlayersApiResponseConverter";
import {PublicTenantInfo} from "./model";
import {convertGames} from "../games/GamesApiResponseConverter";

export class PublicApiResponseConverter extends PublicApiResponseProcessor {
  public async getPublicTenantInfo(response: ResponseContext): Promise<PublicTenantInfo> {
    return super.getPublicTenantInfo(response).then(convertPublicTenantInfoResponse) //.catch(() => [])// TODO handle when called
  }
}

const convertPublicTenantInfoResponse = (response: PublicTenantResponse): PublicTenantInfo => {
  return {
    players: convertPlayerDTOs(response.players),
    tenantName: response.tenantName,
    openGames: convertGames(response.openGames),
    results: convertGames(response.results)
  }
}
