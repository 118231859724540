import React, {useEffect, useState} from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import {useTheme} from "@material-ui/core/styles";
import {DialogProps} from "../../../components/button/buttonProps";
import {Player} from "../../../api/player/model";
import {GameDTO, TenantDTOStatusEnum} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {useForm} from "react-hook-form";
import {toOption} from "../../../components/inputs/singleSelect/option";
import {millisToString} from "../../../util/duration";
import TrendingUpIcon from "@material-ui/icons/TrendingUp";
import {FormDialog} from "../../../components/form/FormDialog";
import {SelectInput} from "../../../components/form/inputs/SelectInput";
import {usePlayersState} from "../../../api/player/usePlayers";
import {useCreateGame} from "../../../api/games/useGames";
import {
  CreateGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreateGameRequest";

interface ChallengeDialogProps extends DialogProps {
  tenantStatus: TenantDTOStatusEnum
  admin?: boolean
  disabled?: boolean
}

export const ChallengeDialog: React.FC<ChallengeDialogProps> = ({
                                                                  tenantStatus,
                                                                  admin = false,
                                                                  buttonTitle,
                                                                  buttonVariant,
                                                                  buttonColor,
                                                                  dialogTitle,
                                                                  disabled
                                                                }) => {
  const {players} = usePlayersState();
  const useFormReturn = useForm<CreateGameRequest>();
  const {watch, setValue} = useFormReturn;
  const mutation = useCreateGame();
  const selectedChallengerId = watch("challengerId")

  const [possibleChallengers, setPossibleChallengers] = useState<Player[]>([]);
  const [possibleDefenders, setPossibleDefenders] = useState<Player[]>([]);
  const [validationMessage, setValidationMessage] = useState('')

  const theme = useTheme()


  useEffect(() => {
    if (!players || players.length < 2) return
    let defaultChallengerId;
    if (admin) {
      const challengers = players.filter(p => !p.opponent)
      setPossibleChallengers(challengers)
      if (challengers.length > 0) {
        defaultChallengerId = challengers[challengers.length - 1].id
      }
    } else {
      setPossibleChallengers(players)
      defaultChallengerId = players[0].id
      const challengers = players.filter(c => c.challengeablePlayers.length > 0)
      if (challengers.length > 0) {
        defaultChallengerId = challengers[0].id
      }
    }
    setValue("challengerId", defaultChallengerId || null);
  }, [players, admin, setValue]);

  useEffect(() => {
    if (!players) return;
    const selectedChallenger = players.find(p => p.id === selectedChallengerId);
    if (!selectedChallenger) return;
    let defenders
    if (admin) {
      defenders = players
      .filter(p => p.id !== selectedChallengerId)
      .filter(p => !p.opponent)
      .filter(p => p.ranking < selectedChallenger.ranking)
    } else {
      defenders = players.filter(p => selectedChallenger.challengeablePlayers.map((p: Player) => p.id).includes(p.id))
    }
    if (defenders.length > 0) {
      setPossibleDefenders(defenders)
      setValue("defenderId", defenders[0].id)
    } else {
      setPossibleDefenders([])
      setValue("defenderId", undefined)
    }
  }, [selectedChallengerId, players, setValue, admin])


  useEffect(() => {
    if (!players) return;
    const selectedChallenger = players.find(p => p.id === selectedChallengerId);
    if (!selectedChallenger) return;

    if (admin) {
      setValidationMessage('')
      return
    }
    if (selectedChallenger.opponent) {
      setValidationMessage(`${selectedChallenger.name} hat bereits ein Spiel gegen ${selectedChallenger.opponent.name}.`)
      return
    }
    if (selectedChallenger.inactive) {
      setValidationMessage(`${selectedChallenger.name} ist inaktiv.`)
      return
    }
    if (selectedChallenger.challengerFreezeDuration > 0) {
      setValidationMessage(`${selectedChallenger.name} ist noch für ${millisToString(selectedChallenger.challengerFreezeDuration)} gesperrt.`)
      return
    }
    if (possibleDefenders.length === 0) {
      setValidationMessage("Keine möglichen Gegner gefunden.")
      return
    }
    return setValidationMessage("")
  }, [selectedChallengerId, possibleDefenders, admin, players])

  const toSelectionOption = (player: Player) => {
    return toOption(player.id, `${player.name} (${player.ranking})`, player.id)
  }

  return (
      <FormDialog<CreateGameRequest, GameDTO>
          mutation={mutation}
          useFormReturn={useFormReturn} buttonText={buttonTitle} dialogTitel={dialogTitle}
          buttonProps={{
            variant: buttonVariant,
            color: buttonColor,
            startIcon: !admin && <TrendingUpIcon/>,
            disabled: !players || players.length === 0 || tenantStatus !== "STARTED" || disabled
          }}
          submitDisabled={!!validationMessage}
      >

        {admin && <Box style={{marginBottom: theme.spacing(3)}}>
          <Typography variant="caption">Sämtliche Forderungsregeln sind außer
            Kraft. <br/> Lediglich
            Spieler die bereits eine offene Forderung haben, sind ausgenommen.</Typography>
        </Box>}


        <Grid container>
          <Grid item xs={12}>
            <SelectInput name="challengerId" useFormReturn={useFormReturn}
                         options={possibleChallengers.map(toSelectionOption)} label="Forderer"
                         fullWidth/>
          </Grid>
          <Grid item xs={12} container justifyContent='center'>
            <Typography style={{paddingBottom: 16}}>fordert</Typography>
          </Grid>
          <Grid item xs={12}>
            <SelectInput name="defenderId" useFormReturn={useFormReturn}
                         options={possibleDefenders.map(toSelectionOption)} label="Verteidiger"
                         fullWidth
                         disabled={!possibleDefenders || possibleDefenders.length === 0}/>
          </Grid>
          <Box display="flex" flexGrow={1} color={theme.palette.error.main} justifyContent="center">
            <Typography variant="caption">
              {validationMessage ? validationMessage : <span>&nbsp;</span>}
            </Typography>
          </Box>
        </Grid>
      </FormDialog>
  )
}
