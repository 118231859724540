import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import SettingsIcon from '@material-ui/icons/Settings';

import {ResponsiveButton} from "../layout/ResponsiveButton";
import {localStorageService} from "../../localstorage/LocalStorageService";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import {cupAuthService} from "../../auth/cupAuthService";
import {Box, Paper} from "@material-ui/core";
import {navigateToLogin, navigateToSettings} from "../../util/navigation";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

interface HeaderProps {
  title: string
  subtitle?: string
  headerBanner?: HeaderBannerProps
  readonly?: boolean
}

export const Header: React.FC<HeaderProps> = ({title, subtitle, headerBanner, readonly}) => {
  const classes = useStyles();
  const tenantKey = localStorageService().getTenantKey()
  return (
      <>
        <div className={classes.root}>
          <AppBar position="static">
            <Toolbar>
              <Typography variant="h6" className={classes.title}>
                {title}&nbsp;
                {subtitle && <Typography component="span"
                                         variant={"body1"}>({subtitle})</Typography>}
              </Typography>

              {tenantKey && !window.location.href.endsWith("/new") &&
                  <ResponsiveButton text="Cup Suche" style={{marginRight: 10}}
                                    onClick={() => {
                                      cupAuthService().logoutAndNavigateToStart()
                                    }}
                                    icon={<ArrowBackIcon/>}
                                    displayIconOnDesktop={true}/>}

              {tenantKey && !window.location.href.endsWith("/new") && !readonly &&
                  <ResponsiveButton text="Einstellungen"
                                    onClick={() => {
                                      navigateToSettings()
                                    }}
                                    icon={<SettingsIcon/>}
                                    displayIconOnDesktop={true}/>}

              {window.location.href.endsWith("/new") && !readonly && <ResponsiveButton text="Zurück"
                                                                                       onClick={() => {
                                                                                         navigateToLogin()
                                                                                       }}
                                                                                       icon={
                                                                                         <ArrowBackIcon/>}
                                                                                       displayIconOnDesktop={true}/>}
            </Toolbar>
          </AppBar>
        </div>
        {headerBanner && <HeaderBanner {...headerBanner}/>}</>
  );
}

interface HeaderBannerProps {
  text: string
  bgColor?: string
  textColor?: string
}

export const HeaderBanner: React.FC<HeaderBannerProps> = ({text, bgColor, textColor}) => {
  return (
      <>{text && <Paper style={{
        width: "100%",
        minHeight: "50px",
        position: "absolute",
        background: bgColor || "orange",
        color: textColor || "white",
        justifyContent: "space-between",
        zIndex: 10
      }}>
        <Box height="100%" display="flex" justifyContent="center" alignItems="center"
             padding="8px" textAlign="center">
          <Typography variant="h6">{text}</Typography>
        </Box>
      </Paper>}</>
  )
}
