import React, {useState} from "react";
import {
    Box,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Typography
} from "@material-ui/core";
import {Transition} from "../../../../components/layout/DialogTransition";
import {billingApi} from "../../../../api/BillingApi";
import {SubscriptionDTO} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/SubscriptionDTO";

interface ReactivateSubscriptionDialogProps {
    onSuccess: (subscription: SubscriptionDTO) => void
}

export const ReactivateSubscriptionDialog: React.FC<ReactivateSubscriptionDialogProps> = ({ onSuccess}) => {
    const [open, setOpen] = useState(false);
    const [serverMessage, setServerMessage] = useState<string>('')
    const [isSubmitting, setIsSubmitting] = useState(false)


    const handleClickOpen = () => {
        setServerMessage("")
        setOpen(true);
        setIsSubmitting(false)
    };

    const handleClose = () => {
        setOpen(false)
    }

    const handleSubmitClicked = async () => {
        setIsSubmitting(true)
        try {
            const subscription = await billingApi.billing().reactivateSubscription()
            onSuccess(subscription)
            setIsSubmitting(false)
            setOpen(false)
        } catch (e) {
            setServerMessage(`Es ist ein Fehler aufgetreten. Bitte probieren Sie es später noch einmal oder wenden Sie sich an support@pyramidencup.at`)
            setIsSubmitting(false)
        }
    }

    return (
        <>
            <Button color="secondary" variant="contained"
                    fullWidth onClick={handleClickOpen}>Reaktivieren</Button>
            <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}
                    aria-labelledby="form-dialog-title" fullWidth>
                <DialogTitle id="form-dialog-title">Reaktivieren</DialogTitle>
                <DialogContent style={{overflowY: "initial"}}>
                    <Typography>Durch <Typography component="span" color="primary" style={{fontWeight: 'bold'}}>"Reaktivieren"</Typography> wird das Abo fortgesetzt und läuft ab dann wie gehabt weiter.</Typography>
                    <Box paddingTop={"15px"}>
                        <Typography display="block" color="error">{serverMessage}</Typography>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} variant="outlined"
                            color="primary">Abbrechen</Button>
                    <Button type="button" variant="contained" color="primary"
                            style={{minWidth: "132px"}}
                            disabled={isSubmitting}
                            onClick={handleSubmitClicked}>{isSubmitting ?
                        <CircularProgress size={25}/> : 'Reaktivieren'}</Button>
                </DialogActions>
            </Dialog>
        </>
    )
}
