import React from "react";
import {Header} from "../header/Header";
import {Box} from "@material-ui/core";

interface PageWrapperProps {
    title: string
    subtitle?: string
    children: any
    bannerText?: string
}

export const PageWrapper: React.FC<PageWrapperProps> = ({
                                                          title,
                                                          subtitle,
                                                          children,
                                                          bannerText
                                                        }) => {

  return (
      <Box>
        <Box marginBottom={2}>
          <Header title={title} subtitle={subtitle} headerBanner={{text: bannerText}}/>
        </Box>
        <Box margin={3}>{children}</Box>
      </Box>
  )


}


