import React, {ReactElement, useEffect} from "react";
import {ButtonProps} from "@material-ui/core";
import {PlayerLite} from "../../../api/player/model";
import {FormDialog} from "../../../components/form/FormDialog";
import {useCreatePlayer, useUpdatePlayer} from "../../../api/player/usePlayers";
import {useForm} from "react-hook-form";
import {SettingsPlayerForm} from "./form/SettingsPlayerForm";
import {
  CreatePlayerRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreatePlayerRequest";
import {
  UpdatePlayerRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/UpdatePlayerRequest";

interface SettingsPlayerDialogProps {
  initialPlayer?: PlayerLite
  disabled?: boolean;
}

const determineDefaultValues = (initialPlayer: PlayerLite): CreatePlayerRequest | UpdatePlayerRequest => {
  if (initialPlayer) {
    return initialPlayer;
  }
  return {name: ""}
}

export const SettingsPlayerDialog = ({
                                       initialPlayer,
                                       disabled
                                     }: SettingsPlayerDialogProps): ReactElement => {
  const createMutation = useCreatePlayer();
  const updateMutation = useUpdatePlayer();


  const useFormReturn = useForm<UpdatePlayerRequest | CreatePlayerRequest, PlayerLite>({
        mode: "onBlur",
        defaultValues: {...determineDefaultValues(initialPlayer)}
      }
  );

  useEffect(() => {
    useFormReturn.reset({...determineDefaultValues(initialPlayer)})
  }, [initialPlayer, useFormReturn])

  const createButtonProps: ButtonProps = {
    variant: "outlined",
    color: "primary",
    disabled: disabled,
  }

  const updateButtonProps: ButtonProps = {
    variant: "text",
    color: "secondary",
    disabled: disabled,
  }

  return (
      <FormDialog buttonText={initialPlayer ? "Editieren" : "Neuer Spieler"}
                  buttonProps={initialPlayer ? updateButtonProps : createButtonProps}
                  dialogTitel={initialPlayer ? "Spieler editieren" : "Spieler hinzufügen"}
                  mutation={initialPlayer ? updateMutation : createMutation}
                  useFormReturn={useFormReturn}>
        <SettingsPlayerForm useFormReturn={useFormReturn}/>
      </FormDialog>
  );
};
