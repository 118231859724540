import {useQuery, UseQueryResult} from "react-query";
import {ApiError, ApiException} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {pyramidCupApi} from "../PyramidCupApi";
import {PublicTenantInfo} from "./model";

export const PUBLIC_ALL_PLAYERS_KEY = "public/players"

export const usePublicTenantInfo = (key: string): UseQueryResult<PublicTenantInfo | undefined, ApiException<ApiError>> => {
  return useQuery([PUBLIC_ALL_PLAYERS_KEY, key], () => fetchPublicTenantInfo(key))
}

const fetchPublicTenantInfo = async (key: string): Promise<PublicTenantInfo> => {
  console.log("fetch info")
  return await pyramidCupApi.public().getPublicTenantInfo(key)
}
