import React, {ReactElement, useEffect} from "react";
import {Grid, Typography} from "@material-ui/core";
import {PlayerLite} from "../../../../api/player/model";
import {FormDialog} from "../../../../components/form/FormDialog";
import {useDeletePlayer} from "../../../../api/player/usePlayers";
import {useForm, UseFormReturn} from "react-hook-form";
import {TextInput} from "../../../../components/form/inputs/TextInput";


export type DeletePlayerFormModel = {
  typedPlayerName: string
  playerId: number
}

type DeletePlayerConfirmDialog1Props = {
  selectedPlayer: PlayerLite
}

export const DeletePlayerConfirmDialog = ({selectedPlayer}: DeletePlayerConfirmDialog1Props): ReactElement => {
  const mutation = useDeletePlayer()
  const useFormReturn = useForm<DeletePlayerFormModel>({
    defaultValues: {
      typedPlayerName: "",
    }
  });
  const typedPlayerName = useFormReturn.watch("typedPlayerName")
  return (
      <FormDialog buttonText={"Spieler löschen"}
                  dialogTitel={"Spieler löschen"}
                  buttonProps={{color: "secondary"}}
                  mutation={mutation}
                  useFormReturn={useFormReturn}
                  submitDisabled={typedPlayerName !== selectedPlayer.name}>
        <DeletePlayerConfirmForm selectedPlayer={selectedPlayer} useFormReturn={useFormReturn}/>
      </FormDialog>
  );
};


type DeletePlayerConfirmFormProps = {
  selectedPlayer: PlayerLite
  useFormReturn: UseFormReturn<DeletePlayerFormModel>;
}

const DeletePlayerConfirmForm = ({
                                   selectedPlayer,
                                   useFormReturn
                                 }: DeletePlayerConfirmFormProps): ReactElement => {
  useEffect(() => {
    useFormReturn.setValue("playerId", selectedPlayer.id)
  }, [selectedPlayer, useFormReturn])
  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle2">Soll der Spieler wirklich gelöscht
            werden?</Typography>
          <br/>
          <Typography variant="subtitle2">Diese Aktion kann nicht mehr rückgängig
            gemacht
            werden. Der
            Spieler wird nicht
            mehr in der Ranglisten angezeigt und kann nicht mehr an Spielen
            teilnehmen.</Typography>
          <br/>
          <Typography variant="subtitle2">Bitte geben Sie zur
            Bestätigung <Typography
                variant="subtitle2" component="span" color="secondary"
                style={{fontWeight: "bold"}}>{selectedPlayer.name}</Typography> in
            das Textfeld
            ein.</Typography>
        </Grid>
        <Grid item xs={12}>
          <TextInput name="typedPlayerName" useFormReturn={useFormReturn} label="Eingabe"
                     size={"small"}
                     variant="outlined"
                     required
                     autoFocus
          />
        </Grid>
      </Grid>
  );
};
