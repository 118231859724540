import React, {useState} from 'react';
import {makeStyles} from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import {Backdrop, Box, Card, CardContent, CircularProgress, Grid, Link} from "@material-ui/core";

import {Form} from '../../components/form/Form';
import {createEmptyTenantConfig, TenantConfiguration} from "../../api/tenants/model";
import {pyramidCupApi} from "../../api/PyramidCupApi";
import {firebaseGetCurrentUser} from "../../config/firebase";
import {CupWizardNameStepperContent, validateCupName} from "./CupWizardNameStepperContent";
import {
    CupWizardPasswordStepperContent,
    validateCupPassword
} from "./CupWizardPasswordStepperContent";
import {
    CupWizardAdminStepperContent,
    validateAdminEmail,
    validateConsentReceiveEmails,
    validateExistingAccount,
    validateNewAccount
} from "./CupWizardAdminStepperContent";
import {CupWizardSummaryStepperContent} from "./CupWizardSummaryStepperContent";
import {TenantDTO} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {toErrorMessage} from "../../api/error/error";
import {CupWizardStartStepperContent, validateStart} from "./CupWizardStartStepperContent";
import {navigateToNewCup} from "../../util/navigation";

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
        minWidth: "92px",
        minHeight: "37px"
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
        position: 'absolute'
    }
}));

const getSteps = () => {
    return ['Name des Cups eingeben', 'Passwort definieren', 'Startzeitpunkt festlegen', 'Admin erstellen', 'Fertig'];
}


export const CreateCupWizard = () => {

    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    const [initialTenantConfig] = useState<TenantConfiguration>(createEmptyTenantConfig())
    const steps = getSteps();
    const [isLoading, setIsLoading] = useState(false)
    const [isStepLoading, setIsStepLoading] = useState(false)
    const [responseMessage, setResponseMessage] = useState(' ')
    const [nameValidationMessage, setNameValidationMessage] = useState(' ')
    const [passwordValidationMessage, setPasswordValidationMessage] = useState(' ')
    const [startValidationMessage, setStartValidationMessage] = useState(' ')
    const [adminPasswordValidationMessage, setAdminPasswordValidationMessage] = useState(' ')
    const [adminEmailValidationMessage, setAdminEmailValidationMessage] = useState(' ')
    const [consentReceiveEmailValidationMessage, setConsentReceiveEmailValidationMessage] = useState(' ')


    const getStepContent = (step, values) => {
        switch (step) {
            case 0:
                return <CupWizardNameStepperContent validationMessage={nameValidationMessage}/>
            case 1:
                return <CupWizardPasswordStepperContent validationMessage={passwordValidationMessage}/>
            case 2:
                return <CupWizardStartStepperContent validationMessage={startValidationMessage}/>
            case 3:
                return <CupWizardAdminStepperContent adminEmailValidationMessage={adminEmailValidationMessage}
                                                     adminPasswordValidationMessage={adminPasswordValidationMessage}
                                                     consentReceiveEmailValidationMessage={consentReceiveEmailValidationMessage}
                                                     tenantConfig={values}/>
            case 4:
                return <CupWizardSummaryStepperContent tenantConfig={values}/>
            default:
                return 'Unknown step';
        }
    }

    const advanceStep = () => setActiveStep((prevActiveStep) => prevActiveStep + 1)

    const handleNameStep = async (tenantConfig: TenantConfiguration) => {
        const validationError = await validateCupName(tenantConfig)
        if (!validationError) {
            advanceStep()
        } else {
            setNameValidationMessage(validationError)
        }
    }

    const handlePasswordStep = (tenantConfig: TenantConfiguration) => {
        const validationError = validateCupPassword(tenantConfig)
        if (!validationError) {
            advanceStep()
        } else {
            setPasswordValidationMessage(validationError)
        }
    }

    const handleStartStep = (tenantConfig: TenantConfiguration) => {
        const validationError = validateStart(tenantConfig)
        if (!validationError) {
            advanceStep()
        } else {
            setStartValidationMessage(validationError)
        }
    }

    const handleAdminStep = async (tenantConfig: TenantConfiguration) => {
        setIsStepLoading(true)
        const adminEmailValidation = validateAdminEmail(tenantConfig)
        if (adminEmailValidation) {
            setAdminEmailValidationMessage(adminEmailValidation)
            return
        }
        if (tenantConfig.useExistingAccount) {
            const validationError = await validateExistingAccount(tenantConfig)
            setAdminPasswordValidationMessage(validationError || ' ')
            setIsStepLoading(false)
            if (validationError) return
        } else {
            const validationError = await validateNewAccount(tenantConfig)
            setAdminPasswordValidationMessage(validationError || ' ')
            setIsStepLoading(false)
            if (validationError) return
        }
        const validationError = validateConsentReceiveEmails(tenantConfig)
        if (validationError) {
            setConsentReceiveEmailValidationMessage(validationError || ' ')
            return
        }
        advanceStep()
    }

    const resetValidationMessages = () => {
        setResponseMessage('')
        setAdminEmailValidationMessage(' ')
        setAdminPasswordValidationMessage(' ')
        setNameValidationMessage(' ')
        setPasswordValidationMessage(' ')
        setStartValidationMessage(' ')
        setConsentReceiveEmailValidationMessage(' ')
    }

    const handleNext = async (tenantConfiguration: TenantConfiguration) => {
        resetValidationMessages()
        switch (activeStep) {
            case 0:
                await handleNameStep(tenantConfiguration)
                break
            case 1:
                handlePasswordStep(tenantConfiguration)
                break
            case 2:
                handleStartStep(tenantConfiguration)
                break
            case 3:
                await handleAdminStep(tenantConfiguration)
        }
    };

    const handleBack = () => {
        setResponseMessage('')
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const createTenant = async (tenantConfiguration: TenantConfiguration): Promise<TenantDTO> => {
        try {
            return await pyramidCupApi.tenants().create({
                name: tenantConfiguration.name.trim(),
                cupPassword: tenantConfiguration.cupPassword,
                adminPassword: tenantConfiguration.adminPassword,
                adminEmail: tenantConfiguration.adminEmail,
                adminUid: tenantConfiguration.useExistingAccount ? firebaseGetCurrentUser().uid : '',
                startTimestamp: tenantConfiguration.startTimestamp
            })
        } catch (e) {
            setResponseMessage(toErrorMessage(e))
            setIsLoading(false)
            return null
        }
    }

    const onSubmit = async (tenantConfiguration: TenantConfiguration) => {
        resetValidationMessages()
        setIsLoading(true)
        const tenant = await createTenant(tenantConfiguration)
        setIsLoading(false)
        if (tenant) {
            navigateToNewCup(tenant.tenantKey, tenant.name)
        }

    }

    return (
        <Grid container justifyContent="center">
            <Grid item xs={12} sm={9} md={6} lg={6}>
                <Card style={{position: "relative"}}>
                    <Backdrop className={classes.backdrop} open={isLoading}>
                        <CircularProgress color="inherit"/>
                    </Backdrop>
                    <CardContent>
                        <Typography variant="h5">In {steps.length - 1} Schritten ist Ihr Cup erstellt...</Typography>
                        <Form onSubmit={onSubmit}
                              initialValues={initialTenantConfig}
                              render={({handleSubmit, values}) => {
                                  return (
                                      <form id="createTenantForm" onSubmit={handleSubmit} noValidate>
                                          <div className={classes.root}>
                                              <Stepper activeStep={activeStep} orientation="vertical">
                                                  {steps.map((label, index) => (
                                                      <Step key={label}>
                                                          <StepLabel><Typography
                                                              variant="overline">{label}</Typography></StepLabel>
                                                          <StepContent>
                                                              <Grid container>
                                                                  <Grid item xs={12} sm={10} md={9} lg={8}>
                                                                      {getStepContent(index, values)}
                                                                  </Grid>
                                                                  <Grid item xs={12} container
                                                                        justifyContent="flex-end">
                                                                      <ActionButtonBar activeStep={activeStep}
                                                                                       maxSteps={steps.length}
                                                                                       onBack={handleBack}
                                                                                       onNext={handleNext}
                                                                                       onSubmit={onSubmit}
                                                                                       tenantConfig={values} disabled={isStepLoading}/>
                                                                  </Grid>
                                                                  <Grid item xs={12}>
                                                                      <Typography
                                                                          variant="subtitle1" color="error">{responseMessage}&nbsp;</Typography>
                                                                  </Grid>
                                                              </Grid>
                                                          </StepContent>
                                                      </Step>
                                                  ))}
                                              </Stepper>
                                          </div>
                                      </form>
                                  )
                              }}/>
                    </CardContent>
                </Card>
            </Grid>
            <Grid item xs={12} container justifyContent="center" alignContent="center" alignItems="center" style={{paddingTop: 8}}><Typography
                variant="body2" color="textSecondary">Sie benötigen Hilfe?&nbsp;</Typography><Link variant="body2"
                // eslint-disable-next-line
                                                                                                   href={"mailto:" + "support" + "@" + "pyramidencup.at"}
                                                                                                   style={{cursor: 'pointer'}}>Support
                kontaktieren</Link></Grid>
        </Grid>
    );
}

interface ActionButtonBarProps {
    activeStep: number
    maxSteps: number
    onBack: () => void
    onNext: (tenantConfig: TenantConfiguration) => void
    onSubmit: (tenantConfig: TenantConfiguration) => void
    disabled?: boolean
    tenantConfig: TenantConfiguration
}

export const ActionButtonBar: React.FC<ActionButtonBarProps> = ({
                                                                    activeStep,
                                                                    maxSteps,
                                                                    onBack,
                                                                    onNext,
                                                                    onSubmit,
                                                                    tenantConfig,
                                                                    disabled
                                                                }) => {
    const classes = useStyles();
    return (
        <Box marginBottom={2} marginTop={2}>
            <Button variant="outlined" disabled={activeStep === 0} onClick={onBack} className={classes.button}>
                Zurück
            </Button>
            {activeStep === maxSteps - 1 ?
                <Button variant="contained" color="primary" onClick={() => onSubmit(tenantConfig)}
                        className={classes.button} disabled={disabled}>
                    Erstellen
                </Button> :
                <Button variant="contained" color="primary" onClick={() => onNext(tenantConfig)} endIcon={disabled && <CircularProgress size={20}/>}
                        style={{minWidth: "92px"}} className={classes.button} disabled={disabled}>
                    {!disabled ? "Weiter" : ""}
                </Button>}
        </Box>
    )
}
