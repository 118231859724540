import {Configuration} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/configuration";
import {RequestContext} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/http/http";
import {cupAuthService} from "../../auth/cupAuthService";
import {PlayersApiRequestFactory} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/PlayersApi";
import {CreatePlayerRequest} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreatePlayerRequest";
import {UpdatePlayerRequest} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/UpdatePlayerRequest";
import {adminAuthService} from "../../auth/adminAuthService";


export class PlayersApiRequestEnhancer extends PlayersApiRequestFactory {

    public async _delete(playerId: number, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super._delete(playerId, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    public async create(createPlayerRequest: CreatePlayerRequest, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.create(createPlayerRequest, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }

    public getAll(xTenantKey?: string, xCupPw?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        return super.getAll(xTenantKey || tenantKey, xCupPw || cupPassword, _options)
    }

    public getAllLite(xTenantKey?: string, xCupPw?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        return super.getAllLite(xTenantKey || tenantKey, xCupPw || cupPassword, _options)
    }

    public async update(updatePlayerRequest: UpdatePlayerRequest, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.update(updatePlayerRequest, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }
}
