import React, {ReactNode} from 'react'
import {MaterialCheckbox} from "./MaterialCheckbox";


interface CheckboxFieldProps {
  name: string
  label?: ReactNode
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

export const CheckboxField: React.FC<CheckboxFieldProps> = ({ name, label, labelPlacement, ...other }) => {
  return <MaterialCheckbox name={name} label={label} color="secondary" labelPlacement={labelPlacement} {...other} />
}
