import {Box, Card, CardContent, Grid, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {EmptyContainer} from "../../../../components/empty/EmptyContainer";
import {TypographyEllipsis} from "../../../../components/output/TypographyEllipsis";
import {Game} from "../../../../api/games/model";
import {toErrorMessage} from "../../../../api/error/error";

type ResultsListComponentProps = {
  finishedGames: Game[]
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error: any;
}

export const ResultsListComponent = ({
                                       isError,
                                       isLoading,
                                       isSuccess,
                                       finishedGames,
                                       error,
                                     }: ResultsListComponentProps) => {
  const determineEmptyMessage = () => {
    if (isLoading) return "Ergebnisse werden geladen";
    if (isError) return `Fehler beim Laden.\n${toErrorMessage(error)}`;
    return "Keine Ergebnisse vorhanden."
  }

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginLeft={2}>
            <Typography variant='overline'>Ergebnisse</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {finishedGames && isSuccess && finishedGames.map(game => <ResultEntry key={game.id}
                                                                                finishedGame={game}/>)}
        </Grid>
        <Grid item xs={12}>
          {(!finishedGames || finishedGames.length < 1) &&
              <EmptyContainer loading={isLoading}
                              emptyMessage={determineEmptyMessage()}/>}
        </Grid>
      </Grid>
  )
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    challengeContainer: {
      backgroundColor: theme.palette.background.default
    },
    winner: {
      fontWeight: "bold"
    }
  }
})

interface ResultEntryProps {
  finishedGame: Game
}

export const ResultEntry: React.FC<ResultEntryProps> = ({finishedGame}) => {
  const classes = useStyles();
  return (
      <Box p={2} paddingTop={0}>
        <Card elevation={1} className={classes.challengeContainer}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary" align="left">
                  #{finishedGame.id}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="textSecondary" align="right">
                  {finishedGame.gameResultTimestamp.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Box display={'flex'} alignItems={"flex-end"}>
                      <TypographyEllipsis component="span" variant="body1"
                                          bold={finishedGame.challenger.id === finishedGame.winner.id}>
                        {finishedGame.challenger.name}
                      </TypographyEllipsis>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={'flex'} alignItems={"flex-end"}>
                      <TypographyEllipsis variant="body1"
                                          bold={finishedGame.defender.id === finishedGame.winner.id}>
                        {finishedGame.defender.name}
                      </TypographyEllipsis>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={6}>
                <Typography>{finishedGame.result}</Typography>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
  )
}
