import React, {useCallback, useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    Typography
} from "@material-ui/core";
import {Transition} from "../../../../components/layout/DialogTransition";
import {useStripe} from "@stripe/react-stripe-js";
import {toCurrency} from "../../../../util/common";
import {navigateToSettings} from "../../../../util/navigation";


interface StripeRedirectDialogProps {
}

export const StripeRedirectDialog: React.FC<StripeRedirectDialogProps> = () => {
    const [open, setOpen] = useState(false);
    const [serverMessage] = useState<string>('')
    const [message, setMessage] = useState('')
    const [title, setTitle] = useState('')
    const stripe = useStripe()

    const loadSetupIntent = useCallback((setupIntentClientSecret: string) => {
        if (stripe) {
            stripe.retrieveSetupIntent(setupIntentClientSecret).then(result => {
                if (result.error) {
                    handleClose()
                    return
                }
                switch (result.setupIntent.status) {
                    case "succeeded":
                        setMessage("Die Änderung der Zahlungsart war erfolgreich.")
                        break;
                    case "canceled":
                        setMessage("Die Änderung der Zahlungsart wurde abgebrochen.")
                        break;
                    case "processing":
                        setMessage("Die Änderung der Zahlungsart wird verarbeitet.")
                        break;
                    case "requires_action":
                    case "requires_confirmation":
                    case "requires_payment_method":
                        setMessage("Die Änderung der Zahlungsart war nicht erfolgreich.")
                }
            })
        }
    },[stripe])

    const loadPaymentIntent = useCallback( (paymentIntentClientSecret: string) => {
        if (stripe) {
            stripe.retrievePaymentIntent(paymentIntentClientSecret).then(result => {
                if (result.error) {
                    handleClose()
                    return
                }
                switch (result.paymentIntent.status) {
                    case "succeeded":
                        setMessage(`Die Zahlung über ${toCurrency(result.paymentIntent.amount, result.paymentIntent.currency)} war erfolgreich.`)
                        break;
                    case "canceled":
                        setMessage("Der Zahlvorgang wurde abgebrochen.")
                        break;
                    case "processing":
                        setMessage("Die Zahlung wird verarbeitet.")
                        break;
                    case "requires_action":
                    case "requires_confirmation":
                    case "requires_payment_method":
                        setMessage("Die Zahlung war nicht erfolgreich. Bitte versuchen Sie es mit einer anderen Zahlungsmethode noch einmal.")
                }
            })
        }
    },[stripe])

    useEffect(() => {
        const searchParams = new URLSearchParams(window.location.search)

        const noPaymentNecessary = searchParams.get("no_payment_necessary")
        if(noPaymentNecessary){
            setTitle("Abo")
            setMessage("Die Verlängerung des Abos war erfolgreich.")
            setOpen(true)
            return
        }

        const setupIntentClientSecret = searchParams.get("setup_intent_client_secret")
        if (setupIntentClientSecret) {
            setTitle('Zahlungsart ändern')
            setOpen(true)
            loadSetupIntent(setupIntentClientSecret)
        }
        const paymentIntentClientSecret= searchParams.get("payment_intent_client_secret")
        if (paymentIntentClientSecret) {
            setTitle('Zahlung')
            setOpen(true)
            loadPaymentIntent(paymentIntentClientSecret)
        }
    }, [stripe, loadPaymentIntent, loadSetupIntent])

    const handleClose = () => {
        setOpen(false)
        navigateToSettings()
    }

    return (
        <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}
                aria-labelledby="form-dialog-title" fullWidth>
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent style={{overflowY: "initial"}}>
                <Grid container>
                    <Grid item xs={12}>
                        <Typography>{message ? message : <span>&nbsp;</span>}</Typography>
                    </Grid>
                </Grid>
                <Box paddingTop={"15px"}>
                    <Typography color="error">{serverMessage}</Typography>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button type="button" variant="contained" color="primary"
                        onClick={handleClose}>Schließen</Button>
            </DialogActions>
        </Dialog>
    )
}
