import {TenantsApiResponseConverter} from "./tenants/TenantsApiResponseConverter";
import {RankingApiResponseConverter} from "./ranking/RankingApiResponseConverter";
import {PlayersApiResponseConverter} from "./player/PlayersApiResponseConverter";
import {GamesApiResponseConverter} from "./games/GamesApiResponseConverter";
import {ConfigurationApiResponseConverter} from "./configuration/ConfigurationApiResponseConverter";
import {createConfiguration, server1, server2} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {
  PromiseConfigurationApi,
  PromiseGamesApi,
  PromisePlayersApi,
  PromisePublicApi,
  PromiseRankingApi,
  PromiseTenantsApi
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/types/PromiseAPI";
import {TenantsApiRequestEnhancer} from "./tenants/TenantsApiRequestEnhancer";
import {RankingApiRequestEnhancer} from "./ranking/RankingApiRequestEnhancer";
import {PlayersApiRequestEnhancer} from "./player/PlayersApiRequestEnhancer";
import {GamesApiRequestEnhancer} from "./games/GamesApiRequestEnhancer";
import {ConfigurationApiRequestEnhancer} from "./configuration/ConfigurationApiRequestEnhancer";
import {PublicApiRequestEnhancer} from "./public/PublicApiRequestEnhancer";
import {PublicApiResponseConverter} from "./public/PublicApiResponseConverter";

export class PyramidCupApi {

  private readonly clientConfiguration;
  private readonly tenantsApi;
  private readonly playersApi;
  private readonly rankingApi;
  private readonly gamesApi;
  private readonly configurationApi;
  private readonly publicApi;

  constructor() {
    console.log("REACT_APP_ENVIRONMENT", process.env.REACT_APP_ENVIRONMENT)
    this.clientConfiguration = createConfiguration({baseServer: server1})
    if (process.env.REACT_APP_ENVIRONMENT === 'prod') {
      this.clientConfiguration = createConfiguration({baseServer: server2})
    }
    this.tenantsApi = new PromiseTenantsApi(this.clientConfiguration, new TenantsApiRequestEnhancer(this.clientConfiguration), new TenantsApiResponseConverter())
    this.playersApi = new PromisePlayersApi(this.clientConfiguration, new PlayersApiRequestEnhancer(this.clientConfiguration), new PlayersApiResponseConverter())
    this.rankingApi = new PromiseRankingApi(this.clientConfiguration, new RankingApiRequestEnhancer(this.clientConfiguration), new RankingApiResponseConverter())
    this.gamesApi = new PromiseGamesApi(this.clientConfiguration, new GamesApiRequestEnhancer(this.clientConfiguration), new GamesApiResponseConverter())
    this.publicApi = new PromisePublicApi(this.clientConfiguration, new PublicApiRequestEnhancer(this.clientConfiguration), new PublicApiResponseConverter())
    this.configurationApi = new PromiseConfigurationApi(this.clientConfiguration, new ConfigurationApiRequestEnhancer(this.clientConfiguration), new ConfigurationApiResponseConverter())
  }

  tenants(): PromiseTenantsApi {
    return this.tenantsApi
  }

  players(): PromisePlayersApi {
    return this.playersApi
  }

  ranking(): PromiseRankingApi {
    return this.rankingApi
  }

  games(): PromiseGamesApi {
    return this.gamesApi
  }

  configuration(): PromiseConfigurationApi {
    return this.configurationApi
  }

  public(): PromisePublicApi {
    return this.publicApi
  }
}

export const pyramidCupApi = new PyramidCupApi()
