import React from 'react'
import {Control, Controller, FieldValues, Path} from "react-hook-form";
import {DateTimePicker} from "@material-ui/pickers";
import {TextField} from "@material-ui/core";

type DateTimeInputProps<T extends FieldValues> = {
  name: Path<T>;
  control: Control<T>;
  required?: boolean;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
}

// TODO Fix DateTimePicker
export const DateTimeInput = <T extends FieldValues>({
                                                       name,
                                                       control,
                                                       label = '',
                                                       fullWidth,
                                                       disabled,
                                                       required = false,
                                                       ...other
                                                     }: DateTimeInputProps<T>) => {
  return (
      <Controller name={name} control={control} render={({field, fieldState}) => {
        return <DateTimePicker
            {...field}
            {...other}
            inputFormat="dd.MM.yyyy HH:mm"
            mask="__.__.____ __:__"
            disabled={disabled}
            renderInput={(props) => (
                <TextField
                    {...props}
                    {...other}
                    label={label}
                    placeholder="TT.MM.JJJJ HH:mm"
                    variant="outlined"
                    helperText="TT.MM.JJJJ HH:mm"
                    fullWidth={fullWidth}
                    error={!!fieldState.error}
                    onBlur={field.onBlur}
                    size="small"
                />
            )}
        />
      }}/>
  )
}
