import {Controller, FieldValues, Path, UseFormReturn} from "react-hook-form";
import React, {ReactElement} from "react";
import {TextField, TextFieldProps} from "@material-ui/core";

type TextInputProps<T extends FieldValues> = {
  name: Path<T>;
  useFormReturn: UseFormReturn<T>;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
  validate?: any;
  valueFormatter?: (value: any) => string;
  readonly?: boolean
} & TextFieldProps

export const TextInput = <T extends FieldValues>({
                                                   name,
                                                   useFormReturn,
                                                   label,
                                                   fullWidth = true,
                                                   disabled,
                                                   required,
                                                   helperText,
                                                   validate,
                                                   valueFormatter,
                                                   readonly,
                                                   ...props
                                                 }: TextInputProps<T>): ReactElement => {
  const getValue = (fieldValue: any) => {
    const value = readonly ? useFormReturn.getValues(name) : fieldValue
    return valueFormatter ? valueFormatter(value) : value
  }

  return (
      <Controller
          name={name}
          control={useFormReturn.control}
          rules={{
            required: !readonly ? {
              value: required,
              message: "Dieses Feld ist ein Pflichtfeld."
            } : undefined,
            validate: readonly ? undefined : validate,
          }}
          render={({field, fieldState}) => {
            return <TextField {...field}
                              style={{pointerEvents: readonly ? 'none' : 'all'}}
                              label={label}
                              fullWidth={fullWidth}
                              disabled={disabled}
                              variant="outlined"
                              size="small"
                              error={!readonly && !!fieldState.error}
                              helperText={(!readonly && fieldState.error?.message) || helperText}
                              value={getValue(field.value)}
                              {...props}
            />
          }}
      />
  );
};
