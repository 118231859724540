import React, {useState} from 'react';
import {Card, CardContent, Grid, Link, Typography} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import {Form} from "react-final-form";
import {SingleLineField} from "../../../components/inputs/singleLineInput/SingleLineField";
import {AuthErrorCodes, getAuth} from "firebase/auth";
import {adminAuthService} from "../../../auth/adminAuthService";
import {useCupCredentials} from "../../../auth/cupAuthService";
import {
    firebaseResetPassword,
    firebaseSendEmailVerification,
    firebaseSignIn
} from "../../../config/firebase";
import {toErrorMessage} from "../../../api/error/error";
import {useAuthState} from "react-firebase-hooks/auth";

interface LoginProps {
  onLoginSuccess: () => void
}

export const Login: React.FC<LoginProps> = ({onLoginSuccess}) => {
  const [showPasswordResetForm, setShowPasswordResetForm] = useState(false)
  const [passwordResetted, setPasswordResetted] = useState(false)
  const onPasswordResetSuccess = () => {
    setPasswordResetted(true)
    setShowPasswordResetForm(false)
  }

  return (
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={7} lg={4}>
          <Card style={{paddingLeft: 20, paddingRight: 20}}>
            <CardContent>
              {!showPasswordResetForm && <AdminLoginForm onLoginSuccess={onLoginSuccess}
                                                         onShowPasswordResetForm={() => setShowPasswordResetForm(true)}
                                                         passwordReset={passwordResetted}
              />}
              {showPasswordResetForm &&
                  <PasswordResetForm onPasswordResetSuccess={onPasswordResetSuccess}/>}
            </CardContent>
          </Card>
        </Grid>
      </Grid>

  )
}

interface AdminCredentials {
  adminEmail: string,
  adminPassword: string
}

interface AdminLoginFormProps {
  onLoginSuccess: () => void
  onShowPasswordResetForm: () => void
  passwordReset: boolean
}

const AdminLoginForm: React.FC<AdminLoginFormProps> = ({
                                                         onLoginSuccess,
                                                         onShowPasswordResetForm,
                                                         passwordReset
                                                       }) => {
  const [errorMessage, setErrorMessage] = useState('')
  const {tenantKey} = useCupCredentials()
  const [showResendVerificationMailLink, setShowResendVerificationMailLink] = useState(false)
  const [user] = useAuthState(getAuth());
  const [adminCredentials, setAdminCredentials] = useState<AdminCredentials>(undefined)

  const onSubmit = async (values: AdminCredentials) => {
    setErrorMessage("")
    try {
      if (!values.adminEmail) {
        setErrorMessage("Bitte geben Sie eine E-Mail Adresse ein.")
        return
      }
      if (!values.adminPassword) {
        setErrorMessage("Bitte geben Sie ein Passwort ein.")
        return
      }
      await adminAuthService().login(values.adminEmail, values.adminPassword)
      onLoginSuccess()
    } catch (e) {
      switch (e.code) {
        case 412:
          setAdminCredentials(values)
          setErrorMessage(toErrorMessage(e))
          setShowResendVerificationMailLink(true)
          break
        case 423:
          setErrorMessage(`Dieser Benutzer ist kein Admin des Cups '${tenantKey}'.`)
          break
        case AuthErrorCodes.INVALID_EMAIL:
        case AuthErrorCodes.INVALID_PASSWORD:
        case AuthErrorCodes.USER_DELETED:
          setErrorMessage("Die eingegebene E-Mail oder das Passwort ist falsch.")
          break
        default:
          setErrorMessage("Bei der Anmeldung ist ein unerwarteter Fehler aufgetreten.")
      }
    }
  }

  const onPasswordForgotten = () => {
    onShowPasswordResetForm()
  }

  const onResendVerificationMail = async () => {
    try {
      if (!user) {
        await firebaseSignIn(adminCredentials.adminEmail, adminCredentials.adminPassword)
      }
      await firebaseSendEmailVerification()
      setShowResendVerificationMailLink(false)
      setErrorMessage("Sie erhalten in Kürze eine E-Mail mit dem Link zur Bestätigung Ihrer E-Mail Adresse.")
    } catch (e) {
      console.log(e)
      setErrorMessage("Es ist ein unerwarteter Fehler aufgetreten.")
    }

  }

  return (
      <Form onSubmit={onSubmit}
            render={({handleSubmit}) => {
              return (
                  <form id={'adminLogin'} onSubmit={handleSubmit} noValidate>
                    <Grid container>
                      <Grid item xs={12} style={{paddingBottom: 10}}><Typography
                          variant="overline">Login</Typography>
                      </Grid>
                      {passwordReset && <Grid item xs={12} style={{paddingBottom: 16}}>
                        <Typography variant="caption">Sie erhalten in Kürze eine E-Mail mit der Sie
                          Ihr
                          Passwort zurücksetzen können.</Typography>
                      </Grid>}
                      <Grid item xs={12}>
                        <SingleLineField name="adminEmail" label="E-Mail" autoComplete="email"/>
                      </Grid>
                      <Grid item xs={12}>
                        <SingleLineField name="adminPassword" label="Passwort" type="password"
                                         autoComplete="current-password"/>
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" type="submit" color="primary"
                                fullWidth>Login</Button>
                      </Grid>
                      <Grid item xs={12} container justifyContent="flex-end"
                            style={{paddingTop: 8, paddingRight: 8}}>
                        <Link style={{cursor: "pointer"}} onClick={onPasswordForgotten}>Passwort
                          vergessen?</Link>
                      </Grid>
                      <Grid item xs={12}
                            style={{minHeight: 31, paddingTop: 10, textAlign: "center"}}>
                        <Typography variant="caption">{errorMessage}&nbsp;</Typography>
                      </Grid>
                      {showResendVerificationMailLink &&
                          <Grid item xs={12} container justifyContent="center"
                                style={{paddingTop: 8}}>
                            <Button size="small" variant="outlined"
                                    onClick={onResendVerificationMail}>Link erneut per E-Mail
                              senden</Button>
                          </Grid>}
                    </Grid>
                  </form>
              )
            }}
      />
  )
}

interface PasswordResetFormProps {
  onPasswordResetSuccess: () => void
}

const PasswordResetForm: React.FC<PasswordResetFormProps> = ({onPasswordResetSuccess}) => {

  const [statusMessage, setStatusMessage] = useState("")

  const onSubmit = async (values) => {
    try {
      if (!values.adminEmail) {
        setStatusMessage("Bitte geben Sie eine E-Mail Adresse ein.")
        return
      }
      await firebaseResetPassword(values.adminEmail)
      onPasswordResetSuccess()
    } catch (e) {
      switch (e.code) {
        case AuthErrorCodes.INVALID_EMAIL:
          setStatusMessage(`Bitte geben Sie eine korrekte E-Mail Adresse ein.`)
          break
        case AuthErrorCodes.USER_DELETED:
          setStatusMessage(`Diese E-Mail Adresse konnte nicht gefunden werden.`)
          break
        default:
          setStatusMessage("Es ist ein unerwarteter Fehler aufgetreten.")
      }

    }
  }

  return (
      <Form onSubmit={onSubmit}
            render={({handleSubmit}) => {
              return (
                  <form id={'adminLogin'} onSubmit={handleSubmit} noValidate>
                    <Grid container>
                      <Grid item xs={12} style={{paddingBottom: 10}}><Typography
                          variant="overline">Passwort zurücksetzen</Typography>
                      </Grid>
                      <Grid item xs={12}>
                        <SingleLineField name="adminEmail" label="E-Mail" autoComplete="email"/>
                      </Grid>
                      <Grid item xs={12}>
                        <Button variant="contained" type="submit" color="primary" fullWidth>Passwort
                          Zurücksetzen</Button>
                      </Grid>
                      <Grid item xs={12}
                            style={{minHeight: 31, paddingTop: 10, textAlign: "center"}}>
                        <Typography variant="caption">{statusMessage}&nbsp;</Typography>
                      </Grid>
                    </Grid>
                  </form>
              )
            }}
      />
  )
}
