import React, {ReactNode} from 'react'
import { Field } from 'react-final-form'
import { Checkbox, CheckboxProps, FormControlLabel } from '@material-ui/core'

interface MaterialCheckboxProps {
  name: string
  label?: ReactNode
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

export const MaterialCheckbox: React.FC<MaterialCheckboxProps & CheckboxProps> = ({
  name,
  label,
  labelPlacement,
  ...other
}) => {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <FormControlLabel control={<Checkbox {...input} {...other} />} label={label} labelPlacement={labelPlacement} />
      )}
    </Field>
  )
}
