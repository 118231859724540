import {createTheme, responsiveFontSizes} from '@material-ui/core'

import {spacing} from "./common";


const primary = '#3d5a80'
const secondary = '#ee6c4d'
const light = responsiveFontSizes(
    createTheme({
        spacing,
        palette: {
            primary: {
                main: primary
            },
            secondary: {
                main: secondary
            }
        }
    })
)

export default light