// Import the functions you need from the SDKs you need
import {initializeApp} from "firebase/app";
import {
    fetchSignInMethodsForEmail,
    getAuth,
    sendPasswordResetEmail,
    setPersistence,
    signInWithEmailAndPassword,
    User
} from "firebase/auth";
import {browserLocalPersistence, sendEmailVerification} from "@firebase/auth";

// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyB3OPPBtU7Sx0wvVfvYLcHp7WCD7rRBdNE",
    authDomain: "pyramid-cup.firebaseapp.com",
    projectId: "pyramid-cup",
    storageBucket: "pyramid-cup.appspot.com",
    messagingSenderId: "717964009523",
    appId: "1:717964009523:web:658d714ef4687b94f1c346",
    measurementId: "G-P2TYBDW8WG"
};

// Initialize Firebase
/*const app = */
initializeApp(firebaseConfig);
//const analytics = getAnalytics(app);


export const firebaseSignIn = async (email: string, password: string): Promise<User> => {
    const auth = getAuth();
    await setPersistence(auth, browserLocalPersistence)
    const userCredential = await signInWithEmailAndPassword(auth, email, password)
    return userCredential.user
}

export const checkIfEmailExists = async (email: string): Promise<boolean> => {
    const auth = getAuth();
    const methods = await fetchSignInMethodsForEmail(auth, email)
    return methods.length !== 0
}

export const firebaseGetCurrentUser = (): User | null => {
    return getAuth().currentUser;
}

export const firebaseSignOut = async () => {
    await getAuth().signOut()
}

export const firebaseResetPassword = async (email: string) => {
    await sendPasswordResetEmail(getAuth(), email)
}

export const firebaseSendEmailVerification = async (tenantKey?: string, tenantName?: string)=>{
    let redirectUrl = window.location.origin
    if(tenantKey && tenantName) redirectUrl = `${redirectUrl}/?key=${tenantKey}&name=${tenantName}&type=email`
    const user = firebaseGetCurrentUser()
    await sendEmailVerification(user, {url: redirectUrl})
}
