import React from "react";
import {Box, Grid, Typography} from "@material-ui/core";
import {formatDate, formatTime} from "../../../util/date";
import {TenantDTOStatusEnum} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {ChallengeDialog} from "./ChallengeDialog";
import {Tenant} from "../../../api/tenants/model";
import {ChallengeRulesDialog} from "./ChallengeRulesDialog";

interface ChallengeProps {
  startTimestamp: Date
  tenantStatus: TenantDTOStatusEnum
  tenant: Tenant
}

export const Challenge: React.FC<ChallengeProps> = ({
                                                      startTimestamp,
                                                      tenantStatus,
                                                    }) => {
  return (
      <>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box marginLeft={2}>
              <Typography variant='overline'>Forderung</Typography>
            </Box>
          </Grid>
          {tenantStatus === "NOT_STARTED" && startTimestamp.getTime() !== 0 &&
              <Grid item xs={12} container justifyContent='center'>
                &nbsp; <Typography color="textPrimary" variant="body1">Der Cup startet
                am <Typography component="span" color="secondary"
                               style={{fontWeight: "bold"}}>{formatDate(startTimestamp)}</Typography> um <Typography
                    component="span" color="secondary"
                    style={{fontWeight: "bold"}}>{formatTime(startTimestamp)}</Typography>.</Typography>
              </Grid>}
          <Grid item xs={12} container justifyContent='center'>
            <Box margin={2} mt={0} display="flex" flexDirection="column" style={{gap: "1rem"}}
                 alignItems="center">
              <ChallengeDialog
                  tenantStatus={tenantStatus} buttonTitle="Spieler fordern"
                  buttonColor="secondary" buttonVariant="contained"
                  dialogTitle="Neue Forderung"/>
              <ChallengeRulesDialog/>
            </Box>
          </Grid>
        </Grid>
      </>
  )
}
