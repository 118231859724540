import React, {forwardRef} from 'react'


import {MaterialField} from "./MaterialField";

interface SingleLineFieldProps extends NodeJS.Dict<any> {
    name: string
    label?: string
    required?: boolean
}

export const SingleLineField: React.FC<SingleLineFieldProps> = forwardRef<HTMLInputElement, SingleLineFieldProps>(
    ({ name, label = '', required = false, ...other }, ref) => {
        return (
            <MaterialField
                name={name}
                label={label}
                required={required}
                variant="outlined"
                fullWidth
                ref={ref}
                {...other}
            />
        )
    }
)
