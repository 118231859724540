import React from "react";
import {Elements, PaymentElement, useElements, useStripe} from "@stripe/react-stripe-js";
import {Stripe, StripeElements, StripeError} from "@stripe/stripe-js";
import {stripePromise} from "../../../../../App";


interface StripePaymentFormProps {
  formId: string
  clientSecret: string
  onPaymentFormReady?: () => void
  onSubmit?: (stripe: Stripe, elements: StripeElements) => void
}

export const StripePaymentForm: React.FC<StripePaymentFormProps> = ({
                                                                      formId,
                                                                      clientSecret,
                                                                      onPaymentFormReady,
                                                                      onSubmit,
                                                                    }) => {
  return (
      <Elements stripe={stripePromise} options={{clientSecret: clientSecret}}>
        <PaymentForm formId={formId} onPaymentFormReady={onPaymentFormReady}
                     onSubmit={onSubmit}/>
      </Elements>
  )
}

interface PaymentFormProps {
  formId: string
  onPaymentFormReady?: () => void
  onError?: (error: StripeError) => void
  onSubmit?: (stripe: Stripe, elements: StripeElements) => void
}

const PaymentForm: React.FC<PaymentFormProps> = ({
                                                   formId,
                                                   onPaymentFormReady,
                                                   onSubmit,
                                                 }) => {

  const stripe = useStripe();
  const elements = useElements();

  const handlePaymentFormReady = () => {
    onPaymentFormReady()
  }

  const handleSubmit = async () => {
    onSubmit(stripe, elements)
  }
  return (
      <>
        <form id={formId} onSubmit={handleSubmit}>
          <PaymentElement onReady={handlePaymentFormReady}/>
        </form>
      </>
  )
}
