export const spacing = 8

// @ts-ignore
export const getOverrides = ({ hover }) => ({
    // Cards
    MuiCardHeader: {
        root: {
            paddingTop: 12,
            paddingBottom: 0
        }
    },
    MuiCardActionArea: {
        root: {
            '&:hover .MuiCardActionArea-focusHighlight': {
                opacity: 1,
                backgroundColor: hover
            }
        },
        focusHighlight: {
            backgroundColor: hover
        }
    },
    // Dialogs
    MuiDialogTitle: {
        root: {
            paddingTop: 8,
            paddingBottom: 8
        }
    },
    // Text Fields
    MuiFilledInput: {
        root: {
            borderTopLeftRadius: 4,
            borderTopRightRadius: 4
        },
        input: {
            padding: '24px 12px 6px'
        },
        inputMarginDense: {
            paddingTop: 22,
            paddingBottom: 4
        }
    },
    MuiOutlinedInput: {
        root: {
            borderRadius: 4
        },
        input: {
            padding: '13.5px 14px'
        }
    },
    MuiAutocomplete: {
        input: {
            padding: '4.5px 4px 6px !important'
        }
    },
    MuiInputLabel: {
        marginDense: {
            top: -2
        },
        formControl: {
            top: -4
        }
    },
    // Tables
    MuiTableCell: {
        root: {
            padding: '12px 16px'
        },
        sizeSmall: {
            padding: '8px 16px'
        }
    },
    // Icon Buttons
    MuiIconButton: {
        root: {
            padding: 8
        },
        sizeSmall: {
            padding: 4
        }
    },
    MuiToggleButton: {
        root: {
            padding: 8
        },
        sizeSmall: {
            padding: 4
        }
    }
})
