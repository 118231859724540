import React, {ReactElement, ReactNode, useEffect} from "react";
import {noop} from "../../util/functions";
import {useForm, UseFormReturn} from "react-hook-form";

interface FormDialogProps<T extends unknown> {
  formValues: T
  formComponent: (useFormReturn: UseFormReturn<T>) => ReactNode
}

export const ReadOnlyForm = <T extends object>({
                                                 formValues,
                                                 formComponent
                                               }: FormDialogProps<T>): ReactElement => {

  const useFormReturn = useForm<any>({
    defaultValues: {...formValues}
  });
  useEffect(() => {
    useFormReturn.reset({...formValues})
  }, [formValues, useFormReturn])

  return (
      <>
        <form onSubmit={noop}>
          {formComponent(useFormReturn)}
        </form>
      </>
  )
}
