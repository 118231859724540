import React, {ReactElement} from "react";
import {Grid, Typography} from "@material-ui/core";
import {SelectInput} from "../../../../components/form/inputs/SelectInput";
import {TextInput} from "../../../../components/form/inputs/TextInput";
import {UseFormReturn} from "react-hook-form";
import {
  FinishGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/FinishGameRequest";
import {PlayerLite} from "../../../../api/player/model";
import {toOption} from "../../../../components/inputs/singleSelect/option";

type FinishGameFormProps = {
  challenger: PlayerLite
  defender: PlayerLite
  useFormReturn: UseFormReturn<FinishGameRequest>
}

export const FinishGameForm = ({
                                 challenger,
                                 defender,
                                 useFormReturn
                               }: FinishGameFormProps): ReactElement => {

  const options = [challenger, defender].map((player) => {
    return toOption(player.id, `${player.name}`, player.id)
  })
  return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Typography variant={"overline"}>Sieger</Typography>
        </Grid>
        <Grid item xs={12}>
          <SelectInput
              name="winnerId"
              useFormReturn={useFormReturn}
              options={options}
              label={"Sieger"}
              fullWidth
          />
        </Grid>
        <Grid item xs={12} style={{marginTop: 2}}>
          <Typography variant={"overline"}>Resultat (optional)</Typography>
        </Grid>
        <Grid item xs={12} container alignItems="center">
          <TextInput name="result" useFormReturn={useFormReturn} label="Resultat"
                     fullWidth helperText={`${challenger.name} gegen ${defender.name}`}
          />
        </Grid>
      </Grid>
  );
};
