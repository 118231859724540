import React from 'react'
import {Form as FinalForm} from 'react-final-form'

type FinalFormType = typeof FinalForm

/**
 * @deprecated
 */
export const Form: FinalFormType = (props) => {
  return (
      <FinalForm {...props}>
        {(formRenderProps) => {
          return (
              <form onSubmit={formRenderProps.handleSubmit}>
                {typeof props.children === 'function' ? props.children(formRenderProps) : props.children}
              </form>
          )
        }}
      </FinalForm>
  )
}
