import React from "react";
import {UseFormReturn} from "react-hook-form";
import {Grid, Typography} from "@material-ui/core";
import {Configuration} from "../../../../api/configuration/model";
import {NumberInput} from "../../../../components/form/inputs/NumberInput";

type ChallengeTopXPlayerConfigurationFormProps = {
  useFormReturn?: UseFormReturn<Configuration>
  readonly?: boolean
}
export const ChallengeTopXPlayerConfigurationForm = ({
                                                       readonly,
                                                       useFormReturn
                                                     }: ChallengeTopXPlayerConfigurationFormProps) => {

  const validate = () => {
    return {
      isNumber: v => !isNaN(parseInt(v)) || 'Der Wert muss eine Zahl sein.',
      positive: v => parseInt(v) > 0 || 'Der Wert muss größer oder gleich 1 sein.',
    }
  };

  return (
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={6} lg={4}>
          {<NumberInput name="topXPlayersCanChallengeBetterPlayers"
                        useFormReturn={useFormReturn}
                        label="Rang"
                        required
                        fullWidth
                        helperText={"Spieler ab diesem Rang können alle besser platzierten Spieler fordern"}
                        autoFocus={!readonly} validate={validate()} noGrouping={true}
                        noDecimals={true} readonly={readonly}/>}
        </Grid>
        <Grid item xs={12} md={6} lg={8}>
          <Typography variant={"caption"} component="p">Ziel dieser Einstellung ist
            es mehr
            Forderungen im oberen Bereich der Pyramide zu ermöglichen.</Typography>
          <br/><Typography variant={"caption"} component="p">Beispielsweise bewirkt
          ein Wert
          von 6, dass jeder Spieler der Top 6 jeden besser platzierten Spieler
          fordern kann.
          Ein Wert von 1 deaktiviert diese Regel.</Typography>
          <br/><Typography variant={"caption"} component="p">Standardwert:
          1</Typography>
        </Grid>
      </Grid>
  )
}
