import {
    RankingApiResponseProcessor
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/RankingApi";
import {ResponseContext} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {handleAuthorization} from "../error/error";


export class RankingApiResponseConverter extends RankingApiResponseProcessor {
    public async create(response: ResponseContext): Promise<void > {
        if (handleAuthorization(response)) return
        return super.create(response)
    }
}
