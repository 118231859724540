import {millisToString} from "../../../util/duration";
import {Player} from "../../../api/player/model";
import {PyramidElementData, PyramidElementStatus} from "./PyramidComponent";


export const createPyramidData = (selectedPlayer: Player, allPlayers: Player[]): PyramidElementData[][] => {
  const elements = createPyramidElements(selectedPlayer, allPlayers)
  return mapToPyramidDataStructure(elements)
}


const createPyramidElements = (selectedPlayer: Player, allPlayers: Player[]): PyramidElementData[] => {

  // no player is selected
  if (!selectedPlayer) {
    return allPlayers.map(player => createPyramidElement(player, '', PyramidElementStatus.DEFAULT))
  }

  // selected player has game
  if (selectedPlayer.opponent) {
    return allPlayers.map(player => {
      if (selectedPlayer.id === player.id) {
        return createPyramidElement(selectedPlayer, '', PyramidElementStatus.SELECTED)
      }
      if (selectedPlayer.opponent.id === player.id) {
        return createPyramidElement(player, 'aktueller Gegner', PyramidElementStatus.OPPONENT)
      }
      return createPyramidElement(player, '', PyramidElementStatus.DEFAULT)
    })
  }

  // selectedPlayer is freezed
  if (selectedPlayer.challengerFreezeDuration > 0) {
    return allPlayers.map(player => {
      if (player.id === selectedPlayer.id) {
        return createPyramidElement(player, `gesperrt (noch ${millisToString(player.challengerFreezeDuration)})`, PyramidElementStatus.SELECTED)
      } else {
        return createPyramidElement(player, '', PyramidElementStatus.DEFAULT)
      }
    })
  }

  //selected player is inactive
  if (selectedPlayer.inactive) {
    return allPlayers.map(player => {
      if (selectedPlayer.id === player.id) {
        return createPyramidElement(selectedPlayer, 'inaktiv', PyramidElementStatus.INACTIVE)
      }
      return createPyramidElement(player, '', PyramidElementStatus.DEFAULT)
    })
  }


  return allPlayers.map(player => {
    // player is dummy element
    if (player.id === 0) {
      return createEmptyPyramidElement()
    }

    if (selectedPlayer.id === player.id) {
      // player is selected player
      return createPyramidElement(selectedPlayer, '', PyramidElementStatus.SELECTED)
    } else {
      // player is out of challenge range
      if (player.ranking > selectedPlayer.ranking || player.ranking < selectedPlayer.minPossibleDefenderRanking) {
        return createPyramidElement(player, '', PyramidElementStatus.DEFAULT)
      }

      // player is playing a game himself (not against selected player)
      if (player.opponent) {
        return createPyramidElement(player, `spielt gegen ${player.opponent.name}`, PyramidElementStatus.UNCHALLENGABLE)
      }

      // player is inactive
      if (player.inactive) {
        return createPyramidElement(player, `inaktiv`, PyramidElementStatus.INACTIVE)
      }

      // player is blocked due to having played against selected player previously
      if (selectedPlayer.blockedPlayerDueRevengeBan && selectedPlayer.blockedPlayerDueRevengeBan.id === player.id) {
        return createPyramidElement(player, `keine Revanche (noch ${millisToString(selectedPlayer.instantRevengeBanDuration)})`, PyramidElementStatus.UNCHALLENGABLE)
      }

      // player is protected
      if (player.defenderProtectionDuration > 0) {
        return createPyramidElement(player, `geschützt (noch ${millisToString(player.defenderProtectionDuration)})`, PyramidElementStatus.UNCHALLENGABLE)
      }

      // player is challangeable
      if (selectedPlayer.challengeablePlayers.map(p => p.id).includes(player.id)) {
        return createPyramidElement(player, 'forderbar', PyramidElementStatus.CHALLANGEABLE)
      }
      return createPyramidElement(player, '', PyramidElementStatus.DEFAULT)

    }
  })

}

const createPyramidElement = (player: Player, subtitle: string, status: PyramidElementStatus): PyramidElementData => {
  return {
    id: player.id, name: player.name, rank: player.ranking, subtitle, status
  }
}

const createEmptyPyramidElement = (): PyramidElementData => {
  return {
    id: 0, name: '-', rank: 0, subtitle: '', status: PyramidElementStatus.DEFAULT
  }
}

const mapToPyramidDataStructure = (players: PyramidElementData[]): PyramidElementData[][] => {
  const copy = players.map(p => p);
  const pyramidData: PyramidElementData[][] = []

  copy.forEach((p: PyramidElementData, index: number) => {
    const start = pyramidData.flatMap(p => p).length
    const end = 1 + start + index
    if (start < copy.length) {
      pyramidData.push(copy.slice(start, end))
    } else {
      const expectedLength = pyramidData[pyramidData.length - 2].length + 1
      while (pyramidData[pyramidData.length - 1].length < expectedLength) {
        pyramidData[pyramidData.length - 1].push(createEmptyPyramidElement())
      }
    }
  })
  return pyramidData
}






