import React from "react";
import {useGamesState} from "../../../../api/games/useGames";
import {ResultsListComponent} from "./ResultsListComponent";


export const ResultsList = () => {
  const {finishedGames, isLoading, isError, isSuccess, error} = useGamesState();
  return (
      <ResultsListComponent finishedGames={finishedGames} isLoading={isLoading} isError={isError}
                            isSuccess={isSuccess} error={error}/>
  )
}
