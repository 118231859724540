export interface Option {
    id: any
    label: string
    value: any
    icon?: any
}

export const toOption = (id: any, label: string, value: any, icon?: any): Option => ({
    id,
    label,
    value,
    icon
})
