import {Box, CircularProgress} from "@material-ui/core";
import React from "react";


export const ProgressIndicator: React.FC = () => {
  return (
      <Box display="flex" justifyContent="center" style={{
        position: 'absolute',
        left: 0,
        right: 0,
        top: '50%',
        transform: 'translateY(-50%)'
      }}><CircularProgress/></Box>
  )
}
