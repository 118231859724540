import React, {CSSProperties, ReactNode} from 'react';
import {Box} from "@material-ui/core";
import {IconButton} from '@material-ui/core';
import Button from "@material-ui/core/Button";

interface ResponsiveButtonProps {
    text: string,
    onClick: any,
    icon: ReactNode,
    displayIconOnDesktop: boolean
    style?: CSSProperties
}

export const ResponsiveButton: React.FC<ResponsiveButtonProps> = ({text, onClick, icon, displayIconOnDesktop, style}) => {
    return (
        <div style={style}>
            <Box display={{xs: 'none', sm: 'block'}}>
                <Button color="inherit" variant="outlined" onClick={onClick} startIcon={displayIconOnDesktop && icon}>{text}</Button>
            </Box>
            <Box display={{xs: 'block', sm: 'none'}}>
                <IconButton color="inherit" onClick={onClick}>
                    {icon}
                </IconButton>
            </Box>
        </div>
    )
}
