import React from 'react'
import { Divider, Grid, Typography } from '@material-ui/core'

interface TitledDividerProps {
    title: string
    action?: React.ReactElement
}

const TitledDivider: React.FC<TitledDividerProps> = ({ title, action }) => {
    return (
        <Grid container spacing={2} alignItems="center">
            <Grid item>
                <Typography variant="h6" color="textSecondary">
                    {title}
                </Typography>
            </Grid>
            <Grid item xs>
                <Divider />
            </Grid>
            <Grid item>{action}</Grid>
        </Grid>
    )
}

export default TitledDivider
