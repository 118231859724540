import React, {ReactElement} from "react";
import {useLocation} from "react-router-dom";
import * as queryString from "querystring";
import {PyramidComponent} from "../home/pyramid/PyramidComponent";
import {usePublicTenantInfo} from "../../api/public/usePublic";
import {Card, Grid} from "@material-ui/core";
import {Header} from "../../components/header/Header";
import {ResultsListComponent} from "../home/game/gameresult/ResultsListComponent";
import {ChallengesListComponent} from "../home/challenges/ChallengesListComponent";

type ReadOnlyCupPageProps = {}

export const ReadOnlyCupPage = ({}: ReadOnlyCupPageProps): ReactElement => {
  const {search} = useLocation()
  const queryParams = queryString.parse(search.replace("?", "")) as {}
  const key = queryParams["param"]
  const {data, isError, error, isLoading, isSuccess} = usePublicTenantInfo(key)
  const players = data?.players || [];
  return (
      <>
        <Header title={data?.tenantName || ""} readonly={true}/>
        <Grid container style={{padding: "8px", gap: "16px"}}>
          <Grid item xs={12}>
            <Card>
              <PyramidComponent players={players} isError={isError} error={error}
                                isLoading={isLoading}/>
            </Card>
          </Grid>
          <Grid md={2} xs={12}></Grid>
          <Grid md={4} xs={12}>
            <Card>
              <ChallengesListComponent openGames={data?.openGames || []} isError={isError}
                                       error={error} isLoading={isLoading} isSuccess={isSuccess}
                                       readonly={true}/>
            </Card>
          </Grid>
          <Grid md={4} xs={12}>
            <Card>
              <ResultsListComponent finishedGames={data?.results || []} isError={isError}
                                    error={error} isLoading={isLoading} isSuccess={isSuccess}/>
            </Card>
          </Grid>

        </Grid>
      </>
  );
};
