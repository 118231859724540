import React from "react";
import {UseFormReturn} from "react-hook-form";
import {Box, Grid, Typography} from "@material-ui/core";
import {
  Configuration,
  MinChallengeRankType,
  MinChallengeRankTypes
} from "../../../../api/configuration/model";
import {SelectInput} from "../../../../components/form/inputs/SelectInput";
import {toOption} from "../../../../components/inputs/singleSelect/option";

const determineTypLabel = (type: MinChallengeRankType) => {
  switch (type) {
    case MinChallengeRankType.RIGHTSIDE_UPPER_ROW:
      return "rechts oberhalb"
    case MinChallengeRankType.FULL_UPPER_ROW:
      return "gesamte Reihe oberhalb"
  }
}
const options = MinChallengeRankTypes.map((u) => toOption(u, determineTypLabel(u), u))


type MinChallengerRankTypeConfigurationFormProps = {
  useFormReturn?: UseFormReturn<Configuration>
  readonly?: boolean
}
export const MinChallengerRankTypeConfigurationForm = ({
                                                         readonly,
                                                         useFormReturn
                                                       }: MinChallengerRankTypeConfigurationFormProps) => {

  const {watch} = useFormReturn;
  const selectedValue = watch("minChallengeRankType")
  return (
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12} md={6} lg={readonly ? 4 : 6}>
          <SelectInput
              name="minChallengeRankType"
              label="Auswahl"
              options={options}
              fullWidth
              useFormReturn={useFormReturn}
              readonly={readonly}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box minHeight={60}>
            {selectedValue === MinChallengeRankType.FULL_UPPER_ROW.toString() &&
                <Typography variant={"caption"} component="p">Ein Spieler darf besser
                  platzierte Spieler in <b>seiner Reihe</b> fordern,
                  sowie <b>alle</b> Spieler der Reihe oberhalb</Typography>}
            {selectedValue === MinChallengeRankType.RIGHTSIDE_UPPER_ROW.toString() &&
                <Typography variant={"caption"} component="p">Ein Spieler darf besser
                  platzierte Spieler in <b>seiner Reihe</b> fordern, sowie Spieler <b>rechts
                    von ihm</b> in der Reihe oberhalb</Typography>}
            <br/><Typography variant={"caption"} component="p">Standardwert: rechts
            oberhalb</Typography>
          </Box>
        </Grid>
      </Grid>
  )
}
