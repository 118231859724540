import React, {
  FormEvent,
  PropsWithChildren,
  ReactElement,
  useCallback,
  useEffect,
  useState
} from "react";
import {
  Box,
  Button,
  ButtonProps,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import {Transition} from "../layout/DialogTransition";
import {useTheme} from "@material-ui/core/styles";
import {UseMutationResult} from "react-query";
import {SubmitHandler, UseFormReturn} from "react-hook-form";
import {toErrorMessage} from "../../api/error/error";


interface FormDialogProps<T extends unknown, S extends unknown> {
  buttonText: string;
  dialogTitel: string;
  buttonProps?: ButtonProps
  dialogButtonText?: string
  onAbortClicked?: () => void;
  onClose?: () => void;
  mutation: UseMutationResult<S, unknown, T, unknown>;
  useFormReturn: UseFormReturn<T, any>;
  submitDisabled?: boolean
}

export const FormDialog = <T extends unknown, S extends unknown>({
                                                                   buttonProps,
                                                                   buttonText,
                                                                   dialogTitel,
                                                                   onAbortClicked,
                                                                   onClose,
                                                                   children,
                                                                   mutation,
                                                                   useFormReturn,
                                                                   submitDisabled,
                                                                   dialogButtonText,
                                                                 }: PropsWithChildren<FormDialogProps<T, S>>): ReactElement => {
  const theme = useTheme();
  const [isOpen, setOpen] = useState(false);
  const {mutate, isLoading, isSuccess, isError, reset, error} = mutation;
  const {handleSubmit, reset: resetForm} = useFormReturn;
  const errorMessage = toErrorMessage(error);

  const handleClose = useCallback(() => {
    setOpen(false);
    reset();
    resetForm();
    onClose?.();
  }, [setOpen, reset, resetForm, onClose])

  useEffect(() => {
    handleClose()
  }, [isSuccess, handleClose])

  const handleClickOpen = () => {
    reset();
    setOpen(true);
  };


  const handleAbort = () => {
    onAbortClicked?.();
    handleClose();
  }

  const onSubmit = (e: FormEvent) => {
    e.preventDefault();
    return handleSubmit(mutate as SubmitHandler<any>)();
  }

  return (
      <>
        <Button {...buttonProps}
                onClick={handleClickOpen}>{buttonText}</Button>
        <Dialog open={isOpen && !isSuccess} onClose={handleClose} TransitionComponent={Transition}
                fullWidth
                maxWidth="sm">
          {isLoading && !isSuccess && !isError && <ModalBox/>}
          <form onSubmit={onSubmit}>
            <DialogTitle>{dialogTitel}</DialogTitle>
            <DialogContent style={{overflowY: "initial"}}>
              {children}
            </DialogContent>
            <DialogActions>
              <Box display="flex" flexDirection="column" flexGrow={1}>
                <Box flexGrow={1} style={{paddingBottom: "1rem", textAlign: "center"}}><Typography
                    color="error"
                    variant="caption">{error && errorMessage ? errorMessage :
                    <span>&nbsp;</span>}</Typography></Box>
                <Box display="flex" flexGrow={1} style={{gap: theme.spacing(1)}}
                     justifyContent="flex-end">
                  <Button variant="outlined" color="primary"
                          onClick={handleAbort}>Abbrechen</Button>
                  <Button type="submit" variant="contained"
                          color="primary"
                          disabled={submitDisabled}>{dialogButtonText || "Speichern"}</Button>
                </Box>
              </Box>
            </DialogActions>
          </form>
        </Dialog>
      </>
  )
}

const ModalBox = (): ReactElement => {
  return (
      <Box style={{
        background: "rgba(110,110,110,0.5",
        width: "100%",
        height: "100%",
        position: "absolute",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        zIndex: 1,
      }}>
        <CircularProgress color="secondary"/>
      </Box>
  );
};
