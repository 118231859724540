import React from "react";
import {Box, CircularProgress, Typography} from "@material-ui/core";
import {CloudQueue} from "@material-ui/icons";
import {makeStyles, Theme} from "@material-ui/core/styles";

const useStyles = makeStyles((theme: Theme) => {
  return {
    fontColor: {
      color: theme.palette.text.disabled
    }
  }
})

interface EmptyContainerProps {
  emptyMessage?: string
  minHeight?: number
  loading?: boolean;
}

export const EmptyContainer: React.FC<EmptyContainerProps> = ({
                                                                emptyMessage = 'Keine Werte vorhanden.',
                                                                minHeight = 150,
                                                                loading
                                                              }) => {
  const classes = useStyles()
  return (
      <Box display="flex"
           justifyContent="center"
           flexDirection="column"
           height='100%'
           minHeight={minHeight}
           alignItems="center">
        {!loading && <CloudQueue fontSize='large' className={classes.fontColor}/>}
        {loading && <CircularProgress/>}
        <Typography variant={"subtitle2"} className={classes.fontColor}>{emptyMessage}</Typography>
      </Box>
  )
}


interface EmptyListMessageProps {
  emptyMessage?: string
}

export const EmptyListMessage: React.FC<EmptyListMessageProps> = ({emptyMessage = 'Keine Werte vorhanden.'}) => {
  const classes = useStyles()

  return (
      <Box display="flex"
           justifyContent="center"
           flexDirection="column"
           height='100%'
           alignItems="center">
        <CloudQueue fontSize='large' className={classes.fontColor}/>

        <Typography variant={"subtitle2"} className={classes.fontColor}>{emptyMessage}</Typography>
      </Box>
  )
}
