import React from 'react'
import { Field } from 'react-final-form'
import { FormControlLabel, Switch, SwitchProps } from '@material-ui/core'

interface MaterialToggleProps {
  name: string
  label?: string
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

export const MaterialToggle: React.FC<MaterialToggleProps & SwitchProps> = ({
  name,
  label,
  labelPlacement,
  ...other
}) => {
  return (
    <Field name={name} type="checkbox">
      {({ input }) => (
        <FormControlLabel control={<Switch {...input} {...other} />} label={label} labelPlacement={labelPlacement} />
      )}
    </Field>
  )
}
