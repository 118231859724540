import React from 'react'
import {Field} from 'react-final-form'
import {TextField} from '@material-ui/core'
import {DateTimePicker} from '@material-ui/pickers'
import {FieldValidator} from 'final-form'

import {noop} from "../../../util/functions";

interface MaterialDateTimePickerFieldProps {
    name: string
    label?: string
    validate?: FieldValidator<Date>
    helperText?: string
    [key: string]: any
}

export const MaterialDateTimePickerField: React.FC<MaterialDateTimePickerFieldProps> = ({
                                                                                            name,
                                                                                            validate = noop,
                                                                                            label,

                                                                                            variant = 'filled',
                                                                                            fullWidth = true,
                                                                                            helperText,

                                                                                            ...other
                                                                                        }) => {

    return (
        <Field name={name}>
            {({input, meta}) => {
                const isError = Boolean(meta.touched && meta.error)
                return (
                    <DateTimePicker
                        {...input}
                        {...other}
                        inputFormat="dd.MM.yyyy HH:mm"
                        mask="__.__.____ __:__"
                        renderInput={(props) => (
                            <TextField
                                {...props}
                                {...other}
                                label={label}
                                placeholder="TT.MM.JJJJ HH:mm"
                                variant={variant}
                                fullWidth={fullWidth}
                                error={isError}
                                helperText={helperText}
                                onBlur={input.onBlur}
                                onFocus={input.onFocus}
                            />
                        )}
                    />
                )
            }}
        </Field>
    )
}
