import React, {ReactElement, useEffect} from "react";
import {Grid, Typography} from "@material-ui/core";
import {PlayerLite} from "../../../../api/player/model";
import {useUpdatePlayer} from "../../../../api/player/usePlayers";
import {useForm, UseFormReturn} from "react-hook-form";
import {
  UpdatePlayerRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/UpdatePlayerRequest";
import {FormDialog} from "../../../../components/form/FormDialog";


interface InactivatePlayerConfirmDialogProps {
  selectedPlayer: PlayerLite
}

export const InactivatePlayerConfirmDialog: React.FC<InactivatePlayerConfirmDialogProps> = ({
                                                                                              selectedPlayer,
                                                                                            }) => {
  const mutation = useUpdatePlayer()
  const useFormReturn = useForm<UpdatePlayerRequest>({
    defaultValues: {...selectedPlayer}
  })

  return (
      <FormDialog buttonText={selectedPlayer.inactive ? 'Reaktivieren' : 'Deaktivieren'}
                  buttonProps={{variant: "text", color: "secondary"}}
                  dialogTitel={selectedPlayer.inactive ? 'Reaktivieren' : 'Deaktivieren'}
                  mutation={mutation} useFormReturn={useFormReturn}>
        <DeactivatePlayerConfirmForm selectedPlayer={selectedPlayer} useFormReturn={useFormReturn}/>
      </FormDialog>
  )
}

type DeactivatePlayerConfirmFormProps = {
  selectedPlayer: PlayerLite
  useFormReturn: UseFormReturn<UpdatePlayerRequest>;
}

const DeactivatePlayerConfirmForm = ({
                                       selectedPlayer,
                                       useFormReturn
                                     }: DeactivatePlayerConfirmFormProps): ReactElement => {
  useEffect(() => {
    useFormReturn.reset({...selectedPlayer, inactive: !selectedPlayer.inactive})
  }, [selectedPlayer, useFormReturn])

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          {selectedPlayer.inactive ?
              (
                  <>
                    <Typography variant="subtitle2">
                      Der Spieler '{selectedPlayer.name}' wird reaktiviert.
                    </Typography>
                    <br/>
                    <Typography variant="subtitle2">
                      Er kann wieder ganz normal am Spielbetrieb teilnehmen.
                    </Typography>
                  </>
              ) : (
                  <>
                    <Typography variant="subtitle2">
                      Der Spieler '{selectedPlayer.name}' wird deaktiviert.
                    </Typography>
                    <br/>
                    <Typography variant="subtitle2">
                      Das bedeutet er kann selber keine Forderungen mehr
                      erstellen und auch nicht
                      mehr von
                      anderen Spielern gefordert werden.
                    </Typography>
                    <br/>
                    <Typography variant="subtitle2">
                      Um wieder am Spielbetrieb teilzunehmen, kann er
                      'Reaktiviert' werden.
                    </Typography>
                    {selectedPlayer.ranking === 1 && <>
                      <br/>
                      <Typography variant="subtitle2">
                        <Typography component="span" color="secondary"
                                    variant="subtitle2">Achtung: </Typography> Dieser
                        Spieler
                        liegt auf Rang 1, das bedeutet er kann nicht mehr
                        von anderen überholt
                        werden, solange er inaktiv ist.
                      </Typography>
                    </>}
                  </>
              )
          }
        </Grid>
      </Grid>
  );
};

