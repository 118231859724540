import React, {ReactElement, useState} from "react";
import Button from "@material-ui/core/Button";
import MenuBookIcon from '@material-ui/icons/MenuBook';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography
} from "@material-ui/core";
import {useConfigurationState} from "../../../api/configuration/useConfiguration";
import {MinChallengeRankType} from "../../../api/configuration/model";
import {millisToString} from "../../../util/duration";

type ChallengeRulesDialogProps = {}

export const ChallengeRulesDialog = ({}: ChallengeRulesDialogProps): ReactElement => {
  const [open, setOpen] = useState(false)
  const {configuration} = useConfigurationState()

  const handleClose = () => {
    setOpen(false)
  }

  if (!configuration) {
    return null
  }
  return (
      <>
        <Button onClick={() => setOpen(true)} startIcon={<MenuBookIcon/>}
                style={{color: '#848484'}} size="small">
          Forderungsregeln
        </Button>
        <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
          <DialogTitle>Regeln</DialogTitle>
          <DialogContent>
            <Box display="flex" style={{gap: "1rem"}} flexDirection="column">
              {configuration.minChallengeRankType === MinChallengeRankType.FULL_UPPER_ROW.toString() &&
                  <RuleEntry>Ein Spieler darf besser
                    platzierte Spieler in <b>seiner Reihe</b> fordern,
                    sowie <b>alle</b> Spieler der Reihe oberhalb.</RuleEntry>}
              {configuration.minChallengeRankType === MinChallengeRankType.RIGHTSIDE_UPPER_ROW.toString() &&
                  <RuleEntry>Ein Spieler darf besser
                    platzierte Spieler in <b>seiner Reihe</b> fordern, sowie Spieler <b>rechts
                      von ihm</b> in der Reihe oberhalb.</RuleEntry>}
              {configuration.topXPlayersCanChallengeBetterPlayers > 1 && <RuleEntry>Innerhalb
                der <b>Top {configuration.topXPlayersCanChallengeBetterPlayers} Spielern</b> kann
                <b> jeder bessergereihte</b> Spieler gefordert werden.</RuleEntry>}
              <RuleEntry>Ein <b>geforderter Spieler</b> ist nach der Austragung des Spieles
                für <b>{millisToString(configuration.defenderProtectionDurationMillis)} geschützt</b>,
                dh. er
                kann in dieser Zeit <b>nicht gefordert werden</b>.</RuleEntry>
              <RuleEntry>Ein <b>fordernder Spieler</b> ist nach Austragung des Spieles
                für <b>{millisToString(configuration.challengerFreezeDurationMillis)} gesperrt</b>,
                dh. er
                kann in dieser Zeit <b>keine Forderung aussprechen</b>.</RuleEntry>
              <RuleEntry>Die beiden Spieler, die ein Spiel bestritten haben, dürfen sich innerhalb
                von <b>{millisToString(configuration.instantRevengeBanDurationMillis)} nicht
                  erneut fordern.</b></RuleEntry>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} variant="contained" color="primary">Schließen</Button>
          </DialogActions>
        </Dialog>

      </>
  );
};

type RuleEntryProps = {
  children: any
}

const RuleEntry = ({children}: RuleEntryProps): ReactElement => {
  return (
      <Box display="flex" style={{gap: "0.5rem"}} alignItems="center">
        <NavigateNextIcon/>
        <Typography variant={"body2"} component="div">{children}</Typography>
      </Box>
  );
};
