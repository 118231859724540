import React, {ReactElement} from 'react'
import {Configuration} from "../../../api/configuration/model";
import {Box, Card, CardActions, CardContent, CardHeader, Grid, Typography} from "@material-ui/core";
import TitledDivider from "../../../components/layout/TitledDivider";
import {useTenantState} from "../../../api/tenants/useTenant";
import {useConfigurationState} from "../../../api/configuration/useConfiguration";
import {EditConfigurationDialog} from "./EditConfigurationDialog";
import {useForm, UseFormReturn} from "react-hook-form";
import {ChallengeTopXPlayerConfigurationForm} from "./forms/ChallengeTopXPlayerConfigurationForm";
import {
  MinChallengerRankTypeConfigurationForm
} from "./forms/MinChallengerRankTypeConfigurationForm";
import {
  DefenderProtectionDurationConfigurationForm
} from "./forms/DefenderProtectionDurationConfigurationForm";
import {
  ChallengerFreezeDurationConfigurationForm
} from "./forms/ChallengerFreezeDurationConfigurationForm";
import {
  InstantRevengeBanDurationConfigurationForm
} from "./forms/InstantRevengeBanDurationConfigurationForm";

export const SettingsConfigurationComponent = (): ReactElement => {
  const {configuration} = useConfigurationState()

  const useFormReturn = useForm<Configuration>({
    defaultValues: {
      ...configuration
    },
  })

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <TitledDivider title="Einstellungen"/>
        </Grid>
        <Grid item xs={12} md={10} lg={7}>
          <SettingsConfigurationCard title={"Forderungen Top Spieler"}
                                     useFormReturn={useFormReturn}
                                     readonlyForm={<ChallengeTopXPlayerConfigurationForm
                                         useFormReturn={useFormReturn} readonly={true}/>}
                                     formComponent={<ChallengeTopXPlayerConfigurationForm
                                         useFormReturn={useFormReturn}/>}/>


        </Grid>
        <Grid item xs={12} md={10} lg={7}>
          <SettingsConfigurationCard title={"Wie hoch darf man fordern?"}
                                     readonlyForm={<MinChallengerRankTypeConfigurationForm
                                         useFormReturn={useFormReturn} readonly={true}/>}
                                     formComponent={<MinChallengerRankTypeConfigurationForm
                                         useFormReturn={useFormReturn}/>}
                                     useFormReturn={useFormReturn}/>
        </Grid>
        <Grid item xs={12} md={10} lg={7}>
          <SettingsConfigurationCard title={"Schutzzeit für Geforderte"}
                                     readonlyForm={<DefenderProtectionDurationConfigurationForm
                                         useFormReturn={useFormReturn} readonly={true}/>}
                                     formComponent={<DefenderProtectionDurationConfigurationForm
                                         useFormReturn={useFormReturn}/>}
                                     useFormReturn={useFormReturn}/>
        </Grid>
        <Grid item xs={12} md={10} lg={7}>
          <SettingsConfigurationCard title={"Sperrzeit für Forderer"}
                                     readonlyForm={<ChallengerFreezeDurationConfigurationForm
                                         useFormReturn={useFormReturn} readonly={true}/>}
                                     formComponent={<ChallengerFreezeDurationConfigurationForm
                                         useFormReturn={useFormReturn}/>}
                                     useFormReturn={useFormReturn}/>
        </Grid>
        <Grid item xs={12} md={10} lg={7}>
          <SettingsConfigurationCard title={"Sperrzeit für Revanche"}
                                     readonlyForm={<InstantRevengeBanDurationConfigurationForm
                                         useFormReturn={useFormReturn} readonly={true}/>}
                                     formComponent={<InstantRevengeBanDurationConfigurationForm
                                         useFormReturn={useFormReturn}/>}
                                     useFormReturn={useFormReturn}/>
        </Grid>
      </Grid>
  )
}

type SettingsConfigurationCardProps = {
  title: string
  readonlyForm: ReactElement
  formComponent: ReactElement
  useFormReturn: UseFormReturn<Configuration>
}

const SettingsConfigurationCard = ({
                                     title,
                                     readonlyForm,
                                     formComponent,
                                     useFormReturn
                                   }: SettingsConfigurationCardProps) => {
  const {tenant} = useTenantState()
  const {configuration, isSuccess} = useConfigurationState()

  if (!isSuccess) {
    return null
  }
  return (
      <Card>
        <CardHeader title={<Typography variant={"overline"}>{title}</Typography>}/>
        <CardContent>
          {readonlyForm}
        </CardContent>
        <CardActions>
          <Box display="flex" justifyContent={"flex-end"} width={'100%'}>
            <EditConfigurationDialog configuration={configuration}
                                     editDisabled={!tenant.accessGranted}
                                     useFormReturn={useFormReturn} dialogTitle={title}>
              {formComponent}
            </EditConfigurationDialog>
          </Box>
        </CardActions>
      </Card>
  )
}

