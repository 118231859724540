import {useMutation, UseMutationResult, useQueryClient} from "react-query";
import {ApiError, ApiException} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {pyramidCupApi} from "../PyramidCupApi";
import {
  RankingChangeRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/RankingChangeRequest";
import {ALL_PLAYERS_KEY} from "../player/usePlayers";


export const useCreateRankingEvents = (): UseMutationResult<void, ApiException<ApiError>, RankingChangeRequest[]> => {
  const queryClient = useQueryClient();
  return useMutation(createRankingEvents, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ALL_PLAYERS_KEY);
    },
  })
}

const createRankingEvents = (requests: RankingChangeRequest[]) => {
  return pyramidCupApi.ranking().create(requests)

}
