import {localStorageService} from "../localstorage/LocalStorageService";
import {pyramidCupApi} from "../api/PyramidCupApi";
import {Tenant} from "../api/tenants/model";
import {navigateToLogin} from "../util/navigation";

export interface Credentials {
    tenantKey: string,
    cupPassword: string
}

export const cupAuthService = () => {

    return {
        getCredentials: (): Credentials => {
          return {tenantKey: localStorageService().getTenantKey(), cupPassword: localStorageService().getCupPassword()}
        },

        login: async (tenantValue: string, password: string): Promise<Tenant> => {
            const tenant = await pyramidCupApi.tenants().login({
                tenantKeyOrTenantName: tenantValue,
                cupPassword: password
            })
            localStorageService().setTenantKey(tenant.tenantKey)
            localStorageService().setCupPassword(password)
            localStorageService().setLoginData({tenantKey: tenant.tenantKey, cupPassword: password, tenantName: tenant.name})
            return tenant
        },

        logout: () => {
            localStorageService().resetCupPassword()
            localStorageService().resetTenantKey()
        },
        logoutAndNavigateToStart: () => {
            localStorageService().resetCupPassword()
            localStorageService().resetTenantKey()
            navigateToLogin()
        },
    }
}

export const useCupCredentials = () => {
    return { tenantKey : localStorageService().getTenantKey(), cupPassword: localStorageService().getCupPassword()}
}

export const autoLoginDetection = (): Promise<Tenant | void> => {
    const params = new URLSearchParams(window.location.search);
    const key = params.get("key")
    const pw = params.get("pw")
    if (!key || !pw) return Promise.resolve();
    return cupAuthService().login(key, pw)
}
