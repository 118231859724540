import React, {ReactElement, useEffect} from "react";
import {FormDialog} from "../../../components/form/FormDialog";
import {useUpdateConfiguration} from "../../../api/configuration/useConfiguration";
import {UseFormReturn} from "react-hook-form";
import {Configuration} from "../../../api/configuration/model";

type EditConfigurationDialogProps = {
  dialogTitle: string
  configuration: Configuration
  editDisabled?: boolean
  useFormReturn: UseFormReturn<Configuration>
  children: ReactElement
}

export const EditConfigurationDialog = ({
                                          dialogTitle,
                                          configuration,
                                          editDisabled,
                                          useFormReturn,
                                          children
                                        }: EditConfigurationDialogProps): ReactElement => {
  const mutation = useUpdateConfiguration()
  useEffect(() => {
    useFormReturn.reset({...configuration})
  }, [configuration, useFormReturn])

  return (
      <FormDialog buttonText="Editieren" mutation={mutation}
                  useFormReturn={useFormReturn}
                  dialogTitel={dialogTitle}
                  buttonProps={{color: "secondary", disabled: editDisabled}}>
        {children}
      </FormDialog>
  );
};
