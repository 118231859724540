import {pyramidCupApi} from "../PyramidCupApi";
import {Configuration} from "./model";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";
import {ApiError, ApiException, ConfigurationDTO} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {ALL_PLAYERS_KEY} from "../player/usePlayers";
import {LoadingStateType, SetLoadingStateType} from "../LoadingStateType";
import create from "zustand";
import {useEffect} from "react";
import {useTenantState} from "../tenants/useTenant";

export const CONFIGURATION_KEY = "configuration"
export const useConfiguration = (tenantKey?: string): UseQueryResult<Configuration, ApiException<ApiError>> => {
  return useQuery(CONFIGURATION_KEY, () => pyramidCupApi.configuration().getConfiguration(tenantKey), {
    enabled: !!tenantKey
  })
}

export const useUpdateConfiguration = (): UseMutationResult<any, ApiError, ConfigurationDTO> => {
  const queryClient = useQueryClient()
  return useMutation(CONFIGURATION_KEY, (configurationDto) => {
    return pyramidCupApi.configuration().update(configurationDto)
  }, {
    onSuccess: () => Promise.all([queryClient.invalidateQueries(ALL_PLAYERS_KEY), queryClient.invalidateQueries(CONFIGURATION_KEY)])
  })
}


type ConfigurationStateStoreType = LoadingStateType & SetLoadingStateType & {
  configuration;
  setConfiguration: (newConfig: Configuration) => void;
  cleanup: () => void;
}

const configurationState = create<ConfigurationStateStoreType>((set) => ({
  configuration: {} as Configuration,
  setConfiguration: (newConfig) => set(() => ({configuration: newConfig})),
  cleanup: () => set({configuration: {}}),
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: undefined,
  setLoadingState: ((isLoading, isError, isSuccess, error) => set(() => ({
    isLoading: isLoading,
    isError: isError,
    isSuccess: isSuccess,
    error: error
  })))
}))

type ConfigurationStateType = LoadingStateType & {
  configuration: Configuration
}

export const useConfigurationState = (): ConfigurationStateType => {
  const {configuration, setConfiguration, setLoadingState, cleanup} = configurationState()
  const {tenant} = useTenantState()
  const {isLoading, isSuccess, isError, error, data} = useConfiguration(tenant?.tenantKey)
  useEffect(() => {
    setConfiguration(data)
    setLoadingState(isLoading, isError, isSuccess, error)
    return () => {
      cleanup();
    }
  }, [cleanup, setConfiguration, data, isSuccess, isLoading, isError, error, setLoadingState])
  return {
    configuration,
    isLoading,
    isError,
    isSuccess,
    error
  }

}
