import {Game} from "./model";
import {convertPlayerLiteDTO} from "../player/PlayersApiResponseConverter";
import {GamesApiResponseProcessor} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/GamesApi";
import {GameDTO, ResponseContext} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {handleAuthorization} from "../error/error";


export class GamesApiResponseConverter extends GamesApiResponseProcessor {
  public async create(response: ResponseContext): Promise<Game> {
    if (handleAuthorization(response)) return
    return super.create(response).then(convertGame)
  }

  public async load(response: ResponseContext): Promise<Array<Game>> {
    if (handleAuthorization(response)) return
    return super.load(response).then(convertGames).catch(() => []) // TODO handle when called
  }

  public async finish(response: ResponseContext): Promise<number> {
    if (handleAuthorization(response)) return
    return super.finish(response)
  }

  public async _delete(response: ResponseContext): Promise<number> {
    if (handleAuthorization(response)) return
    return super._delete(response)
  }
}

const convertGame = (gameDTO: GameDTO): Game => {
  return {
    ...gameDTO,
    challenger: convertPlayerLiteDTO(gameDTO.challenger),
    defender: convertPlayerLiteDTO(gameDTO.defender),
    winner: convertPlayerLiteDTO(gameDTO.winner)
  }
}

export const convertGames = (gameDTOs: GameDTO[]): Game[] => {
  return gameDTOs.map(convertGame)
}
