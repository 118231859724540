import React from "react";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {SingleLineField} from "../../components/inputs/singleLineInput/SingleLineField";
import {TenantConfiguration} from "../../api/tenants/model";


interface PasswordStepperContentProps {
    validationMessage: string
}

export const CupWizardPasswordStepperContent: React.FC<PasswordStepperContentProps> = ({validationMessage}) => {
    return (
        <Box marginTop={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Dieses Passwort wird von jedem Spieler
                        benötigt um auf den Cup
                        zugreifen zu können.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SingleLineField name="cupPassword" label="Cup Passwort" autoFocus type="text" required
                                     helperText={validationMessage}/>
                </Grid>
            </Grid>
        </Box>
    )
}


export const validateCupPassword = (tenantConfig: TenantConfiguration): string | null => {
    if (!tenantConfig.cupPassword) {
        return 'Bitte geben Sie ein Passwort ein.'
    } else {
        return
    }
}
