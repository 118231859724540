import {pyramidCupApi} from "../PyramidCupApi";
import {Player} from "./model";
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";
import create from "zustand";
import {useEffect} from "react";
import {ApiError, ApiException} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {
  CreatePlayerRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreatePlayerRequest";
import {
  UpdatePlayerRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/UpdatePlayerRequest";
import {LoadingStateType, SetLoadingStateType} from "../LoadingStateType";
import {DeletePlayerFormModel} from "../../pages/settings/player/form/DeletePlayerConfirmDialog";

export const ALL_PLAYERS_KEY = "players"
const usePlayers = (): UseQueryResult<Player[] | undefined, ApiException<ApiError>> => {
  // TODO verify if tenant has access
  return useQuery<Player[], ApiException<ApiError>, Player[]>(ALL_PLAYERS_KEY, fetchPlayers, {})
}

const fetchPlayers = async (): Promise<Player[]> => {
  return await pyramidCupApi.players().getAll()
}

export const useCreatePlayer = (): UseMutationResult<unknown, ApiException<ApiError>, CreatePlayerRequest> => {
  const queryClient = useQueryClient();
  return useMutation(createPlayer, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ALL_PLAYERS_KEY);
    },
  })
}
const createPlayer = (request: CreatePlayerRequest) => {
  return pyramidCupApi.players().create(request)
}

export const useUpdatePlayer = (): UseMutationResult<unknown, ApiException<ApiError>, UpdatePlayerRequest> => {
  const queryClient = useQueryClient();
  return useMutation(updatePlayer, {
    onSuccess: () => {
      return queryClient.invalidateQueries(ALL_PLAYERS_KEY);
    },
  })
}
const updatePlayer = (request: UpdatePlayerRequest) => {
  return pyramidCupApi.players().update(request);
}

export const useDeletePlayer = (): UseMutationResult<void, ApiException<ApiError>, DeletePlayerFormModel> => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: deletePlayer,
    mutationKey: ALL_PLAYERS_KEY,
    onSuccess: () => queryClient.invalidateQueries(ALL_PLAYERS_KEY)
  })
}

const deletePlayer = (model: DeletePlayerFormModel) => {
  return pyramidCupApi.players()._delete(model.playerId)
}

type PlayersStateStoreType = LoadingStateType & SetLoadingStateType & {
  players: Player[];
  setPlayers: (newPlayer: Player[]) => void;
  cleanup: () => void;
}

export const playersState = create<PlayersStateStoreType>((set) => ({
  players: [],
  setPlayers: (newPlayers: Player[]) => set(() => ({players: newPlayers})),
  cleanup: () => set({players: []}),
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: undefined,
  setLoadingState: ((isLoading, isError, isSuccess, error) => set(() => ({
    isLoading: isLoading,
    isError: isError,
    isSuccess: isSuccess,
    error: error
  })))
}))


type PlayersStateType = LoadingStateType & {
  players: Player[],
}

export const usePlayersState = (): PlayersStateType => {
  const {players, setPlayers, setLoadingState, cleanup} = playersState();
  const {data: loadedPlayers, isLoading, isError, isSuccess, error} = usePlayers();

  useEffect(() => {
    setPlayers(loadedPlayers);
    setLoadingState(isLoading, isError, isSuccess, error)
    return () => {
      cleanup();
    }
  }, [cleanup, setPlayers, loadedPlayers, isSuccess, isLoading, isError, error, setLoadingState])
  return {players, isLoading, isError, isSuccess, error};
}
