import React, {ReactElement} from "react";
import {Grid} from "@material-ui/core";
import {UseFormReturn} from "react-hook-form";
import {TextInput} from "../../../../components/form/inputs/TextInput";
import {PlayerLite} from "../../../../api/player/model";
import {
  CreatePlayerRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreatePlayerRequest";
import {
  UpdatePlayerRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/UpdatePlayerRequest";
import {ReadOnlyForm} from "../../../../components/form/ReadOnlyForm";


interface SettingsPlayerFormProps {
  useFormReturn?: UseFormReturn<UpdatePlayerRequest | CreatePlayerRequest, PlayerLite>;
  readOnlyPlayer?: PlayerLite
}

export const SettingsPlayerForm = ({
                                     useFormReturn,
                                     readOnlyPlayer
                                   }: SettingsPlayerFormProps): ReactElement => {
  if (readOnlyPlayer) {
    return (
        <ReadOnlyForm formValues={readOnlyPlayer}
                      formComponent={(useFromReturn) =>
                          <Form
                              useFormReturn={useFromReturn}
                              readonly={true}/>}/>
    )
  }
  return (
      <Form useFormReturn={useFormReturn}/>
  );
};


interface FormProps {
  useFormReturn: UseFormReturn<any>;
  readonly?: boolean;
}

const Form = ({useFormReturn, readonly}: FormProps): ReactElement => {
  return (
      <Grid container alignItems="flex-start" spacing={2}>
        <Grid item xs={12}>
          <TextInput name="name" useFormReturn={useFormReturn} label="Name"
                     helperText={"Name des Spielers"}
                     required
                     autoFocus
                     readonly={readonly}
          />
        </Grid>
        {readonly && <Grid item xs={12}>
          <TextInput name="ranking" useFormReturn={useFormReturn} label="Platzierung"
                     readonly={readonly}
                     fullWidth/>
        </Grid>}
      </Grid>
  );
};
