import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult
} from "react-query";
import {
  CreateGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/CreateGameRequest";
import {ApiError, ApiException, GameDTO} from "@pyramid-cup/pyramid-cup-api-client-ts";
import {pyramidCupApi} from "../PyramidCupApi";
import {ALL_PLAYERS_KEY} from "../player/usePlayers";
import {Game} from "./model";
import create from "zustand";
import {useEffect} from "react";
import {LoadingStateType} from "../LoadingStateType";
import {
  FinishGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/FinishGameRequest";


export const ALL_GAMES = "games"

export const useCreateGame = (): UseMutationResult<GameDTO, undefined, CreateGameRequest> => {
  const queryClient = useQueryClient();
  return useMutation(ALL_GAMES, (request) => {
        return pyramidCupApi.games().create(request);
      }, {
        onSuccess: () => {
          return Promise.all([queryClient.invalidateQueries(ALL_PLAYERS_KEY), queryClient.invalidateQueries(ALL_GAMES)]);
        }
      }
  )
}

export const useFinishGame = (): UseMutationResult<number, unknown, FinishGameRequest> => {
  const queryClient = useQueryClient();
  return useMutation(ALL_GAMES, (request) => {
        return pyramidCupApi.games().finish(request);
      }, {
        onSuccess: () => {
          return Promise.all([queryClient.invalidateQueries(ALL_PLAYERS_KEY), queryClient.invalidateQueries(ALL_GAMES)]);
        }
      }
  )
}

export const useDeleteGame = (): UseMutationResult<number, unknown, Pick<Game, "id">> => {
  const queryClient = useQueryClient();
  return useMutation(ALL_GAMES, (request) => {
    return pyramidCupApi.games()._delete(request.id)
  }, {
    onSuccess: () => {
      return Promise.all([queryClient.invalidateQueries(ALL_PLAYERS_KEY), queryClient.invalidateQueries(ALL_GAMES)]);
    }
  })
}

const useGames = (): UseQueryResult<Games, ApiException<ApiError>> => {
  return useQuery(ALL_GAMES, fetchGames, {})
}

type Games = {
  openGames: Game[];
  finishedGames: Game[];
}

const fetchGames = async (): Promise<Games> => {
  const [openGames, finishedGames] = await Promise.all([pyramidCupApi.games().load(undefined, undefined, true), pyramidCupApi.games().load(undefined, undefined, false)])
  return {openGames, finishedGames};
}

type GamesStateStoreType = LoadingStateType & {
  games: Games;
  setGames: (newGames: Games) => void;
  cleanup: () => void;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  error?: ApiException<ApiError>;
  setLoadingState: (isLoading: boolean, isError: boolean, isSuccess: boolean, error?: ApiException<ApiError>) => void;
}

export const gamesState = create<GamesStateStoreType>((set) => ({
  games: {openGames: [], finishedGames: []},
  setGames: (newGames: Games) => set(() => ({games: newGames})),
  cleanup: () => set({games: {openGames: [], finishedGames: []}}),
  isLoading: false,
  isError: false,
  isSuccess: false,
  error: undefined,
  setLoadingState: ((isLoading, isError, isSuccess, error) => set(() => ({
    isLoading: isLoading,
    isError: isError,
    isSuccess: isSuccess,
    error: error
  })))
}))

type GamesStateType = LoadingStateType & {
  openGames: Game[],
  finishedGames: Game[],
}

export const useGamesState = (): GamesStateType => {
  const {games, setGames, setLoadingState, cleanup} = gamesState();
  const {data: loadedGames, isLoading, isError, isSuccess, error} = useGames();

  useEffect(() => {
    setGames(loadedGames);
    setLoadingState(isLoading, isError, isSuccess, error)
    return () => {
      cleanup();
    }
  }, [cleanup, setGames, loadedGames, isSuccess, isLoading, isError, error, setLoadingState])
  return {
    openGames: games?.openGames,
    finishedGames: games?.finishedGames,
    isLoading,
    isError,
    isSuccess,
    error
  };
}

