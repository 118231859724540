import React, {useEffect} from "react";
import {Game} from "../../../api/games/model";
import {useDeleteGame} from "../../../api/games/useGames";
import {useForm} from "react-hook-form";
import {FormDialog} from "../../../components/form/FormDialog";
import {Grid, Typography} from "@material-ui/core";


interface DeleteGameConfirmDialogProps {
  game: Game
}

export const DeleteGameConfirmDialog: React.FC<DeleteGameConfirmDialogProps> = ({
                                                                                  game
                                                                                }) => {
  const mutation = useDeleteGame()
  const useFormReturn = useForm<Pick<Game, "id">>({defaultValues: {id: game.id}})
  useEffect(() => {
    useFormReturn.setValue("id", game.id)
  }, [game, useFormReturn])

  return (
      <FormDialog buttonText="Löschen" dialogTitel="Spiel löschen" mutation={mutation}
                  buttonProps={{color: "secondary"}} dialogButtonText="Löschen"
                  useFormReturn={useFormReturn}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>Sind Sie sicher dass Sie dieses Spiel löschen wollen?</Typography>
          </Grid>
        </Grid>
      </FormDialog>
  )
}
