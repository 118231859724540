import React from 'react'
import {FormHelperText, SwitchProps} from '@material-ui/core'
import {MaterialToggle} from "./MaterialToggle";


interface SwitchFieldProps {
  name: string
  label?: string
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom'
}

export const SwitchField: React.FC<SwitchFieldProps & SwitchProps> = ({ name, label, labelPlacement, ...other }) => {
  return (
    <>
      <MaterialToggle name={name} label={label} color="secondary" labelPlacement={labelPlacement} {...other} />
      <FormHelperText> {/*Layout Placeholder*/}</FormHelperText>
    </>
  )
}
