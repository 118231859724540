import React, {ReactElement, useEffect} from "react";
import {Grid} from "@material-ui/core";
import {FormDialog} from "../../../../components/form/FormDialog";
import {useForm} from "react-hook-form";
import {useTenantState, useUpdateTenant} from "../../../../api/tenants/useTenant";
import {
  TenantUpdateRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/TenantUpdateRequest";
import {DateTimeInput} from "../../../../components/form/inputs/DateTimeInput";

export type EditStartTimestampFormType = Partial<TenantUpdateRequest>;

export const EditStartTimestampDialog = (): ReactElement => {
  const {tenant} = useTenantState();
  const useFormReturn = useForm<EditStartTimestampFormType>({
    defaultValues: {
      startTimestamp: tenant.startTimestamp
    }
  });
  const mutation = useUpdateTenant();
  const {isSuccess, variables} = mutation;

  const {control, reset} = useFormReturn;

  useEffect(() => {
    if (isSuccess) {
      reset({startTimestamp: variables.startTimestamp})
    }
  }, [isSuccess, variables, reset])

  return (
      <FormDialog buttonText="Editieren" dialogTitel="Start des Cups ändern" mutation={mutation}
                  useFormReturn={useFormReturn}
                  buttonProps={{
                    disabled: tenant.status !== 'NOT_STARTED' || !tenant.accessGranted,
                    color: "secondary"
                  }}>
        <Grid container>
          <Grid item xs={12}>
            <DateTimeInput name="startTimestamp" control={control} label="Start des Cups" required
                           fullWidth/>
          </Grid>
        </Grid>
      </FormDialog>
  )
}
