import React, {ReactElement, useCallback, useEffect, useState} from "react";
import {Grid, Typography} from "@material-ui/core";
import DraggableList from "../../../components/list/DraggableList";
import {Item} from '../../../components/list/DraggableListItem';
import {DropResult} from "react-beautiful-dnd";
import {reorder} from "../../../components/list/helper";
import {PlayerLite} from "../../../api/player/model";
import {RankingChange} from "../../../api/ranking/model";
import {useForm} from "react-hook-form";
import {
    RankingChangeRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/RankingChangeRequest";
import {FormDialog} from "../../../components/form/FormDialog";
import {useCreateRankingEvents} from "../../../api/ranking/useRanking";

type RankingItem = Item & {
    player: PlayerLite
}

interface SettingsChangeRankingDialogProps {
    initialPlayers: PlayerLite[]
}

export const SettingsChangeRankingDialog = ({initialPlayers}: SettingsChangeRankingDialogProps): ReactElement => {
    const [items, setItems] = useState<RankingItem[]>([])
    const [rankingEvents, setRankingEvents] = useState<RankingChange[]>([])
    const mutation = useCreateRankingEvents()
    const useFormReturn = useForm<RankingChangeRequest[]>({
        defaultValues: []
    })

    const initializeRankingItems = useCallback(() => {
        setItems(initialPlayers.map((p) => {
            return {id: p.id.toString(), primary: `${p.name}`, secondary: undefined, player: p}
        }))
    }, [initialPlayers])

    useEffect(() => {
        initializeRankingItems()
    }, [initialPlayers, initializeRankingItems])


    const onDragEnd = ({destination, source}: DropResult) => {
        // dropped outside the list
        if (!destination) return;

        const player = items[source.index].player
        const newItems = reorder(items, source.index, destination.index);
        const newRank = newItems.indexOf(newItems.find(i => i.player.id === player.id)) + 1
        let events = Array.from(rankingEvents)
        events.push({playerId: player.id, newRanking: newRank})
        setRankingEvents(events)
        setItems(newItems);
        useFormReturn.reset(events)
    };

 return (
     <FormDialog buttonText={"Rangliste ändern"}
                 dialogTitel={"Rangliste ändern"}
                 buttonProps={{color: "secondary", variant: "text", disabled: initialPlayers?.length === 0}}
                 mutation={mutation}
                 useFormReturn={useFormReturn}>
         <Grid container spacing={2}>
             <Grid item xs={12}><Typography variant="caption">Zum Ändern der Reihung einfach Spieler
                 verschieben</Typography></Grid>
             <Grid item xs={12} style={{maxHeight: '51vh', overflowY: 'auto'}}>
                 <DraggableList items={items} onDragEnd={onDragEnd}/>
             </Grid>
         </Grid>
     </FormDialog>
 );
};
