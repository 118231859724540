import TitledDivider from "../../../components/layout/TitledDivider";
import React, {useState} from "react";
import {
  Box,
  Card,
  CardActions,
  CardContent,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from "@material-ui/core";
import {EmptyListMessage} from "../../../components/empty/EmptyContainer";
import {SettingsChangeRankingDialog,} from "./SettingsChangeRankingDialog";
import {InactivatePlayerConfirmDialog} from "./form/InactivatePlayerConfirmDialog";
import {PlayerLite} from "../../../api/player/model";
import {DeletePlayerConfirmDialog} from "./form/DeletePlayerConfirmDialog";
import {Tenant} from "../../../api/tenants/model";
import {usePlayersState} from "../../../api/player/usePlayers";
import {SettingsPlayerForm} from "./form/SettingsPlayerForm";
import {SettingsPlayerDialog} from "./SettingsPlayerDialog";


interface SettingsPlayerComponentProps {
  tenant: Tenant
}

export const SettingsPlayerComponent: React.FC<SettingsPlayerComponentProps> = ({tenant}) => {
  const {players} = usePlayersState();
  const [selectedPlayerId, setSelectedPlayerId] = useState<number | null>()
  const selectedPlayer = players?.find(sP => sP.id === selectedPlayerId) || players?.[0] || null

  const determinePlayerName = (player: PlayerLite): React.ReactNode => {
    const ranking = player.ranking.toString()
    const name = player.name
    const inactive = player.inactive ? '(inaktiv)' : ''
    return (
        <>
          <Typography component="span"
                      color={player.inactive ? 'textSecondary' : 'textPrimary'}>{`${ranking}. ${name}`}</Typography>
          <Typography component="span" variant="subtitle2"
                      color="textSecondary">{` ${inactive}`.trimEnd()}</Typography>
        </>
    )
  }

  return (
      <section id="player">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TitledDivider title="Spieler"/>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            <Card style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
              <CardContent>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid item xs={5}>
                    <Typography variant="overline">Spielerliste</Typography>
                  </Grid>
                  <Grid item xs={7} container justifyContent="flex-end">
                    <SettingsPlayerDialog disabled={!tenant.accessGranted}/>
                  </Grid>
                  <Grid item xs={12}>
                    <List component="nav" dense
                          style={{height: 200, overflow: 'auto'}}>
                      {players && players.map((p: PlayerLite) => {
                        return (
                            <ListItem
                                button
                                key={p.name}
                                selected={p.id === selectedPlayer?.id}
                                onClick={() => setSelectedPlayerId(p.id)}>
                              <ListItemText primary={determinePlayerName(p)}/>
                            </ListItem>
                        )
                      })}
                      {(!players || players.length === 0) &&
                          <EmptyListMessage
                              emptyMessage="Noch keine Spieler vorhanden."/>}
                    </List>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container>
                  <Grid item xs={12} container justifyContent='flex-end'
                        alignItems='flex-end'>
                    {players && players.length > 0 &&
                        <SettingsChangeRankingDialog initialPlayers={players}/>}
                  </Grid>
                </Grid>
              </CardActions>
            </Card>
          </Grid>
          <Grid item xs={12} md={6} lg={5}>
            {selectedPlayer && <Card style={{
              height: '100%',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between'
            }}>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="overline">Spielerdetails</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <SettingsPlayerForm readOnlyPlayer={selectedPlayer}/>
                  </Grid>
                </Grid>
              </CardContent>
              <CardActions>
                <Grid container>
                  <Grid item xs={12} container justifyContent='space-between'
                        alignItems='flex-end'>
                    <DeletePlayerConfirmDialog selectedPlayer={selectedPlayer}/>
                    <Box display="flex" alignItems="flex-end">
                      <InactivatePlayerConfirmDialog selectedPlayer={selectedPlayer}/>
                      <SettingsPlayerDialog initialPlayer={selectedPlayer}
                                            disabled={!tenant.accessGranted}/>
                    </Box>
                  </Grid>
                </Grid>
              </CardActions>
            </Card>}
          </Grid>
        </Grid>
      </section>
  )
}
