import {Box, Card, CardContent, Grid, Typography} from "@material-ui/core";
import React from "react";
import {makeStyles, Theme} from "@material-ui/core/styles";
import {EmptyContainer} from "../../../components/empty/EmptyContainer";
import {TypographyEllipsis} from "../../../components/output/TypographyEllipsis";
import {Game} from "../../../api/games/model";
import {toErrorMessage} from "../../../api/error/error";
import {FinishGameDialog} from "../game/gameresult/FinishGameDialog";


type ChallengesListComponentProps = {
  openGames: Game[];
  isLoading: boolean;
  isSuccess: boolean;
  isError: boolean;
  error: any;
  readonly?: boolean;
}
export const ChallengesListComponent = ({
                                          openGames,
                                          isLoading,
                                          isSuccess,
                                          isError,
                                          error,
                                          readonly,
                                        }: ChallengesListComponentProps) => {
  // const {openGames, isLoading, isSuccess, isError, error} = useGamesState();

  const determineEmptyMessage = () => {
    if (isLoading) return "Forderungen werden geladen";
    if (isError) return `Fehler beim Laden.\n${toErrorMessage(error)}`;
    return "Keine offenen Forderungen vorhanden."
  }

  return (
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box marginLeft={2}>
            <Typography variant='overline'>Offene Forderungen</Typography>
          </Box>
        </Grid>
        <Grid item xs={12}>
          {isSuccess && openGames && openGames.map(game => <ChallengeEntry key={game.id}
                                                                           openGame={game}
                                                                           readonly={readonly}/>)}
        </Grid>
        <Grid item xs={12}>
          {(!openGames || openGames.length < 1) &&
              <EmptyContainer loading={isLoading}
                              emptyMessage={determineEmptyMessage()}/>}
        </Grid>
      </Grid>
  )
}

interface ChallengeEntryProps {
  openGame: Game;
  readonly: boolean;
}

const useStyles = makeStyles((theme: Theme) => {
  return {
    header: {
      fontSize: 14,
    },
    pos: {
      marginBottom: 12,
    },
    challengeContainer: {
      backgroundColor: theme.palette.background.default
    }
  }
})

export const ChallengeEntry: React.FC<ChallengeEntryProps> = ({openGame, readonly}) => {
  const classes = useStyles();

  return (
      <Box p={2} paddingTop={0}>
        <Card elevation={1} className={classes.challengeContainer}>
          <CardContent>
            <Grid container spacing={1}>
              <Grid item xs={4}>
                <Typography variant="body2" color="textSecondary" align="left">
                  #{openGame.id}
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="body2" color="textSecondary" align="right">
                  {openGame.challengeTimestamp.toLocaleString()}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={0}>
                  <Grid item xs={12}>
                    <Box display={'flex'} alignItems={"flex-end"}>
                      <TypographyEllipsis variant="body1">
                        {openGame.challenger.name}
                      </TypographyEllipsis>
                      {openGame.challenger.ranking !== 0 &&
                          <Typography>&nbsp;({openGame.challenger.ranking})</Typography>}
                      <Typography variant={"caption"}
                                  color={"textSecondary"}>&nbsp;{'fordert'}</Typography>
                    </Box>
                  </Grid>
                  <Grid item xs={12}>
                    <Box display={'flex'} alignItems={"flex-end"}>
                      <TypographyEllipsis variant="body1">
                        {openGame.defender.name}
                      </TypographyEllipsis>
                      {openGame.defender.ranking !== 0 &&
                          <Typography>&nbsp;({openGame.defender.ranking})</Typography>}
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
              {!readonly && <Grid item xs={12} container alignContent={"flex-end"}
                                  justifyContent={"flex-end"}>
                <FinishGameDialog gameId={openGame.id} challenger={openGame.challenger}
                                  defender={openGame.defender}/>
              </Grid>}
            </Grid>
          </CardContent>
        </Card>
      </Box>
  )
}
