import {Configuration} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/configuration";
import {RequestContext} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/http/http";
import {cupAuthService} from "../../auth/cupAuthService";
import {RankingApiRequestFactory} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/apis/RankingApi";
import {RankingChangeRequest} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/RankingChangeRequest";
import {adminAuthService} from "../../auth/adminAuthService";


export class RankingApiRequestEnhancer extends RankingApiRequestFactory {

    async create(rankingChangeRequest: Array<RankingChangeRequest>, xTenantKey?: string, xCupPw?: string, xAdminToken?: string, _options?: Configuration): Promise<RequestContext> {
        const {tenantKey, cupPassword} = cupAuthService().getCredentials()
        const {token} = await adminAuthService().getAdminCredentials()
        return super.create(rankingChangeRequest, xTenantKey || tenantKey, xCupPw || cupPassword, xAdminToken || token, _options)
    }
}
