import {Controller, FieldValues, Path, UseFormReturn} from "react-hook-form";
import React, {ReactElement} from "react";
import {TextField, TextFieldProps} from "@material-ui/core";
import {NumberFormatValues, NumericFormat, NumericFormatProps} from "react-number-format";

type NumericFormatComponentProps = {
  noGrouping?: boolean
  noDecimals?: boolean
} & NumericFormatProps & TextFieldProps

export const NumericFormatComponent = React.forwardRef(({
                                                          noGrouping,
                                                          noDecimals,
                                                          ...props
                                                        }: NumericFormatComponentProps, ref): ReactElement => {
  return (
      <NumericFormat
          {...props}
          type={"text"}
          valueIsNumericString={true}
          thousandSeparator={noGrouping ? '' : '.'}
          decimalSeparator={','}
          decimalScale={noDecimals ? 0 : undefined}
          allowedDecimalSeparators={['.', ',']}
          customInput={TextField}
          variant='outlined'
          size="small"
          fullWidth
          onValueChange={props.onValueChange}
      />
  );
});

type NumberInputProps<T extends FieldValues> = {
  name: Path<T>;
  useFormReturn: UseFormReturn<T>;
  label?: string;
  fullWidth?: boolean;
  disabled?: boolean;
  helperText?: string;
  required?: boolean;
  validate?: any;
  noGrouping?: boolean
  noDecimals?: boolean
  readonly?: boolean
} & TextFieldProps

export const NumberInput = <T extends FieldValues>({
                                                     name,
                                                     useFormReturn,
                                                     label,
                                                     fullWidth = true,
                                                     disabled,
                                                     required,
                                                     helperText,
                                                     validate,
                                                     noDecimals,
                                                     noGrouping,
                                                     readonly,
                                                   }: NumberInputProps<T>): ReactElement => {
      const readonlyValue = useFormReturn.getValues(name)
      return (
          <Controller
              name={name}
              control={useFormReturn.control}
              rules={{
                required: !readonly ? {
                  value: required,
                  message: "Dieses Feld ist ein Pflichtfeld."
                } : undefined,
                validate: readonly ? undefined : validate,
              }}
              render={({field, fieldState}) => {
                return <NumericFormatComponent
                    {...field}
                    style={{pointerEvents: readonly ? 'none' : 'all'}}
                    label={label}
                    noDecimals={noDecimals}
                    noGrouping={noGrouping}
                    value={(readonly ? readonlyValue : field.value) || ""}
                    onChange={readonly ? undefined : field.onChange}
                    fullWidth={fullWidth}
                    disabled={disabled}
                    error={!readonly && !!fieldState.error}
                    helperText={(!readonly && fieldState.error?.message) || helperText}
                    onValueChange={(values: NumberFormatValues) => {
                      if (!readonly) {
                        useFormReturn.setValue(name, values.value as any, {shouldValidate: true})
                      }
                    }}
                />
              }}
          />
      );
    }
;
