import * as React from 'react';
import {Draggable} from 'react-beautiful-dnd';

import makeStyles from '@material-ui/core/styles/makeStyles';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import {IconButton} from "@material-ui/core";

const useStyles = makeStyles({
    draggingListItem: {
        background: 'rgb(235,235,235)'
    }
});

export type Item = {
    id: string;
    primary: string;
    secondary: string;
};

export type DraggableListItemProps = {
    item: Item;
    index: number;
};

const DraggableListItem = ({ item, index }: DraggableListItemProps) => {
    const classes = useStyles();
    return (
        <Draggable draggableId={item.id} index={index}>
            {(provided, snapshot) => (
                <ListItem
                    button
                    dense
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={snapshot.isDragging ? classes.draggingListItem : ''}
                >
                    <IconButton size="small" disabled>
                        <DragIndicatorIcon color="secondary"/>
                    </IconButton>

                    <ListItemText primary={`${index+1}. ${item.primary}`}/>
                </ListItem>
            )}
        </Draggable>
    );
};

export default DraggableListItem;
