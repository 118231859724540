import React, {forwardRef} from 'react'
import {Field} from 'react-final-form'
import {TextField} from '@material-ui/core'
import {FieldValidator, Subscription} from 'final-form'

interface MaterialFieldProps<T = string> {
  name: string
  validate?: FieldValidator<any>
  fieldName?: string
  subscription?: Subscription
  parse?: (value: T, name: string) => any
  format?: (value: T, name: string) => any
  defaultValue?: T
  Component?: React.ElementType
  helperText?: string
  type?: string

  [key: string]: any
}

export const MaterialField = forwardRef<HTMLInputElement, MaterialFieldProps>(
    (
        {
          name,
          validate,
          fieldName,
          subscription,
          parse,
          format,
          defaultValue,
          Component = TextField,
          helperText = ' ',
          type = 'text',
          ...others
        },
        ref
    ) => {
      return (
          <Field
              defaultValue={defaultValue}
              name={name}
              validate={validate}
              type={type} MaterialField
              subscription={subscription}
              parse={parse}
              format={format}
          >
            {({input, meta}) => {
              const isError = Boolean(meta.touched && meta.error)
              return (
                  <Component
                      error={isError}
                      helperText={isError ? meta.error : helperText}
                      ref={ref}
                      size="small"
                      {...input}
                      {...others}
                  />
              )
            }}
          </Field>
      )
    }
)
