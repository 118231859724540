
export const isEmpty =(obj: any)=> {
return obj && Object.keys(obj).length === 0
    && Object.getPrototypeOf(obj) === Object.prototype
}

export const toCurrency = (valueInCents: number, currencyCode: string)=>{
  return (valueInCents / 100).toLocaleString("de-DE", {
    style: 'currency',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    currency: currencyCode
  })
}
