import React from "react";
import {Box, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import {SingleLineField} from "../../components/inputs/singleLineInput/SingleLineField";
import {pyramidCupApi} from "../../api/PyramidCupApi";
import {TenantConfiguration} from "../../api/tenants/model";


interface NameStepperContentProps {
    validationMessage: string
}

export const CupWizardNameStepperContent: React.FC<NameStepperContentProps> = ({validationMessage}) => {
    return (
        <Box marginTop={2}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="subtitle2" color="textSecondary">Definieren Sie wie Ihr Cup heißen
                        soll.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <SingleLineField name="name" label="Name" required variant="outlined" autoFocus
                                     helperText={validationMessage}/>
                </Grid>
            </Grid>
        </Box>
    )
}

export const validateCupName = (tenantConfig: TenantConfiguration): Promise<string | null> => {
    if (!tenantConfig.name) {
        return Promise.resolve('Bitte geben Sie einen Namen ein.')
    }
    return pyramidCupApi.tenants().exists(tenantConfig.name.trim()).then(() => {
        return 'Dieser Name wird bereits verwendet.'
    }).catch(() => {
        return null
    })
}
