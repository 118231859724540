import React, {useEffect} from "react";
import {useForm, UseFormReturn} from "react-hook-form";
import {Box, Grid, Typography} from "@material-ui/core";
import {Configuration, HoursMinutes, toHoursMinutes} from "../../../../api/configuration/model";
import {hoursAndMinutesToMillis, hoursAndMinutesToString} from "../../../../util/duration";
import {TextInput} from "../../../../components/form/inputs/TextInput";
import {NumberInput} from "../../../../components/form/inputs/NumberInput";

type InstantRevengeBanDurationConfigurationFormProps = {
  useFormReturn?: UseFormReturn<Configuration>
  readonly?: boolean
}
export const InstantRevengeBanDurationConfigurationForm = ({
                                                             readonly,
                                                             useFormReturn
                                                           }: InstantRevengeBanDurationConfigurationFormProps) => {
  const {watch, setValue} = useFormReturn;
  const millis = watch("instantRevengeBanDurationMillis")
  const hoursMinutes = toHoursMinutes(millis)

  const hoursMinutesForm = useForm<HoursMinutes>({
    reValidateMode: "onChange",
    defaultValues: {
      ...toHoursMinutes(millis)
    },
  })
  const hours = hoursMinutesForm.watch("hours")
  const minutes = hoursMinutesForm.watch("minutes")

  useEffect(() => {
    const newMillis = hoursAndMinutesToMillis(hours, minutes)
    if (isNaN(newMillis) || newMillis < 0) {
      setValue("instantRevengeBanDurationMillis", 0)
      return
    }
    setValue("instantRevengeBanDurationMillis", newMillis)
  }, [hours, minutes, setValue])

  return (

      <Grid container alignItems="flex-start" spacing={2}>
        {!readonly && (
            <>
              <Grid item xs={6} md={3}>
                <NumberInput name={"hours"} useFormReturn={hoursMinutesForm}
                             label={"Stunden"} noGrouping={true} noDecimals={true}/>
              </Grid>
              <Grid item xs={6} md={3}>
                <NumberInput name={"minutes"} useFormReturn={hoursMinutesForm}
                             noGrouping={true} noDecimals={true}
                             label={"Minuten"}/>
              </Grid>
            </>
        )}
        {readonly &&
            <Grid item xs={12} md={6} lg={4}>
              <TextInput
                  name="instantRevengeBanDurationMillis"
                  useFormReturn={useFormReturn}
                  valueFormatter={(value) => hoursAndMinutesToString(toHoursMinutes(value).hours, toHoursMinutes(value).minutes)}
                  variant="outlined" fullWidth
                  helperText={`Spieler dürfen sich für ${hoursAndMinutesToString(hoursMinutes.hours, hoursMinutes.minutes)} nach Spielende nicht erneut fordern.`}
                  readonly={readonly}
              />
            </Grid>}

        <Grid item xs={12} md={6} lg={8}>
          <Box minHeight={60}>
            <Typography variant={"caption"} component="p">Dieser Wert gibt an wie
              lange
              Spieler, nachdem Sie ein Spiel gegeneinander ausgetragen haben,
              sich nicht erneut fordern dürfen.</Typography>
            <br/><Typography variant={"caption"} component="p">Damit soll verhindert
            werden dass sich zwei Spieler permanent gegenseitig blockieren und somit
            nicht
            von anderen Spielern gefordert werden können.</Typography>
            <br/><Typography variant={"caption"} component="p">Standardwert:
            24h</Typography>
          </Box>
        </Grid>
      </Grid>
  )
}
