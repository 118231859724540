import React, {ReactElement} from "react";
import {Grid, TextField} from "@material-ui/core";
import {Game} from "../../../api/games/model";
import {UseFormReturn} from "react-hook-form";
import {
  FinishGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/FinishGameRequest";
import {FinishGameForm} from "../../home/game/gameresult/FinishGameForm";


type SettingsGameResultFormNewProps = {
  game: Game;
  useFormReturn?: UseFormReturn<FinishGameRequest>
}

export const SettingsGameResultForm = ({
                                         game,
                                         useFormReturn
                                       }: SettingsGameResultFormNewProps): ReactElement => {
  const readonly = !useFormReturn

  return (
      <>
        <Grid container alignItems="flex-start" spacing={2}
              style={{pointerEvents: readonly ? 'none' : 'all'}}>

          <Grid item xs={4} lg={3}>
            <TextField label="Id"
                       variant="outlined" value={game.id} disabled={true} fullWidth size="small"/>
          </Grid>
          <Grid item xs={8} lg={9}>
            <TextField label="Forderungsdatum"
                       variant="outlined" value={game.challengeTimestamp.toLocaleString()}
                       disabled={true} fullWidth size="small"/>
          </Grid>
          <Grid item xs={12}>
            <TextField label="Begegnung"
                       variant="outlined" value={`${game.challenger.name} - ${game.defender.name}`}
                       disabled={true} fullWidth size="small"/>
          </Grid>
          {readonly && game.winner && <>
            <Grid item xs={12}>
              <TextField label="Sieger"
                         variant="outlined" value={game.winner?.name}
                         disabled={true} fullWidth size="small"/>
            </Grid>
            <Grid item xs={12}>
              <TextField label="Ergebnis"
                         variant="outlined" value={`${game.result || 'kein Ergebnis eingetragen'}`}
                         disabled={true} fullWidth size="small"/>
            </Grid>
          </>}
        </Grid>
        {!readonly &&
            <FinishGameForm challenger={game.challenger} defender={game.defender}
                            useFormReturn={useFormReturn}/>}
      </>
  );
};
