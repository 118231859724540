import {Grid, Paper} from "@material-ui/core";
import React, {useEffect} from "react";
import {PageWrapper} from "../../components/page/PageWrapper";
import {ChallengesList} from "./challenges/ChallengesList";
import {ResultsList} from "./game/gameresult/ResultsList";
import {Challenge} from "./challenges/Challenge";
import {Pyramid} from "./pyramid/Pyramid";
import {useTenantState} from "../../api/tenants/useTenant";
import {cupAuthService} from "../../auth/cupAuthService";

export const HomePage = () => {
  const {tenant, isError, error} = useTenantState();
  const noSubscriptionWarningText = "Kein Zugriff möglich, da das Abo abgelaufen ist. Bitte wenden Sie sich an Ihren Administrator."

  useEffect(() => {
    if (isError) {
      cupAuthService().logoutAndNavigateToStart();
      console.log(error)
    }
  }, [tenant, isError, error])

  return (
      <>
        {tenant && <PageWrapper title={tenant.name} subtitle={tenant.tenantKey}
                                bannerText={!tenant.accessGranted && noSubscriptionWarningText}>
          <Grid container spacing={4}>
            <Grid item xs={12}>
              <Paper>
                <Challenge startTimestamp={tenant.startTimestamp}
                           tenantStatus={tenant.status} tenant={tenant}/>
              </Paper>
            </Grid>
            <Grid item xs={12}>
              <Paper>
                <Pyramid/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper>
                <ChallengesList/>
              </Paper>
            </Grid>
            <Grid item xs={12} md={4}>
              <Paper>
                <ResultsList/>
              </Paper>
            </Grid>
          </Grid>
        </PageWrapper>}
      </>
  )
}
