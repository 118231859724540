import {localStorageService} from "../localstorage/LocalStorageService";
import {firebaseGetCurrentUser, firebaseSignIn, firebaseSignOut} from "../config/firebase";
import {pyramidCupApi} from "../api/PyramidCupApi";

interface AdminCredentials {
  tenantKey: string,
  token: string
}

export const adminAuthService = () => {
  return {
    getAdminCredentials: async (): Promise<AdminCredentials> => {
      const token = await firebaseGetCurrentUser()?.getIdToken(false)
      return {tenantKey: localStorageService().getTenantKey(), token}
    },

    login: async (adminEmail: string, adminPassword: string) => {
      await firebaseSignIn(adminEmail, adminPassword)
      const token = await firebaseGetCurrentUser().getIdToken(false)
      const tenantKey = localStorageService().getTenantKey()
      await pyramidCupApi.tenants().loginAdmin({tenantKey, token})
    },

    checkLogin: async () => {
      const token = await firebaseGetCurrentUser().getIdToken(false)
      const tenantKey = localStorageService().getTenantKey()
      return pyramidCupApi.tenants().loginAdmin({tenantKey, token})
    },

    logout: async () => {
      await firebaseSignOut()
    },
  }
}
