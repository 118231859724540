import {format} from "date-fns";
import deLocale from "date-fns/locale/de";

export const formatDateTime = (date?: Date): string => {
    if(!date) return ""
    return format(date, "dd.MM.yyyy HH:mm", {locale: deLocale})
}

export const formatDate = (date: Date): string => {
    return format(date, "dd.MM.yyyy", {locale: deLocale})
}

export const formatTime = (date: Date): string => {
    return format(date, "HH:mm", {locale: deLocale})
}
