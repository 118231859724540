import React from "react";
import {Box, Grid, TextField} from "@material-ui/core";
import {TenantConfiguration} from "../../api/tenants/model";
import {format} from "date-fns";
import deLocale from "date-fns/locale/de";
import {makeStyles} from "@material-ui/core/styles";


interface SummaryStepperContentProps {
    tenantConfig: TenantConfiguration
}

const useStyles = makeStyles({
    root: {
        '& input': {
            color: 'rgb(0.84,0.84,0.84)',

        },
        '& .MuiInput-underline.Mui-disabled:before':{
            borderBottomStyle: "solid"
        }
    },

});

export const CupWizardSummaryStepperContent: React.FC<SummaryStepperContentProps> = ({tenantConfig}) => {
    const classes = useStyles()
    return (
        <Box marginTop={2} marginBottom={2}>
            <Grid container spacing={2}>
                <Grid item xs={12} >
                    <TextField  className={classes.root} value={tenantConfig.name} label="Name des Cups" variant="standard" disabled fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.root} value={tenantConfig.cupPassword} label="Cup Passwort" variant="standard" disabled
                               fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.root} value={format(tenantConfig.startTimestamp, "dd.MM.yyyy HH:mm", {locale: deLocale})} label="Startzeitpunkt" variant="standard" disabled
                               fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.root} value={tenantConfig.adminEmail} label="Admin E-Mail" variant="standard" disabled
                               fullWidth/>
                </Grid>
                <Grid item xs={12}>
                    <TextField className={classes.root} value={'*'.repeat(tenantConfig.adminPassword?.length || 0)} label="Admin Passwort"
                               variant="standard" disabled fullWidth/>
                </Grid>
            </Grid>
        </Box>
    )
}
