import React, {useEffect} from "react";
import {Game} from "../../../api/games/model";
import {FormDialog} from "../../../components/form/FormDialog";
import {useForm} from "react-hook-form";
import {useFinishGame} from "../../../api/games/useGames";
import {
  FinishGameRequest
} from "@pyramid-cup/pyramid-cup-api-client-ts/dist/models/FinishGameRequest";
import {SettingsGameResultForm} from "./SettingsGameResultForm";

const initForm = (game: Game): FinishGameRequest => {
  return {
    gameId: game.id,
    winnerId: game.winner?.id || game.challenger.id,
    result: game.result || ""
  }
}

interface EditGameDialogProps {
  game: Game
}

export const EditGameDialog = ({
                                 game,
                               }: EditGameDialogProps) => {
  const useFormReturn = useForm<FinishGameRequest>({
    defaultValues: {
      ...initForm(game)
    }
  });

  useEffect(() => {
    useFormReturn.reset(initForm(game))
  }, [game, useFormReturn])


  const mutation = useFinishGame()

  return (
      <FormDialog buttonText="Editieren" dialogTitel="Spielergebnis editieren" mutation={mutation}
                  useFormReturn={useFormReturn} buttonProps={{color: "secondary"}}>
        <SettingsGameResultForm game={game} useFormReturn={useFormReturn}/>
      </FormDialog>
  )
}
