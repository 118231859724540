import React, { useEffect, useRef, useState } from 'react'
import { makeStyles, PropTypes, Tooltip, Typography } from '@material-ui/core'
import { Variant } from '@material-ui/core/styles/createTypography'

const useStyles = makeStyles(() => ({
    mightOverflow: {
        maxWidth: '100%',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }
}))

interface TypographyEllipsisProps {
    placement?:
        | 'bottom-end'
        | 'bottom-start'
        | 'bottom'
        | 'left-end'
        | 'left-start'
        | 'left'
        | 'right-end'
        | 'right-start'
        | 'right'
        | 'top-end'
        | 'top-start'
        | 'top'

    align?: PropTypes.Alignment
    color?: 'initial' | 'inherit' | 'primary' | 'secondary' | 'textPrimary' | 'textSecondary' | 'error'
    display?: 'initial' | 'block' | 'inline'
    variant?: Variant | 'inherit'
    component?: string
    bold?: boolean
}

export const TypographyEllipsis: React.FC<TypographyEllipsisProps> = ({ placement = 'top',bold, ...others }) => {
    const classes = useStyles()
    const ref = useRef<HTMLElement>(null)
    const [tooltip, setTooltip] = useState(null)
    const Container = ({ children }) =>
        tooltip ? (
            <Tooltip title={tooltip} placement={placement}>
                {children}
            </Tooltip>
        ) : (
            <>{children} </>
        )

    useEffect(() => {
        if (ref.current?.offsetWidth < ref.current?.scrollWidth) {
            setTooltip(ref.current.innerHTML)
        } else {
            setTooltip(null)
        }
    }, [ref, setTooltip])

    const fweight = bold ? 'bold':'normal'
    return (
        <Container>
            <Typography ref={ref} className={classes.mightOverflow} style={{fontWeight: fweight}} component="div" {...others} />
        </Container>
    )
}
